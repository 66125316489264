.Principles {
  display: flex;
  height: 100%;
  padding-bottom: 171px;
  background: #f7f8fc;
}
.Principles-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.Principles-backgroundImage {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .3;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(rgba(0, 0, 0, 0)));
          mask-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(rgba(0, 0, 0, 0)));
}
.Principles-left {
  flex: auto;
  padding-top: 60px;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Principles-left {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Principles-left {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.Principles-right {
  flex: auto;
  background: transparent;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 10 + 9 * 48px + 0.5 * 48px + 1 * 48px / 2) + 0px);
  padding-left: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Principles-right {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 10 + 9 * 32px + 0.5 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Principles-right {
    padding-left: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Principles-header {
  color: #e6102d;
  margin-bottom: 93px;
  padding-top: 200px;
  margin-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Principles-header {
    margin-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.Principles-subtitle {
  display: flex;
  margin-bottom: 24px;
}
.Principles-subtitleStroke {
  width: 100px;
  margin-right: 24px;
  border-bottom: 1px solid #e6102d;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 11px);
}
@media (max-width: 1199px) {
  .Principles-subtitleStroke {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 11px);
  }
}
.Principles-subtitleText {
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  line-height: 8px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #e6102d;
}
.Principles-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 52px;
  font-weight: 500;
  line-height: 64px;
  color: #0f0c1a;
  margin-bottom: 85px;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 8 + 7 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Principles-description {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 8 + 7 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Principles-row {
  display: flex;
  flex-wrap: wrap;
}
.Principles-text {
  font-size: 18px;
  line-height: 28px;
  color: #0f0c1a;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 6 + 5 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Principles-text {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 6 + 5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Principles-text p {
  margin-bottom: 75px;
}
.Principles-text-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 44px;
  margin-bottom: 24px;
}
.Principles-icon {
  position: relative;
  padding-top: 16px;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 2 + 1 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
.Principles-icon svg {
  height: 72px;
  width: 72px;
  top: 0;
  left: 0;
  animation: animate 1.5s ease-out;
}
@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1199px) {
  .Principles-icon {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 2 + 1 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Principles-icon {
    margin-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
.Principles-iconText {
  font-size: 14px;
  line-height: 20px;
  color: #78797a;
  padding-top: 19px;
}
.Principles-quote {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 48px;
  color: #1c1630;
  position: relative;
  margin-top: 20px;
  padding: 58px 72px 58px 72px;
  background: #fff;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 9 + 8 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Principles-quote {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 9 + 8 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Principles-quote:after {
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  top: 0;
  right: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid #f7f8fc;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  transform: rotate(45deg);
  transform-origin: 140% 83%;
}
.Principles-quote:before {
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  top: 0;
  right: 0;
  border-top: 20px solid #edeef2;
  border-bottom: 20px solid transparent;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  transform: rotate(45deg);
  transform-origin: 18% 33%;
}
.Principles-afterQuote {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: #1c1630;
  margin-top: 100px;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 6 + 5 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Principles-afterQuote {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 6 + 5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Principles-afterQuote p {
  margin-bottom: 30px;
}
@media (min-width: 1440px), (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  .Principles-slider {
    flex-shrink: 0;
    margin-bottom: 140px;
    width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0.5 * 48px) / 10 * 9 + 8 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (min-width: 1440px) and (max-width: 1199px), (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px), (max-width: 1199px) and (max-width: 1199px) {
  .Principles-slider {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0.5 * 2 * 48px) / 10 * 9 + 8 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Principles-value-slides {
  font-size: 0;
  margin-top: 37px;
}
.Principles-value-slides-item {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-right: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  margin-bottom: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  height: 300px;
}
@media (max-width: 1199px) {
  .Principles-value-slides-item {
    width: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Principles-value-slides-item {
    margin-right: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Principles-value-slides-item {
    margin-bottom: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Principles-value-slides-item:nth-child(2n) {
  margin-right: 0;
}
.Principles-value-slides-item:hover .Principles-value-slides-item-image {
  transform: rotateY(180deg);
}
.Principles-value-slides-item:hover .Principles-value-slides-item-description {
  transform: rotateY(0deg);
}
.Principles-value-slides-item-image {
  background-size: cover;
  background-position: center center;
}
.Principles-value-slides-item-image:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.Principles-value-slides-item-image-title {
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 500;
}
.Principles-value-slides-item-description {
  transform: rotateY(-180deg);
  box-sizing: border-box;
  border: 1px solid #e6102d;
  padding: 14px 20px;
}
.Principles-value-slides-item-image,
.Principles-value-slides-item-description {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.Principles-value-slides-item-title {
  font-size: 16px;
  line-height: 24px;
  color: #000;
  font-weight: 500;
  margin-bottom: 5px;
}
.Principles-value-slides-item-text {
  font-size: 12px;
  line-height: 16px;
}
@media (min-width: 1440px) {
  .Principles-value-slides-item-text {
    font-size: 14px;
    line-height: 20px;
  }
}
