.Slider {
  position: relative;
  height: 100%;
}
.Slider-items {
  height: 100%;
}
.Slider .owl-stage-outer,
.Slider .owl-stage,
.Slider .owl-item {
  height: 100%;
}
.Slider .owl-dots {
  display: none;
}
.Slider-item {
  height: 100%;
}
.Slider-item img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Slider-left {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  cursor: url('/static/img/front/cursor-left.png') 3 8, pointer;
  /* Legacy */
  cursor: url('/static/img/front/cursor-left.svg') 3 8, pointer;
  /* FF */
  cursor: -webkit-image-set(url('/static/img/front/cursor-left.png') 1x, url('/static/img/front/cursor-left@2x.png') 2x) 3 8, pointer;
  /* Webkit */
  cursor: image-set(url('/static/img/front/cursor-left.png') 1x, url('/static/img/front/cursor-left@2x.png') 2x) 3 8, pointer;
  /* Webkit */
  z-index: 8;
}
.Slider-right {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: url('/static/img/front/cursor-right.png') 26 8, pointer;
  /* Legacy */
  cursor: url('/static/img/front/cursor-right.svg') 26 8, pointer;
  /* FF */
  cursor: -webkit-image-set(url('/static/img/front/cursor-right.png') 1x, url('/static/img/front/cursor-right@2x.png') 2x) 26 8, pointer;
  /* Webkit */
  cursor: image-set(url('/static/img/front/cursor-right.png') 1x, url('/static/img/front/cursor-right@2x.png') 2x) 26 8, pointer;
  /* Webkit */
  z-index: 8;
}
.Slider-captionText {
  position: relative;
  flex: 1 1;
}
.Slider-caption {
  position: absolute;
  bottom: 0;
  background: #fff;
  z-index: 9;
}
.Slider-captionValue.isExiting {
  position: absolute;
}
.Slider-captionValue.isEntering {
  display: none;
}
.Slider-counterCurrent {
  position: relative;
}
.Slider--index {
  height: 864px;
  padding-bottom: 168px;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Slider--index {
    height: 696px;
    padding-bottom: 76px;
  }
}
@media (max-width: 1199px) {
  .Slider--index {
    height: 613px;
    padding-bottom: 133px;
  }
}
.Slider--index .Slider-left,
.Slider--index .Slider-right {
  bottom: 168px;
}
.Slider--index .owl-stage-outer:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(0deg, transparent, rgba(28, 22, 48, 0.8));
  z-index: 6;
  pointer-events: none;
}
.Slider--index .Slider-inner {
  position: absolute;
  width: 100%;
  bottom: 0;
}
@media (min-width: 1440px) {
  body.lang--ru .Slider--index .Slider-inner {
    width: calc(100% + 336px);
  }
}
.Slider--index .Slider-caption {
  height: 264px;
  bottom: 0;
  padding-top: 32px;
  left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 8 + 7 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Slider--index .Slider-caption {
    left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--index .Slider-caption {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 8 + 7 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--index .Slider-caption {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--index .Slider-caption {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Slider--index .Slider-caption {
    height: 216px;
    padding-top: 34px;
    left: calc(((100% - (9 - 1) * 48px - 0.5 * 48px - 0.5 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
    width: calc(((100% - (9 - 1) * 48px - 0.5 * 48px - 0.5 * 48px) / 9 * 8 + 7 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
    padding-right: calc(((100% - (9 - 1) * 48px - 0.5 * 48px - 0.5 * 48px) / 9 * 2 + 1 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px) {
  .Slider--index .Slider-caption {
    left: calc(((100% - (9 - 1) * 32px - 0.5 * 32px - 0.5 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px) {
  .Slider--index .Slider-caption {
    width: calc(((100% - (9 - 1) * 32px - 0.5 * 32px - 0.5 * 2 * 48px) / 9 * 8 + 7 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px) {
  .Slider--index .Slider-caption {
    padding-right: calc(((100% - (9 - 1) * 32px - 0.5 * 32px - 0.5 * 2 * 48px) / 9 * 2 + 1 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--index .Slider-caption {
    height: 216px;
    left: 48px;
    padding-top: 29px;
    width: calc(((100% - (9 - 1) * 48px - 0.5 * 48px - 0.5 * 48px) / 9 * 9 + 8 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
    padding-left: calc(((100% - (9 - 1) * 48px - 0.5 * 48px - 0.5 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
    padding-right: calc(((100% - (9 - 1) * 48px - 0.5 * 48px - 0.5 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Slider--index .Slider-caption {
    width: calc(((100% - (9 - 1) * 32px - 0.5 * 32px - 0.5 * 2 * 48px) / 9 * 9 + 8 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Slider--index .Slider-caption {
    padding-left: calc(((100% - (9 - 1) * 32px - 0.5 * 32px - 0.5 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Slider--index .Slider-caption {
    padding-right: calc(((100% - (9 - 1) * 32px - 0.5 * 32px - 0.5 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Slider--index .Slider-counter {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #bebdbd;
  margin-bottom: 10px;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Slider--index .Slider-counter {
    margin-bottom: 12px;
  }
}
@media (max-width: 1199px) {
  .Slider--index .Slider-counter {
    margin-bottom: 10px;
  }
}
.Slider--index .Slider-counterCurrent {
  color: #e6102d;
  display: inline-block;
  min-width: 10px;
  margin-right: -1px;
}
.Slider--index .Slider-counterCurrent[data-digits-count='2'] {
  min-width: 18px;
  margin-right: -2px;
}
.Slider--index .Slider-captionTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 45px;
  margin-left: -1px;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Slider--index .Slider-captionTitle {
    font-size: 28px;
    line-height: 34px;
  }
}
@media (max-width: 1199px) {
  .Slider--index .Slider-captionTitle {
    font-size: 28px;
    line-height: 32px;
  }
}
.Slider--index .Slider-captionLink {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  color: #e6102d;
  display: inline-block;
  position: relative;
  margin-top: 18px;
}
.Slider--index .Slider-captionLink:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  opacity: .3;
  border-bottom: 4px solid #e6102d;
  transition: opacity 0.3s ease;
}
.Slider--index .Slider-captionLink:hover:after,
.Slider--index .Slider-captionLink:focus:after {
  opacity: 0;
}
.Slider--about,
.Slider--principles,
.Slider--career,
.Slider--production,
.Slider--excursion,
.Slider--post {
  padding-bottom: 0;
}
.Slider--about .owl-stage-outer:after,
.Slider--principles .owl-stage-outer:after,
.Slider--career .owl-stage-outer:after,
.Slider--production .owl-stage-outer:after,
.Slider--excursion .owl-stage-outer:after,
.Slider--post .owl-stage-outer:after {
  display: none;
}
.Slider--about .Slider-caption,
.Slider--principles .Slider-caption,
.Slider--career .Slider-caption,
.Slider--production .Slider-caption,
.Slider--excursion .Slider-caption,
.Slider--post .Slider-caption {
  display: flex;
  min-height: 96px;
  padding-top: 26px;
  padding-bottom: 20px;
  padding-left: 0;
}
.Slider--about .Slider-captionText,
.Slider--principles .Slider-captionText,
.Slider--career .Slider-captionText,
.Slider--production .Slider-captionText,
.Slider--excursion .Slider-captionText,
.Slider--post .Slider-captionText {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  color: #2e2e33;
  padding-left: calc(((100% - (6 - 1) * 48px - 1 * 48px - 0 * 48px) / 6 * 0 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 1px);
  padding-right: calc(((100% - (6 - 1) * 48px - 1 * 48px - 0 * 48px) / 6 * 0 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 1px);
}
@media (max-width: 1199px) {
  .Slider--about .Slider-captionText,
  .Slider--principles .Slider-captionText,
  .Slider--career .Slider-captionText,
  .Slider--production .Slider-captionText,
  .Slider--excursion .Slider-captionText,
  .Slider--post .Slider-captionText {
    padding-left: calc(((100% - (6 - 1) * 32px - 1 * 32px - 0 * 2 * 48px) / 6 * 0 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 1px);
  }
}
@media (max-width: 1199px) {
  .Slider--about .Slider-captionText,
  .Slider--principles .Slider-captionText,
  .Slider--career .Slider-captionText,
  .Slider--production .Slider-captionText,
  .Slider--excursion .Slider-captionText,
  .Slider--post .Slider-captionText {
    padding-right: calc(((100% - (6 - 1) * 32px - 1 * 32px - 0 * 2 * 48px) / 6 * 0 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 1px);
  }
}
.Slider--about .Slider-captionTitle,
.Slider--principles .Slider-captionTitle,
.Slider--career .Slider-captionTitle,
.Slider--production .Slider-captionTitle,
.Slider--excursion .Slider-captionTitle,
.Slider--post .Slider-captionTitle {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
}
.Slider--about .Slider-counterCurrent,
.Slider--principles .Slider-counterCurrent,
.Slider--career .Slider-counterCurrent,
.Slider--production .Slider-counterCurrent,
.Slider--excursion .Slider-counterCurrent,
.Slider--post .Slider-counterCurrent,
.Slider--about .Slider-counterTotal,
.Slider--principles .Slider-counterTotal,
.Slider--career .Slider-counterTotal,
.Slider--production .Slider-counterTotal,
.Slider--excursion .Slider-counterTotal,
.Slider--post .Slider-counterTotal {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.Slider--about .Slider-counter,
.Slider--principles .Slider-counter,
.Slider--career .Slider-counter,
.Slider--production .Slider-counter,
.Slider--excursion .Slider-counter,
.Slider--post .Slider-counter {
  text-align: center;
  color: #bebdbd;
  flex-shrink: 0;
  width: calc(((100% - (6 - 1) * 48px - 1 * 48px - 0 * 48px) / 6 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Slider--about .Slider-counter,
  .Slider--principles .Slider-counter,
  .Slider--career .Slider-counter,
  .Slider--production .Slider-counter,
  .Slider--excursion .Slider-counter,
  .Slider--post .Slider-counter {
    width: calc(((100% - (6 - 1) * 32px - 1 * 32px - 0 * 2 * 48px) / 6 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Slider--about .Slider-counterCurrent,
.Slider--principles .Slider-counterCurrent,
.Slider--career .Slider-counterCurrent,
.Slider--production .Slider-counterCurrent,
.Slider--excursion .Slider-counterCurrent,
.Slider--post .Slider-counterCurrent {
  color: #e6102d;
}
.Slider--about .Slider-captionLink,
.Slider--principles .Slider-captionLink,
.Slider--career .Slider-captionLink,
.Slider--production .Slider-captionLink,
.Slider--excursion .Slider-captionLink,
.Slider--post .Slider-captionLink {
  display: none;
}
.Slider--about,
.Slider--career {
  height: 720px;
}
.Slider--about .Slider-caption,
.Slider--career .Slider-caption {
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 6 + 5 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Slider--about .Slider-caption,
  .Slider--career .Slider-caption {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 6 + 5 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--about .Slider-caption,
  .Slider--career .Slider-caption {
    left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--about .Slider-caption,
  .Slider--career .Slider-caption {
    padding-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Slider--excursion {
  height: 600px;
}
.Slider--excursion .Slider-caption {
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 6 + 5 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-top: 22px;
  padding-bottom: 22px;
  min-height: 68px;
}
@media (max-width: 1199px) {
  .Slider--excursion .Slider-caption {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 6 + 5 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--excursion .Slider-caption {
    left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--excursion .Slider-caption {
    padding-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Slider--excursion .Slider-counterCurrent,
.Slider--excursion .Slider-counterTotal,
.Slider--excursion .Slider-captionText,
.Slider--excursion .Slider-captionTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: calc(24 / 14);
}
.Slider--principles {
  height: 630px;
}
.Slider--principles .Slider-caption {
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 6 + 5 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  left: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Slider--principles .Slider-caption {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 6 + 5 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--principles .Slider-caption {
    left: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Slider--principles .Slider-captionText {
  width: 100%;
}
.Slider--production {
  height: 528px;
}
.Slider--production .Slider-caption {
  width: calc(((100% - (7 - 1) * 48px - 0 * 48px - 0 * 48px) / 7 * 5 + 4 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  left: calc(((100% - (7 - 1) * 48px - 0 * 48px - 0 * 48px) / 7 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Slider--production .Slider-caption {
    width: calc(((100% - (7 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 7 * 5 + 4 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--production .Slider-caption {
    left: calc(((100% - (7 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 7 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--production .Slider-caption {
    padding-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Slider--post {
  height: 720px;
  background: #d8d8d8;
}
.Slider--post .Slider-caption {
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 6 + 5 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  min-height: 72px;
  padding-top: 22px;
  padding-bottom: 10px;
}
@media (max-width: 1199px) {
  .Slider--post .Slider-caption {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 6 + 5 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Slider--post .Slider-caption {
    left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Slider--post .Slider-captionText {
  padding-left: 0;
  padding-right: calc(((100% - (6 - 1) * 48px - 1 * 48px - 0 * 48px) / 6 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Slider--post .Slider-captionText {
    padding-right: calc(((100% - (6 - 1) * 32px - 1 * 32px - 0 * 2 * 48px) / 6 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
