.Production {
  height: 100%;
  background: #fafafc;
}
.Production-header {
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 10 + 9 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
  color: #e6102d;
  margin-bottom: 80px;
  padding-top: 200px;
}
@media (max-width: 1199px) {
  .Production-header {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 10 + 9 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Production-header {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
.Production-subtitle {
  display: flex;
  margin-bottom: 48px;
}
.Production-subtitleStroke {
  width: 100px;
  margin-right: 24px;
  border-bottom: 1px solid #e6102d;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Production-subtitleStroke {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 24px);
  }
}
.Production-subtitleText {
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  line-height: 8px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #e6102d;
}
.Production-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #0f0c1a;
  margin-bottom: 22px;
}
.Production-text {
  font-size: 20px;
  line-height: 32px;
  color: #0f0c1a;
}
.Production-aerialView {
  position: relative;
  width: 100%;
  margin-bottom: 89px;
  font-size: 0;
}
.Production-aerialViewImg {
  position: relative;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Production-aerialViewSvg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.Production-aerialViewPath {
  position: absolute;
  opacity: 0;
  fill: white;
  transition: opacity 0.3s ease 0.15s, fill 0.3s ease 0.15s;
}
.Production-aerialViewPath[state='foreground'] {
  opacity: .05;
  transition: opacity 0.3s ease, fill 0.3s ease;
}
.Production-aerialViewPath[state='background'] {
  opacity: .3;
  fill: black;
  transition: opacity 0.3s ease, fill 0.3s ease;
}
.Production .BuildingCross[data-id='1'] {
  top: 21%;
  left: 19%;
}
.Production .BuildingCross[data-id='2'] {
  top: 27%;
  left: 29%;
  z-index: 2;
}
.Production .BuildingCross[data-id='3'] {
  top: 22%;
  left: 33%;
}
.Production .BuildingCross[data-id='4'] {
  top: 29%;
  left: 44%;
}
.Production .BuildingCross[data-id='5'] {
  top: 20%;
  left: 45%;
}
.Production .BuildingCross[data-id='6'] {
  top: 33%;
  left: 53%;
}
.Production .BuildingCross[data-id='7'] {
  top: 43%;
  left: 69%;
}
.Production .BuildingCross[data-id='8'] {
  top: 93%;
  left: 79%;
}
.Production-contents {
  margin-bottom: 96px;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 10 + 9 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Production-contents {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 10 + 9 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Production-contents {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
.Production-contentsHeader {
  width: 100%;
}
.Production-contentsStroke {
  content: '';
  height: 27px;
  border-top: 1px solid red;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0.33 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Production-contentsStroke {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0.33 + -0.67 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Production-contentsColumns {
  display: flex;
}
.Production-contentsLeft {
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 7 + 6 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
  position: relative;
}
@media (max-width: 1199px) {
  .Production-contentsLeft {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 7 + 6 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Production-contentsLeft {
    margin-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
.Production-contentsLeft:after {
  height: 10px;
  width: 10px;
  background-color: red;
  border: 1px solid #e6102d;
}
.Production-contentsRight {
  padding-top: 23px;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 2 + 1 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Production-contentsRight {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 2 + 1 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Production-contentsRightHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  line-height: 32px;
  color: #e6102d;
  margin-bottom: 10px;
  white-space: nowrap;
}
.Production-contentsRightSpan {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 18px;
}
.Production-contentsRightDescription {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #78797a;
  margin-top: 1px;
  padding-left: 1px;
  margin-bottom: 61px;
}
.Production-capacity {
  margin-bottom: 10px;
}
.Production-contentsText {
  font-size: 20px;
  line-height: 32px;
  color: #1c1630;
  margin-bottom: 86px;
}
.Production-media {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.Production-mediaVideo {
  font-family: 'object-fit: cover; object-position: center';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.Production-mediaTexts {
  position: relative;
  padding: 168px 250px 166px 250px;
}
.Production-mediaTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  text-align: center;
  color: #1c1630;
  width: 100%;
  margin-bottom: 19px;
}
.Production-mediaSubtitle {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  color: #78797a;
  width: 100%;
}
