.PopupAge {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 25;
}
.PopupAge-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .8;
  background: #0f0c1a;
  z-index: 1;
}
.PopupAge-content {
  display: flex;
  position: absolute;
  height: 360px;
  width: 768px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding-top: 60px;
  background: #fff;
  transition: transform 0.3s ease;
  z-index: 2;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  transform: translateY(-20px);
  box-shadow: 0 30px 50px 0 rgba(0, 0, 0, 0.3);
}
.PopupAge-content.isVisible {
  transform: translateY(0);
}
.PopupAge-logo {
  position: relative;
  height: 60px;
  width: 39px;
  fill: #e6102d;
}
.PopupAge-logo svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.PopupAge-question {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 43px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 1px;
  color: #1c1630;
  margin-top: 32px;
}
.PopupAge-controls {
  display: flex;
  margin-top: 46px;
}
.PopupAge-control {
  width: 180px;
}
.PopupAge-control--accept {
  margin-right: 20px;
}
