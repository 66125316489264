.Brand {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  transition: transform .6s ease;
}
.Brand.isExiting {
  transform: scale(0.9);
}
.Brand.beforeEnter {
  transform: scale(0.9);
}
.Brand-content {
  display: flex;
  align-items: stretch;
  flex: 0 1 auto;
  transition: height .3s ease;
  transition-delay: .3s;
}
.Brand-media {
  position: -webkit-sticky;
  position: sticky;
  height: 100vh;
  top: 0;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 6 + 5 * 48px + 0.5 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-media {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 6 + 5 * 32px + 0.5 * 32px + 1 * 48px) + 0px);
  }
}
.Brand-back {
  position: absolute;
  left: 28px;
  top: 124px;
  z-index: 1;
  font-size: 15px;
  font-weight: 500;
  transition: color 0.15s ease;
}
.Brand-back:hover {
  color: #e6102d;
}
.Brand-back svg {
  stroke: currentColor;
  width: 12px;
  height: 10px;
  transform: rotate(90deg);
  margin-right: 5px;
}
.Brand-img .BrandImage {
  position: absolute;
  height: calc(100% - 320px);
  width: 100%;
  top: 150px;
  bottom: 150px;
  left: 0;
  right: 0;
  z-index: 0;
}
.Brand-img .BrandImage-item {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.Brand-img .BrandImage .owl-stage-outer,
.Brand-img .BrandImage .owl-stage,
.Brand-img .BrandImage .owl-item {
  height: 100%;
}
.Brand-img .BrandImage .owl-dots {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.Brand-img .BrandImage .owl-dots button.owl-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  background: #d8d8d8;
  margin: 0 10px;
  position: relative;
}
.Brand-img .BrandImage .owl-dots button.owl-dot:before {
  content: "";
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
.Brand-img .BrandImage .owl-dots button.owl-dot.active {
  background-color: #e6102d;
}
.Brand-img .BrandImage .owl-dots button.owl-dot:focus {
  outline: none;
}
.Brand-img .BrandImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
}
.Brand-img .BrandImage.beforeEnter {
  opacity: 0;
  transform: translateX(100%);
}
.Brand-arrowLink {
  position: absolute;
  height: 26px;
  width: 13px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: none;
  border: none;
  stroke: rgba(145, 145, 153, 0.4);
  transition: stroke 0.3s ease;
  cursor: pointer;
  z-index: 1;
}
.Brand-arrow--prev .Brand-arrowLink {
  left: 38px;
}
.Brand-arrow--next .Brand-arrowLink {
  right: 38px;
}
.Brand-arrowLink:hover,
.Brand-arrowLink:focus {
  stroke: #616166;
}
.Brand-arrowLink:hover + .Brand-arrowContent,
.Brand-arrowLink:focus + .Brand-arrowContent {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.Brand-arrowIcon {
  position: absolute;
  height: 100%;
  width: 13px;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.Brand-arrowIcon svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}
.Brand-arrow--next .Brand-arrowIcon {
  right: 0;
  transform: scale(-1);
}
.Brand-arrow--prev .Brand-arrowIcon {
  left: 0;
}
.Brand-arrowContent {
  display: flex;
  visibility: hidden;
  position: absolute;
  align-items: flex-start;
  max-width: 300px;
  min-height: 70px;
  top: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  opacity: 0;
  background: #fff;
  transition: opacity 0.3s ease, visibility 0.3s linear 0.3s;
  transform: translateY(-50%);
  border-radius: 2px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}
.Brand-arrow--prev .Brand-arrowContent {
  left: 24px;
  padding-left: 61px;
  padding-right: 6px;
}
.Brand-arrow--prev .Brand-arrowContent .Brand-arrowImg img {
  margin-left: 9px;
  margin-right: 0;
}
.Brand-arrow--next .Brand-arrowContent {
  right: 24px;
  padding-left: 6px;
  padding-right: 61px;
}
.Brand-arrowImg {
  flex-shrink: 0;
  align-self: center;
  height: 50px;
  width: 36px;
  margin-right: 9px;
}
.Brand-arrowImg img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Brand-arrowText {
  flex-shrink: 0;
  max-width: 150px;
  margin-top: 5px;
}
.Brand-arrowTitle {
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  white-space: normal;
  color: #1c1630;
  display: block;
  margin-bottom: 1px;
}
.Brand-arrowSubtitle {
  font-size: 12px;
  line-height: 20px;
  white-space: normal;
  color: #7a7a7a;
  display: block;
  height: 20px;
}
.Brand-warning {
  position: absolute;
  height: 10vh;
  width: calc(100% - 48px);
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: .29;
  fill: #ccd6dd;
}
.Brand-warning svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Brand-description {
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;
  background: #fff;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 6 + 5 * 48px + 0.5 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-description {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 6 + 5 * 32px + 0.5 * 32px + 1 * 48px) + 0px);
  }
}
.Brand-descriptionInner {
  position: relative;
  padding-top: 111px;
}
.Brand-descriptionInner.isExiting {
  position: absolute;
  top: 0;
  left: 24px;
  right: 24px;
}
.Brand-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 43px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 1px;
  color: #1c1630;
  width: 390px;
}
.Brand-link {
  margin-top: 11px;
}
.Brand-social {
  position: absolute;
  top: 132px;
  right: 16px;
}
.Brand-styles {
  margin-top: 31px;
}
.Brand-styles .StyleTag {
  margin-bottom: 5px;
}
.Brand-factList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 27px;
}
.Brand-fact {
  position: relative;
  padding-top: 19px;
  padding-bottom: 14px;
}
.Brand-fact:nth-child(3n - 2) {
  width: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-fact:nth-child(3n - 2) {
    width: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-fact:nth-child(3n - 2):after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #e9eaf3;
  width: calc(((100% - (2 - 1) * 48px - 1 * 48px - 0 * 48px) / 2 * 6 + 5 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-fact:nth-child(3n - 2):after {
    width: calc(((100% - (2 - 1) * 32px - 1 * 32px - 0 * 2 * 48px) / 2 * 6 + 5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-fact:nth-child(3n - 1) {
  width: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-fact:nth-child(3n - 1) {
    width: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-fact:nth-child(3n) {
  width: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 2 + 1 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-fact:nth-child(3n) {
    width: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 2 + 1 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-fact--package {
  padding-bottom: 21px;
  width: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 6 + 5 * 48px + 0 * 48px + 0 * 48px / 2) + 0px) !important;
}
@media (max-width: 1199px) {
  .Brand-fact--package {
    width: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 6 + 5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-fact:nth-child(4n) + .Brand-fact--package {
  width: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 4 + 3 * 48px + 0 * 48px + 0 * 48px / 2) + 0px) !important;
}
@media (max-width: 1199px) {
  .Brand-fact:nth-child(4n) + .Brand-fact--package {
    width: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 4 + 3 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-factLabel {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Brand-factTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  color: #1c1630;
  margin-top: 1px;
}
.Brand-factSubtitle {
  font-size: 12px;
  color: #94949d;
}
.Brand-packageList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
}
.Brand-package {
  line-height: 20px;
  height: 24px;
  padding-left: 7px;
  padding-right: 8px;
  border: 1px solid #edeef2;
  border-radius: 2px;
  margin-bottom: 12px;
}
.Brand-package:not(:last-child) {
  margin-right: 12px;
}
.Brand-packageTitle {
  font-size: 12px;
  color: #1c1630;
  margin-right: 2px;
}
.Brand-packageVolume {
  font-size: 12px;
  color: #ccd6dd;
}
.Brand-text {
  font-size: 18px;
  line-height: 28px;
  color: #1c1630;
  padding-top: 27px;
  padding-bottom: 28px;
  border-bottom: 1px solid #e9eaf3;
}
.Brand-text strong {
  font-weight: 500;
}
.Brand-text a {
  text-decoration: underline;
}
.Brand-text a:hover,
.Brand-text a:focus {
  text-decoration: none;
}
.Brand-buttonText {
  display: none;
  vertical-align: middle;
  position: relative;
  height: 21px;
  width: 21px;
  margin-left: 8px;
  background: #1c1630;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  transition: opacity 0.15s ease;
}
.Brand-buttonText:hover {
  opacity: .7;
}
.Brand-buttonText svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 3px;
  width: 13px;
  fill: #fff;
}
.Brand-snackSection {
  padding-top: 22px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e9eaf3;
}
.Brand-snackHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  color: #1c1630;
}
.Brand-snackList {
  margin-top: 28px;
  font-size: 0;
}
.Brand-snack {
  width: 33.33%;
  display: inline-block;
  margin-bottom: 24px;
}
@media (max-width: 1199px) {
  .Brand-snack {
    width: 50%;
  }
}
.Brand-snackImg {
  display: inline-block;
  width: 40px;
  vertical-align: top;
  margin-right: 16px;
}
.Brand-snackImgInner {
  position: relative;
  height: 40px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
}
.Brand-snackImgInner svg {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  fill: #e6102d;
}
.Brand-snackContent {
  display: inline-block;
  vertical-align: top;
}
.Brand-snackTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: #1c1630;
  margin-bottom: -3px;
}
.Brand-awardsSection {
  padding-top: 22px;
  padding-bottom: 24px;
}
.Brand-awardsHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  color: #1c1630;
}
.Brand-awardsList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 28px;
}
.Brand-award {
  display: flex;
  margin-bottom: 24px;
  width: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-award {
    width: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-award:nth-child(odd) {
  margin-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-award:nth-child(odd) {
    margin-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-awardImg {
  flex-shrink: 0;
  width: 40px;
  margin-right: 16px;
}
.Brand-awardImgInner {
  position: relative;
  height: 40px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
}
.Brand-awardImgInner img {
  display: block;
  position: absolute;
  height: 40px;
  width: 40px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Brand-awardContent {
  flex: 1 1;
  padding-top: 2px;
}
.Brand-awardTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #1c1630;
  margin-bottom: -3px;
}
.Brand-awardSubtitle {
  font-size: 12px;
  line-height: 20px;
  color: #919199;
}
.Brand-locations {
  display: none;
  height: 540px;
  background: #12151b;
}
.Brand-locations--active {
  display: block;
}
.Brand-also {
  display: flex;
}
.Brand-alsoHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  position: relative;
  flex-shrink: 0;
  align-self: flex-start;
  margin-top: 64px;
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  margin-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-alsoHeader {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Brand-alsoHeader {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Brand-alsoHeader {
    margin-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-alsoHeader:after {
  content: '';
  position: absolute;
  width: 24px;
  bottom: -17px;
  left: 0;
  border-bottom: 1px solid #e6102d;
}
.Brand-alsoGrid {
  background: #fff;
  border-top: 1px solid #edeef2;
  border-left: 1px solid #edeef2;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 9 + 8 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-alsoGrid {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 9 + 8 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
