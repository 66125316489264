.Link {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  color: #e6102d;
  display: inline-block;
  position: relative;
}
.Link:after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  opacity: .3;
  border-bottom: 4px solid #e6102d;
  transition: opacity 0.3s ease;
}
.Link:hover:after,
.Link:focus:after {
  opacity: 0;
}
.Link svg {
  fill: #ccd6dd;
}
.Link--icon-arrowInfo svg {
  position: absolute;
  height: 8px;
  width: 16px;
  bottom: 5px;
  left: -25px;
  stroke: #e6102d;
  transition: transform 0.15s ease;
  transform: rotate(180deg);
}
.Link--icon-arrowInfo:hover svg,
.Link--icon-arrowInfo:focus svg {
  transform: rotate(180deg) translateX(5px);
}
.Link--icon-link svg {
  position: absolute;
  height: 13px;
  width: 13px;
  top: 3px;
  left: calc(100% + 7px);
}
