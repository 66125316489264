.Contact {
  margin-top: -90px;
  min-height: calc(100vh - 368px);
  position: relative;
  padding-top: 90px;
}
.Contact .mapboxgl-ctrl {
  display: none;
}
.Contact .Contact-map {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.Contact-marker {
  position: relative;
  height: 15px;
  width: 15px;
  background: #e6102d;
  border-radius: 50%;
}
.Contact-marker:before,
.Contact-marker:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: 0;
  margin-left: 0;
  border-radius: 50%;
}
.Contact-marker:before {
  box-shadow: 0 0 0 1px #e6102d;
}
.Contact-marker:after {
  background: #e6102d;
  animation: buttonGrow 2s ease-out infinite;
  box-shadow: 0 0 0 3px #e6102d;
}
.Contact-marker:active {
  box-shadow: 0 0 0 3px #e6102d;
}
.Contact-contentWrapper {
  background: #fff;
  margin-top: 82px;
  min-height: calc(100vh - 368px);
  position: relative;
  z-index: 5;
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 4 + 3 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 8 + 7 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  padding: 48px 48px 108px;
}
@media (max-width: 1199px) {
  .Contact-contentWrapper {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 4 + 3 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Contact-contentWrapper {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 8 + 7 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Contact-contentWrapper {
    width: auto;
  }
}
.Contact-title {
  color: #1c1630;
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: calc(64 / 56);
  letter-spacing: 0;
  margin: 0;
  padding-right: 128px;
}
.Contact-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 36px;
  margin: 60px 0 0;
}
@media (max-width: 1199px) {
  .Contact-content {
    flex-direction: column;
  }
}
.Contact-contentGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.Contact-contentGridItem {
  background: #000;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  height: 200px;
  width: calc(50% - 10px);
  overflow: hidden;
}
.Contact-contentGridLink {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: calc(26 / 20);
  padding: 25px;
  position: relative;
  text-decoration: none;
  height: 100%;
  width: 100%;
}
.Contact-contentGridLink span {
  padding-right: 50px;
  position: relative;
  transition: opacity 0.35s ease;
  z-index: 2;
}
.Contact-contentGridLink::before {
  content: '';
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translate(0, 0) scale(1);
  transition: transform 0.35s ease;
  z-index: 1;
}
.Contact-contentGridLink--excursion::before {
  background: url('/static/img/front/contacts/bg-excursion.png') no-repeat 50% 50% / cover;
}
.Contact-contentGridLink--partner::before {
  background: url('/static/img/front/contacts/bg-partner.png') no-repeat 50% 50% / cover;
}
.Contact-contentGridLink--quality::before {
  background: url('/static/img/front/contacts/bg-quality.png') no-repeat 50% 50% / cover;
}
.Contact-contentGridLink::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 29 16'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23fff' d='m21.63.07-1.6 1.57 4.88 5.24H0v2.28h24.87l-4.84 5.2 1.6 1.57L29.03 8 21.63.07Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h29v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") no-repeat 100% 50%;
  content: '';
  position: absolute;
  right: 25px;
  bottom: 30px;
  height: 16px;
  width: 29px;
  transition: opacity 0.35s ease;
  z-index: 2;
}
.Contact-contentGridLink:hover::before {
  transform: scale(1.1);
}
.Contact-contentGridLink:hover span,
.Contact-contentGridLink:hover::after {
  opacity: 0.8;
}
.Contact-contentInfoSection {
  margin-bottom: 40px;
}
.Contact-contentInfoSectionTitle {
  color: #e6102d;
  font-size: 10px;
  font-weight: 500;
  line-height: calc(18 / 10);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 0 6px;
}
.Contact-contentInfoSectionText {
  font-size: 16px;
  line-height: calc(24 / 16);
}
.Contact-contentInfoSectionText p {
  margin: 0 0 6px;
}
.Contact-contentInfoSectionText a {
  color: #e6102d;
  text-decoration: underline;
}
.Contact-contentInfoSectionText a:hover {
  text-decoration: none;
}
.Contact-button {
  border: 1px solid #ccd6dd;
  border-radius: 2px;
  color: #616166;
  display: inline-block;
  font-size: 15px;
  line-height: 42px;
  margin: 12px 0 0;
  padding: 0 44px;
  height: 44px;
  width: auto;
  transition: color 0.3s ease, border-color 0.3s ease;
}
.Contact-button:hover,
.Contact-button:focus {
  background: none;
  color: #e6102d;
  border-color: #e6102d;
}
.Contact-button:focus {
  outline: 0;
}
.Contact-qualityFormWrapper {
  position: relative;
  display: none;
}
.Contact-qualityFormClose {
  position: absolute;
  top: -48px;
  right: -48px;
}
.Contact-qualityFormText {
  font-size: 20px;
  line-height: calc(32 / 20);
  margin: 32px 0 0;
  max-width: 900px;
}
@keyframes buttonGrow {
  0% {
    opacity: 1;
  }
  40% {
    height: 600%;
    width: 600%;
    margin-top: -250%;
    margin-left: -250%;
    background: rgba(230, 16, 45, 0);
    box-shadow: 0 0 0 1px #e6102d;
  }
  45% {
    box-shadow: 0 0 0 3px rgba(230, 16, 45, 0);
    background: rgba(230, 16, 45, 0);
  }
  60% {
    opacity: 0;
    background: rgba(230, 16, 45, 0);
    box-shadow: 0 0 0 3px rgba(230, 16, 45, 0);
  }
  100% {
    height: 100%;
    width: 100%;
    margin-top: -250%;
    margin-left: -250%;
    opacity: 0;
  }
}
