.CareerVacancyApply {
  padding-top: 170px;
  padding-bottom: 140px;
}
@media (max-width: 1199px) {
  .CareerVacancyApply {
    padding-top: 56px;
    padding-bottom: 80px;
  }
}
.CareerVacancyApply-stepsNav {
  color: #1c1630;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: calc(21 / 20);
  list-style: none;
  margin: 98px auto 90px;
  padding: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .CareerVacancyApply-stepsNav {
    margin: 56px auto;
  }
}
.CareerVacancyApply-stepsNav::before,
.CareerVacancyApply-stepsNav::after {
  background: #ccd6dd;
  content: '';
  position: absolute;
  top: 14px;
  left: 0;
  right: 0;
  height: 2px;
  z-index: 1;
}
.CareerVacancyApply-stepsNav::after {
  background: #1c1630;
  right: auto;
  width: 0;
  z-index: 2;
}
.CareerVacancyApply-stepsNav[data-step="1"]::after {
  width: 25%;
}
@media (max-width: 1199px) {
  .CareerVacancyApply-stepsNav[data-step="1"]::after {
    width: 25%;
  }
}
.CareerVacancyApply-stepsNav[data-step="2"]::after {
  width: 45%;
}
@media (max-width: 1199px) {
  .CareerVacancyApply-stepsNav[data-step="2"]::after {
    width: 50%;
  }
}
.CareerVacancyApply-stepsNav[data-step="3"]::after {
  width: 66%;
}
@media (max-width: 1199px) {
  .CareerVacancyApply-stepsNav[data-step="3"]::after {
    width: 75%;
  }
}
.CareerVacancyApply-stepsNav[data-step="4"]::after {
  width: 100%;
}
.CareerVacancyApply-stepsNav li {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.CareerVacancyApply-stepsNav li::before {
  background: #ccd6dd;
  content: '';
  margin: 0 auto;
  display: block;
  position: relative;
  height: 30px;
  width: 30px;
  z-index: 3;
}
.CareerVacancyApply-stepsNav li:first-child {
  text-align: left;
}
.CareerVacancyApply-stepsNav li:first-child::before {
  margin: 0;
}
.CareerVacancyApply-stepsNav li:last-child {
  text-align: right;
}
.CareerVacancyApply-stepsNav li:last-child::before {
  margin: 0 0 0 auto;
}
.CareerVacancyApply-stepsNav li.isCurrentStep::before {
  background: #e60f2d;
}
.CareerVacancyApply-stepsNav li.isPastStep::before {
  background: #1c1630;
}
.CareerVacancyApply-stepsNavItem {
  display: block;
  margin: 17px 0 0;
}
@media (max-width: 1199px) {
  .CareerVacancyApply-stepsNavItem {
    display: none;
  }
}
.CareerVacancyApplyForm {
  font-size: 20px;
  line-height: 32px;
}
.CareerVacancyApplyForm-step {
  display: none;
}
.CareerVacancyApplyForm-step.isCurrentStep {
  display: block;
}
.CareerVacancyApplyForm-fieldGroupEducation {
  max-width: calc(50% - 10px);
}
.CareerVacancyApplyForm-workWrapper {
  position: relative;
}
.CareerVacancyApplyForm-noWorkExperience {
  position: absolute;
  right: 0;
  top: -64px;
}
.CareerVacancyApplyForm-noWorkExperience .CommonNewForm-fieldGroupCheckbox {
  margin-top: 0;
}
@media (max-width: 1199px) {
  .CareerVacancyApplyForm-noWorkExperience {
    top: -48px;
  }
}
.CareerVacancyApplyForm-successMessage {
  color: #1c1630;
  font-size: 20px;
  line-height: calc(32 / 20);
  margin: 90px 0 50px;
}
@media (max-width: 1199px) {
  .CareerVacancyApplyForm-successMessage {
    font-size: 18px;
    line-height: calc(22 / 18);
    margin: 56px 0 32px;
  }
}
