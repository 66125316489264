.TopNav {
  position: relative;
  z-index: 12;
  /*
    &--index {

        body.lang--ru & {
            width: calc(100% - 323px);

            @media @device-medium, @device-small {
                .calcgrid(
                    @property: width; @columns: 9; @total-columns: 12; @has-last-gutter: 0.5; @total-padding: 1;
                    @has-padding: 1
                );
            }
        }

        @{c}-tour {
            margin-right: 0;
        }
    }
    */
}
.TopNav-inner {
  display: flex;
  position: absolute;
  align-items: stretch;
  height: 65px;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 28px;
  transition: transform 0.3s ease-in;
}
.TopNav-inner .ExcursionPage,
.PostPage .TopNav-inner {
  transform: translateY(-65px);
}
.TopNav-logo {
  position: relative;
  align-self: center;
  margin-top: 1px;
  fill: #e6102d;
}
.TopNav-logo svg {
  display: block;
  height: 36px;
  width: 24px;
  margin: 0 auto;
  pointer-events: none;
}
.TopNav-links {
  display: flex;
  align-items: center;
  flex: 1 1;
  margin-left: 28px;
}
.TopNav-link {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  height: 28px;
  white-space: nowrap;
  transition: color 0.3s ease;
}
.TopNav-link--ru {
  display: none;
}
body.lang--ru .TopNav-link--ru {
  display: inline-block;
}
.TopNav-link.isActive,
.TopNav-link:hover,
.TopNav-link:focus {
  color: #e6102d !important;
}
.TopNav-link:not(:last-child) {
  margin-right: 40px;
}
.TopNav-tour {
  display: flex;
  align-self: center;
  margin-left: 20px;
}
.TopNav-tour button:focus {
  outline: 0;
}
.TopNav--excursion {
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 11 + 10 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .TopNav--excursion {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 11 + 10 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
.TopNav--floatNav {
  z-index: 13;
}
.TopNav--floatNav.isActive .TopNav-inner {
  visibility: visible;
  transition: transform 0.3s ease, visibility 0s ease;
  transform: translateY(0);
}
.TopNav--floatNav .TopNav-inner {
  visibility: hidden;
  position: fixed;
  width: 100%;
  min-width: 1024px;
  background: #fff;
  border-bottom: 1px solid #edeef2;
  transition: transform 0.3s ease, visibility 0s ease 0.3s;
  transform: translateY(-100%);
}
.TopNav--floatNav .TopNav-link {
  font-size: 15px;
  line-height: 30px;
  color: #000;
  margin-right: 40px;
}
.TopNav--floatNav .TopNav-tour {
  margin-right: 0;
}
.TopNav.TopNav--contact {
  height: 65px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 21;
}
.TopNav.TopNav--fixed {
  z-index: 21;
}
.TopNav.TopNav--fixed.isExiting .TopNav-inner {
  background-color: #fff !important;
  transform: translateY(-100%) !important;
}
.TopNav.TopNav--fixed.isExiting .TopNav-inner:after {
  border-color: #edeef2 !important;
}
.TopNav.TopNav--fixed .TopNav-inner {
  position: fixed;
  width: 100%;
  transition: background-color 0.7s ease, transform 0.3s ease;
  transform: translate(0, 0);
}
.TopNav.TopNav--fixed .TopNav-inner:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  border-left: 8px solid #e6102d;
  z-index: 2;
}
.TopNav.TopNav--fixed .TopNav-inner:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(237, 238, 242, 0);
  transition: border-color 0.7s ease;
  z-index: 1;
}
.TopNav.TopNav--fixed .TopNav-link:not(:hover) {
  transition: color 0.15s ease;
}
.TopNav.TopNav--fixed.isActive .TopNav-inner {
  background-color: #fff;
}
.TopNav.TopNav--fixed.isActive .TopNav-inner:after {
  border-color: #edeef2;
}
.TopNav.TopNav--fixed.isActive .TopNav-inner .TopNav-link:not(:hover) {
  color: #1c1630;
  transition: color 0.15s ease;
}
.TopNav.TopNav--fixed button {
  line-height: 44px;
  height: 44px;
  padding-left: 44px;
  padding-right: 44px;
}
.TopNav.TopNav--sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 13;
}
.TopNav.TopNav--sticky .TopNav-inner {
  position: static;
  border-bottom: 1px solid rgba(237, 238, 242, 0);
  transition: background-color 0.7s ease, border-color 0.7s ease;
}
.TopNav.TopNav--sticky .TopNav-link:not(:hover) {
  transition: color 0.15s ease;
}
.TopNav.TopNav--sticky.isActive .TopNav-inner {
  background-color: #fff;
  border-color: #edeef2;
}
.TopNav.TopNav--sticky.isActive .TopNav-inner .TopNav-link:not(:hover) {
  color: #1c1630;
  transition: color 0.15s ease;
}
.TopNav.TopNav--about .TopNav-inner,
.TopNav.TopNav--history .TopNav-inner,
.TopNav.TopNav--career .TopNav-inner,
.TopNav.TopNav--production .TopNav-inner {
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
}
.TopNav.TopNav--about .TopNav-link:not(:hover),
.TopNav.TopNav--history .TopNav-link:not(:hover),
.TopNav.TopNav--career .TopNav-link:not(:hover),
.TopNav.TopNav--production .TopNav-link:not(:hover) {
  color: #1c1630;
  transition: color 0.15s ease;
}
.TopNav.TopNav--brand .TopNav-inner {
  width: 100%;
  background-color: #fafafc;
}
.TopNav.TopNav--brand .TopNav-link:not(:hover) {
  color: #1c1630;
  transition: color 0.15s ease;
}
.TopNav.TopNav--posts .TopNav-tour {
  margin-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .TopNav.TopNav--posts .TopNav-tour {
    margin-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.TopNav.TopNav--posts .TopNav-inner {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ebebf6;
}
.TopNav.TopNav--posts .TopNav-inner:after {
  content: none;
}
.TopNav.TopNav--posts .TopNav-link:not(:hover) {
  color: #1c1630;
  transition: color 0.15s ease;
}
.TopNav .TopNav-inner--isInactive {
  transform: translate3d(0, -100%, 0) !important;
}
