@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-BoldItalic.eot');
  src: url('/static/fonts/GraphikLCG-BoldItalic.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-BoldItalic.woff2') format('woff2'), url('/static/fonts/GraphikLCG-BoldItalic.woff') format('woff'), url('/static/fonts/GraphikLCG-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-Light.eot');
  src: url('/static/fonts/GraphikLCG-Light.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-Light.woff2') format('woff2'), url('/static/fonts/GraphikLCG-Light.woff') format('woff'), url('/static/fonts/GraphikLCG-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-LightItalic.eot');
  src: url('/static/fonts/GraphikLCG-LightItalic.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-LightItalic.woff2') format('woff2'), url('/static/fonts/GraphikLCG-LightItalic.woff') format('woff'), url('/static/fonts/GraphikLCG-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-Semibold.eot');
  src: url('/static/fonts/GraphikLCG-Semibold.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-Semibold.woff2') format('woff2'), url('/static/fonts/GraphikLCG-Semibold.woff') format('woff'), url('/static/fonts/GraphikLCG-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-Extralight.eot');
  src: url('/static/fonts/GraphikLCG-Extralight.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-Extralight.woff2') format('woff2'), url('/static/fonts/GraphikLCG-Extralight.woff') format('woff'), url('/static/fonts/GraphikLCG-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG Super';
  src: url('/static/fonts/GraphikLCG-SuperItalic.eot');
  src: url('/static/fonts/GraphikLCG-SuperItalic.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-SuperItalic.woff2') format('woff2'), url('/static/fonts/GraphikLCG-SuperItalic.woff') format('woff'), url('/static/fonts/GraphikLCG-SuperItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-MediumItalic.eot');
  src: url('/static/fonts/GraphikLCG-MediumItalic.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-MediumItalic.woff2') format('woff2'), url('/static/fonts/GraphikLCG-MediumItalic.woff') format('woff'), url('/static/fonts/GraphikLCG-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-Black.eot');
  src: url('/static/fonts/GraphikLCG-Black.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-Black.woff2') format('woff2'), url('/static/fonts/GraphikLCG-Black.woff') format('woff'), url('/static/fonts/GraphikLCG-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-Regular.eot');
  src: url('/static/fonts/GraphikLCG-Regular.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-Regular.woff2') format('woff2'), url('/static/fonts/GraphikLCG-Regular.woff') format('woff'), url('/static/fonts/GraphikLCG-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-Thin.eot');
  src: url('/static/fonts/GraphikLCG-Thin.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-Thin.woff2') format('woff2'), url('/static/fonts/GraphikLCG-Thin.woff') format('woff'), url('/static/fonts/GraphikLCG-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-BlackItalic.eot');
  src: url('/static/fonts/GraphikLCG-BlackItalic.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-BlackItalic.woff2') format('woff2'), url('/static/fonts/GraphikLCG-BlackItalic.woff') format('woff'), url('/static/fonts/GraphikLCG-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG Super';
  src: url('/static/fonts/GraphikLCG-Super.eot');
  src: url('/static/fonts/GraphikLCG-Super.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-Super.woff2') format('woff2'), url('/static/fonts/GraphikLCG-Super.woff') format('woff'), url('/static/fonts/GraphikLCG-Super.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-Bold.eot');
  src: url('/static/fonts/GraphikLCG-Bold.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-Bold.woff2') format('woff2'), url('/static/fonts/GraphikLCG-Bold.woff') format('woff'), url('/static/fonts/GraphikLCG-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-ExtralightItalic.eot');
  src: url('/static/fonts/GraphikLCG-ExtralightItalic.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-ExtralightItalic.woff2') format('woff2'), url('/static/fonts/GraphikLCG-ExtralightItalic.woff') format('woff'), url('/static/fonts/GraphikLCG-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'BerlingskeSerifCn-Md';
  src: url('/static/fonts/BerlingskeSerifCn-Md.woff2') format('woff2'), url('/static/fonts/BerlingskeSerifCn-Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-Medium.eot');
  src: url('/static/fonts/GraphikLCG-Medium.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-Medium.woff2') format('woff2'), url('/static/fonts/GraphikLCG-Medium.woff') format('woff'), url('/static/fonts/GraphikLCG-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-RegularItalic.eot');
  src: url('/static/fonts/GraphikLCG-RegularItalic.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-RegularItalic.woff2') format('woff2'), url('/static/fonts/GraphikLCG-RegularItalic.woff') format('woff'), url('/static/fonts/GraphikLCG-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-ThinItalic.eot');
  src: url('/static/fonts/GraphikLCG-ThinItalic.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-ThinItalic.woff2') format('woff2'), url('/static/fonts/GraphikLCG-ThinItalic.woff') format('woff'), url('/static/fonts/GraphikLCG-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('/static/fonts/GraphikLCG-SemiboldItalic.eot');
  src: url('/static/fonts/GraphikLCG-SemiboldItalic.eot?#iefix') format('embedded-opentype'), url('/static/fonts/GraphikLCG-SemiboldItalic.woff2') format('woff2'), url('/static/fonts/GraphikLCG-SemiboldItalic.woff') format('woff'), url('/static/fonts/GraphikLCG-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
