.Grid {
  font-size: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  min-width: 1024px;
  top: 0;
  left: 0;
  padding: 0;
  z-index: 25;
}
.Grid-col {
  display: inline-block;
  position: relative;
  height: 100%;
  background-color: rgba(0, 233, 23, 0.2);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Grid-col {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Grid-gutter {
  display: inline-block;
  position: relative;
  height: 100%;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Grid-gutter {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Grid-pad {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 24px;
}
@media (max-width: 1199px) {
  .Grid-pad {
    width: 48px;
  }
}
