.Notify {
  display: flex;
  position: relative;
  height: 80px;
  background: #fff;
  z-index: 25;
}
.Notify-img {
  flex: 0 1 auto;
  width: 380px;
  position: relative;
}
.Notify-img img {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: left;
     object-position: left;
}
.Notify-text {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: .05px;
  color: #1c1630;
  display: flex;
  margin-left: 40px;
  margin-right: 210px;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Notify-text {
    font-size: 18px;
  }
}
@media (max-width: 1199px) {
  .Notify-text {
    font-size: 16px;
  }
}
.Notify-text a {
  color: #e6102d;
  display: inline-block;
  position: relative;
  margin-left: 2px;
}
.Notify-text a:hover:after,
.Notify-text a:focus:after {
  border-color: rgba(230, 16, 45, 0);
}
.Notify-text a:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 1px;
  right: 1px;
  border-bottom: 3px solid rgba(230, 16, 45, 0.3);
  transition: border-color 0.3s ease;
}
.Notify-close {
  flex: 0 1 auto;
}
