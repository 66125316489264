.Input {
  position: relative;
}
.Input svg {
  position: absolute;
  height: 18px;
  width: 46px;
  top: 60px;
  right: 0;
}
.Input svg:hover {
  cursor: pointer;
}
