.ViewCount {
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 5px;
  padding-bottom: 4px;
  padding-left: 5px;
  padding-right: 8px;
}
.ViewCount:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .4;
  background: #1c1630;
  z-index: 5;
  border-radius: 10px;
}
.ViewCount-icon {
  position: relative;
  height: 11px;
  width: 16px;
  margin-right: 5px;
  fill: #fff;
  z-index: 6;
}
.ViewCount-icon svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.ViewCount-value {
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  position: relative;
  z-index: 6;
}
.ViewCount--light:after {
  background: #edeef2;
}
.ViewCount--light .ViewCount-icon {
  fill: #919199;
}
.ViewCount--light .ViewCount-value {
  color: #919199;
}
.ViewCount--post {
  padding: 0;
}
.ViewCount--post:after {
  background: none;
}
.ViewCount--post svg {
  fill: #616166;
}
.ViewCount--post .ViewCount-icon {
  height: 16px;
  width: 24px;
  margin-top: 3px;
  margin-left: 1px;
  margin-right: 8px;
}
.ViewCount--post .ViewCount-value {
  font-size: 22px;
  line-height: 22px;
  color: #616166;
  margin-top: 1px;
}
