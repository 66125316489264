.LegalMenuLeft {
  height: 100%;
  padding-top: 228px;
}
.LegalMenuLeft-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #f7f8fc;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: calc(25 / 15);
  padding: 9px 0;
  transition: color 0.3s ease;
}
.LegalMenuLeft-item:hover {
  color: #e6102d;
  transition: color 0.3s ease;
  cursor: pointer;
}
.LegalMenuLeft-item.isActive {
  color: #e6102d;
}
.LegalMenuLeft--legal .LegalMenuLeft-item,
.LegalMenuLeft--partners .LegalMenuLeft-item {
  color: #0f0c1a;
  border-bottom: 1px solid #ebebf6;
}
.LegalMenuLeft--legal .LegalMenuLeft-item:hover,
.LegalMenuLeft--partners .LegalMenuLeft-item:hover {
  color: #e6102d;
  transition: color 0.3s ease;
  cursor: pointer;
}
.LegalMenuLeft--legal .LegalMenuLeft-item.isActive,
.LegalMenuLeft--partners .LegalMenuLeft-item.isActive {
  color: #e6102d;
}
