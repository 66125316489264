.Footer {
  position: relative;
  background-color: #0f0c1a;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.Footer-inner {
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 28px;
  z-index: 1;
}
.Footer a {
  text-decoration: none;
}
.Footer-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.Footer-background-logo {
  right: 0;
  position: absolute;
  height: 100%;
  min-height: 100%;
}
.Footer-background-logo svg {
  height: 100%;
  min-height: 100%;
}
.Footer-logo {
  padding-top: 6px;
  padding-left: 25px;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 3 + 2 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Footer-logo {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 3 + 2 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
.Footer-logo svg {
  height: 33px;
  width: 161px;
}
@media (max-width: 1199px) {
  .Footer-logo svg {
    width: 144px;
  }
}
.Footer-logoLink {
  margin-left: 3px;
  transition: opacity 0.3s ease;
}
.Footer-logoLink:hover,
.Footer-logoLink:focus {
  opacity: .7;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.Footer-header {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #f7f8fc;
  margin-top: 1px;
  margin-bottom: 12px;
}
.Footer-link {
  font-size: 14px;
  line-height: 28px;
  color: #78728c;
  display: block;
  transition: color 0.7s ease;
}
.Footer-link--ru {
  display: none;
}
body.lang--ru .Footer-link--ru {
  display: block;
}
body.lang--ru .Footer-link--not-ru {
  display: none;
}
.Footer-link:hover,
.Footer-link:focus {
  color: #ebebf6;
  transition: color 0.3s ease;
}
.Footer-company {
  padding-top: 7px;
  padding-bottom: 30px;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Footer-company {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Footer-brandy {
  padding-top: 7px;
  padding-bottom: 30px;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Footer-brandy {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Footer-media {
  padding-top: 7px;
  padding-bottom: 30px;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  visibility: hidden;
  pointer-events: none;
}
@media (max-width: 1199px) {
  .Footer-media {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
body.lang--ru .Footer-media {
  pointer-events: initial;
  visibility: inherit;
}
.Footer-social {
  display: flex;
  left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  margin-top: 20px;
}
@media (max-width: 1199px) {
  .Footer-social {
    left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.Footer-socialLink {
  height: 24px;
  width: 24px;
  padding: 4px;
  transition: opacity 0.3s ease;
  z-index: 1;
  margin-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Footer-socialLink {
    margin-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Footer-socialLink {
    margin-right: 32px;
  }
}
@media (max-width: 1199px) {
  .Footer-socialLink {
    margin-right: 24px;
  }
}
.Footer-socialLink:hover,
.Footer-socialLink:focus {
  opacity: .7;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.Footer-socialLink svg {
  height: 100%;
  width: 100%;
  fill: #e4012b;
}
.Footer-languagesCol {
  padding-top: 7px;
  padding-bottom: 60px;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Footer-languagesCol {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Footer-languagesCol {
    padding-left: 2px;
  }
}
@media (max-width: 1199px) {
  .Footer-languagesCol {
    position: absolute;
    justify-content: flex-end;
    width: auto;
    top: 28px;
    right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Footer-languagesCol {
    right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
.Footer-languages {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  min-height: 18px;
}
.Footer-languageLink {
  height: 16px;
  width: 16px;
  margin-right: 10px;
  overflow: hidden;
  transition: opacity 0.3s ease;
  z-index: 1;
  border-radius: 50%;
}
.Footer-languageLink:not(.isActive) {
  opacity: .3;
  transition: opacity 0.3s ease;
}
.Footer-languageLink:last-of-type {
  margin-right: 0;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Footer-languageLink {
    margin-right: 8px;
  }
}
@media (max-width: 1199px) {
  .Footer-languageLink {
    margin-right: 8px;
  }
}
.Footer-languageLink:hover,
.Footer-languageLink:focus {
  opacity: .7;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.Footer-languageLink svg {
  height: 16px;
  width: 100%;
}
.Footer-plusEighteen {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
}
@media (max-width: 1199px) {
  .Footer-plusEighteen {
    right: 48px;
  }
}
.Footer-plusEighteen svg {
  height: 24px;
  width: 24px;
}
.Footer-subscribe {
  text-align: left;
  z-index: 1;
  background: #1B1825;
}
.Footer-subscribe-inner {
  margin: 0 auto;
  width: 520px;
}
.Footer-copy {
  position: absolute;
  left: 0;
  bottom: 30px;
  font-size: 14px;
  line-height: 28px;
  color: #78728c;
  padding-left: 28px;
}
.Footer-copy a {
  color: inherit;
  transition: color 0.3s ease;
}
.Footer-copy a:hover,
.Footer-copy a:focus {
  color: #ebebf6;
}
