.StyleTag {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 40px;
  white-space: nowrap;
  color: #94949d;
  position: relative;
  height: 40px;
  padding: 0 18px;
  background: #f7f8fc;
  border: none;
  transition: color 0.3s ease, width 0.3s ease, background 0.3s ease;
  cursor: pointer;
  border-radius: 2px;
}
.StyleTag:hover,
.StyleTag:focus {
  color: #616166;
}
.StyleTag.isActive {
  color: #fff;
  background: #e6102d;
}
.StyleTag.isActive .StyleTag-close {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s linear 0s;
}
.StyleTag.isActive .StyleTag-title {
  transform: translateX(-7px);
}
.StyleTag.isHidden {
  display: none;
}
.StyleTag-title {
  display: inline-block;
  transition: transform 0.3s ease;
}
.StyleTag-close {
  visibility: hidden;
  position: absolute;
  height: 8px;
  width: 9px;
  top: 0;
  bottom: 0;
  right: 9px;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}
.StyleTag-close svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.StyleTag--dark {
  color: #fff;
  background: #443f53;
}
.StyleTag--dark:hover,
.StyleTag--dark:focus {
  color: #fff;
  background: #e6102d;
}
