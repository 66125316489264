.About {
  display: flex;
  height: 100%;
  padding-bottom: 20px;
  background: #f7f8fc;
}
.About-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.About-backgroundImage {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .3;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(rgba(0, 0, 0, 0)));
          mask-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(rgba(0, 0, 0, 0)));
}
.About-left {
  flex: auto;
  padding-top: 60px;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-left {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .About-left {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.About-right {
  flex: auto;
  background: transparent;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 10 + 9 * 48px + 0.5 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-right {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 10 + 9 * 32px + 0.5 * 32px + 1 * 48px) + 0px);
  }
}
.About-header {
  color: #e6102d;
  margin-bottom: 174px;
  padding-top: 200px;
  padding-left: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
  margin-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-header {
    padding-left: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .About-header {
    margin-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.About-subtitle {
  display: flex;
  margin-bottom: 24px;
}
.About-subtitleStroke {
  width: 100px;
  margin-right: 24px;
  border-bottom: 1px solid #e6102d;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .About-subtitleStroke {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 24px);
  }
}
.About-subtitleText {
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  line-height: 8px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #e6102d;
}
.About-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #0f0c1a;
  margin-bottom: 18px;
  padding-right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-description {
    padding-right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.About-text {
  font-size: 20px;
  line-height: 32px;
  color: #0f0c1a;
  padding-right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-text {
    padding-right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (min-width: 1440px), (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  .About-slider {
    flex-shrink: 0;
    margin-bottom: 80px;
    width: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 10 + 9 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
    margin-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 1 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (min-width: 1440px) and (max-width: 1199px), (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px), (max-width: 1199px) and (max-width: 1199px) {
  .About-slider {
    width: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 10 + 9 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (min-width: 1440px) and (max-width: 1199px), (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px), (max-width: 1199px) and (max-width: 1199px) {
  .About-slider {
    margin-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.About-production {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 44px;
  margin-bottom: 13px;
  width: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 9 + 8 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-left: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-production {
    width: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 9 + 8 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .About-production {
    margin-left: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
.About-productionDescription {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 78px;
  width: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 9 + 8 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 2 + 1 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-left: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-productionDescription {
    width: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 9 + 8 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .About-productionDescription {
    padding-right: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 2 + 1 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .About-productionDescription {
    margin-left: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
.About-logos {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 189px;
  width: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 10 + 9 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 1 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-logos {
    width: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 10 + 9 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .About-logos {
    margin-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.About-logosItem {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.About-logosItem:after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: 0;
  bottom: 0;
  border: 1px solid #edeef2;
}
.About-logosItem:before {
  content: '';
  float: left;
  padding-top: 94%;
}
.About-logo {
  width: 47%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.About-brands {
  flex-shrink: 0;
  width: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 9 + 8 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-left: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-brands {
    width: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 9 + 8 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .About-brands {
    margin-left: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
.About-brands:last-of-type {
  margin-bottom: 0;
}
.About-brandsSection {
  display: flex;
  margin-bottom: 118px;
}
.About-brandsTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  position: relative;
  flex-shrink: 0;
  align-self: flex-start;
  padding-top: 1px;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-brandsTitle {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .About-brandsTitle {
    padding-right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.About-brandsTitle:before {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 17px;
  border-bottom: 1px solid red;
  width: calc(((100% - (3 - 1) * 48px - 1 * 48px - 0 * 48px) / 3 * 0.33333333 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-brandsTitle:before {
    width: calc(((100% - (3 - 1) * 32px - 1 * 32px - 0 * 2 * 48px) / 3 * 0.33333333 + -0.66666667 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.About-brandsList {
  flex-shrink: 0;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 6 + 5 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-brandsList {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 6 + 5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.About-brandsArrow {
  transition: color 0.3s ease;
}
.About-brandsArrow .About-brandsItem {
  padding-top: 22px !important;
}
.About-brandsArrow:first-child .About-brandsItem {
  padding-top: 8px !important;
}
.About-brandsArrow:hover {
  color: #e6102d;
  cursor: pointer;
}
.About-brandsArrow:hover svg {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateX(0);
}
.About-brandsItem {
  position: relative;
  padding-top: 22px;
  padding-bottom: 3px;
  border-bottom: 1px solid #edeef2;
}
.About-brandsItem:first-child {
  padding-top: 8px;
}
.About-brandsItem.isActive {
  color: #e6102d;
}
.About-brandsItem--noSubtitle {
  padding-top: 26px;
  padding-bottom: 13px;
}
.About-brandsItem svg {
  visibility: hidden;
  position: absolute;
  height: 100%;
  width: 16px;
  top: 0;
  right: 0;
  opacity: 0;
  stroke: #e6102d;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease 0.3s;
  transform: translateX(-10px);
  right: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .About-brandsItem svg {
    right: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.About-brandsHeader {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.About-brandsDescription {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
}
