.LocationTooltip {
  font-family: 'Graphik LCG', Arial, sans-serif;
  position: relative;
}
.LocationTooltip-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 20px;
  line-height: 25px;
  color: #1c1630;
}
.LocationTooltip-type {
  font-size: 12px;
  line-height: 20px;
  color: #2e2e33;
  position: relative;
  margin-top: -1px;
  padding-bottom: 14px;
}
.LocationTooltip-type:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -27px;
  right: -27px;
  opacity: .23;
  border-bottom: 1px solid #979797;
}
.LocationTooltip-address {
  font-size: 12px;
  color: #1c1630;
  position: relative;
  margin-top: 19px;
  padding-left: 20px;
}
.LocationTooltip-addressIcon {
  position: absolute;
  height: 14px;
  width: 10px;
  top: 1px;
  left: -6px;
  fill: #e6102d;
}
.LocationTooltip-addressIcon svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}
.LocationTooltip-telephone {
  font-size: 12px;
  color: #1c1630;
  position: relative;
  margin-top: 13px;
  padding-left: 20px;
}
.LocationTooltip-telephoneIcon {
  display: inline-block;
  position: relative;
  position: absolute;
  height: 13px;
  width: 13px;
  top: 1px;
  left: -6px;
  fill: #e6102d;
}
.LocationTooltip-telephoneIcon svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}
.LocationTooltip-website {
  font-size: 12px;
  color: #e6102d;
  display: block;
  position: relative;
  margin-top: 12px;
  padding-left: 20px;
}
.LocationTooltip-websiteIcon {
  display: inline-block;
  position: relative;
  position: absolute;
  height: 14px;
  width: 14px;
  top: 2px;
  left: -8px;
  fill: #e6102d;
}
.LocationTooltip-websiteIcon svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}
.LocationTooltip-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
