.Responsible-logo {
  display: inline-block;
  position: absolute;
  height: 36px;
  width: 24px;
  top: 15px;
  left: 32px;
  fill: #e6102d;
}
.Responsible-logo svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.Responsible-content {
  position: relative;
  padding-top: 192px;
  padding-bottom: 127px;
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 8 + 7 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  box-sizing: content-box;
}
@media (max-width: 1199px) {
  .Responsible-content {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Responsible-content {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Responsible-content {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 8 + 7 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Responsible-content:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  border-left: 1px solid #ccd6dd;
}
.Responsible-header {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #1c1630;
}
.Responsible-text {
  font-size: 21px;
  line-height: 32px;
  color: #1c1630;
  margin-top: 66px;
}
.Responsible-text p + p {
  margin-top: 32px;
}
.Responsible-link {
  margin-top: 65px;
}
.ResponsiblePage .Content {
  background: #fff;
}
.ResponsiblePage .Content-topNav {
  display: none;
}
.ResponsiblePage .Content-footer,
.ResponsiblePage .Content-push {
  display: none;
}
