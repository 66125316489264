.Notification {
  background: #000;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: calc(28 / 18);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  padding: 30px 60px;
  position: fixed;
  bottom: 20px;
  left: 24px;
  right: 24px;
  transform: translate(0, 150%);
  transition: opacity 0.7s ease, transform 0.7s ease;
  z-index: 25;
}
.Notification.show {
  transform: translate(0, 0);
  opacity: 1;
}
@media (max-width: 1199px) {
  .Notification {
    font-size: 16px;
    flex-direction: column;
    padding: 20px 15px;
    left: 15px;
    right: 15px;
  }
}
.Notification-content {
  margin-right: 60px;
}
@media (max-width: 1199px) {
  .Notification-content {
    margin-bottom: 36px;
    margin-right: 0;
  }
}
.Notification-content a {
  color: #e6102d;
  transition: color 0.3s ease;
}
.Notification-content a:hover {
  color: #cc001b;
}
@media (max-width: 1199px) {
  .Notification-controls {
    display: block;
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .Notification-controlBtn {
    display: block;
    width: 100%;
  }
}
