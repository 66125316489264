.PostList {
  padding-top: 65px;
  background: #fff;
}
.PostList-sectionHeader {
  display: flex;
  min-height: 72px;
  padding: 13px 0;
  border-bottom: 1px solid #ebebf6;
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-sectionHeader {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .PostList-sectionHeader {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.PostList-header {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 45px;
  color: #1c1630;
  align-self: center;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-header {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.PostList-sectionList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  flex-wrap: wrap;
}
.PostList-sectionItem {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: .85px;
  text-transform: uppercase;
  color: #616166;
  transition: color 0.3s ease;
}
.PostList-sectionItem:not(:last-child) {
  margin-right: 37px;
}
.PostList-sectionItem:hover,
.PostList-sectionItem:focus {
  color: #1c1630;
}
.PostList-sectionItem.isActive {
  color: #e6102d;
  pointer-events: none;
}
.PostList-socialList {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  min-width: 102px;
}
@media (max-width: 1199px) {
  .PostList-socialList {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.PostList-socialItem {
  display: block;
  position: relative;
  height: 16px;
  width: 16px;
  fill: #e6102d;
  transition: fill 0.3s ease;
}
.PostList-socialItem svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}
.PostList-socialItem:not(:last-child) {
  margin-right: 12px;
}
.PostList-socialItem:hover,
.PostList-socialItem:focus {
  fill: #cc001b;
}
.PostList-socialItem--twitter {
  width: 19px;
  margin-top: -2px;
  margin-right: 11px !important;
}
.PostList-socialItem--facebook {
  margin-top: -1px;
}
.PostList-socialItem--telegram {
  margin-top: -1px;
  margin-right: 14px !important;
}
.PostList-tags {
  position: relative;
  margin-bottom: 19px;
}
.PostList-description {
  display: none;
  font-size: 20px;
  line-height: 32px;
  color: #0f0c1a;
  margin-top: 20px;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 10 + 9 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-description {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 10 + 9 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .PostList-description {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .PostList-description {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.PostList-description.isActive {
  display: block;
}
.PostList-main {
  display: flex;
  padding-bottom: 44px;
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-main {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .PostList-main {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.PostList-favorite {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 642px;
  padding-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 8 + 7 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-favorite {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 8 + 7 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .PostList-favorite {
    margin-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.PostList-favoriteMeta {
  display: flex;
  position: relative;
  align-items: flex-end;
  margin-bottom: 20px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .PostList-favoriteMeta {
    margin-bottom: 15px;
  }
}
.PostList-favoriteBadge {
  margin-right: 9px;
}
.PostList-favoriteDate {
  font-size: 11px;
  line-height: 11px;
  letter-spacing: .85px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 5px;
  margin-right: 14px;
}
.PostList-favoriteExternalLink {
  font-size: 11px;
  line-height: 11px;
  letter-spacing: .85px;
  text-transform: uppercase;
  color: #e6102d;
  position: relative;
  margin-bottom: 5px;
}
.PostList-favoriteExternalLink:after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  border-bottom: 4px solid rgba(230, 16, 45, 0.3);
  transition: opacity 0.3s ease;
}
.PostList-favoriteExternalLink:hover:after,
.PostList-favoriteExternalLink:focus:after {
  opacity: 0;
}
.PostList-favoriteContent {
  display: block;
}
.PostList-favoriteContent:hover .PostList-favoriteTitle,
.PostList-favoriteContent:focus .PostList-favoriteTitle {
  border-color: transparent !important;
}
.PostList-favoriteContent:hover .PostList-favoriteImg img,
.PostList-favoriteContent:focus .PostList-favoriteImg img {
  transform: scale(1.05);
}
.PostList-favoriteContent:hover .PostList-favoriteImg:before,
.PostList-favoriteContent:focus .PostList-favoriteImg:before {
  opacity: 1;
}
.PostList-favoriteImg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(28, 22, 48, 0.73);
  overflow: hidden;
}
.PostList-favoriteImg img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
  transition: transform 0.3s ease-in;
}
.PostList-favoriteImg--align-t img {
  -o-object-position: center top;
     object-position: center top;
}
.PostList-favoriteImg--align-b img {
  -o-object-position: center bottom;
     object-position: center bottom;
}
.PostList-favoriteImg:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(28, 22, 48, 0.8) 100%);
  z-index: 1;
}
.PostList-favoriteImg:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(28, 22, 48, 0.2);
  transition: opacity 0.3s ease;
  z-index: 2;
}
.PostList-favoriteTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #fff;
  display: inline;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: border-color 0.3s ease;
  z-index: 3;
}
@media (max-width: 1199px) {
  .PostList-favoriteTitle {
    font-size: 40px;
    line-height: 45px;
  }
}
.PostList-favoriteSubtitle {
  font-size: 21px;
  line-height: 32px;
  color: #fff;
  position: relative;
  margin-top: 22px;
  opacity: .8;
  z-index: 3;
}
@media (max-width: 1199px) {
  .PostList-favoriteSubtitle {
    font-size: 15px;
    line-height: 23px;
  }
}
.PostList-news {
  display: flex;
  flex-direction: column;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 4 + 3 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-news {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 4 + 3 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.PostList-newsHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 45px;
  color: #1c1630;
  margin-top: 2px;
}
.PostList-newsLink {
  display: flex;
  height: 135px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #ebebf6;
}
.PostList-newsLink:hover .PostList-newsTitle,
.PostList-newsLink:focus .PostList-newsTitle {
  color: #e6102d;
}
.PostList-newsLink:hover .PostList-newsImage img,
.PostList-newsLink:focus .PostList-newsImage img {
  transform: scale(1.1);
}
.PostList-newsNumber {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 76px;
  font-weight: 500;
  line-height: 76px;
  text-align: center;
  color: #ccd6dd;
  flex-shrink: 0;
  margin-top: 1px;
  width: calc(((100% - (4 - 1) * 48px - 0 * 48px - 0 * 48px) / 4 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-newsNumber {
    width: calc(((100% - (4 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.PostList-newsContent {
  display: flex;
  width: calc(((100% - (4 - 1) * 48px - 0 * 48px - 0 * 48px) / 4 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-newsContent {
    width: calc(((100% - (4 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.PostList-newsText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}
.PostList-newsTitle {
  font-size: 14px;
  line-height: 18px;
  color: #1c1630;
  max-height: 54px;
  overflow: hidden;
  transition: color 0.3s ease;
}
.PostList-newsDate {
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #919199;
}
.PostList-newsImage {
  flex-shrink: 0;
  height: 110px;
  width: 88px;
  margin-top: 1px;
  margin-left: 32px;
}
.PostList-newsImage img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
  transition: transform 0.3s ease-in;
}
.PostList-newsImage--align-t img {
  -o-object-position: center top;
     object-position: center top;
}
.PostList-newsImage--align-b img {
  -o-object-position: center bottom;
     object-position: center bottom;
}
.PostList-newsButton {
  margin-top: auto;
}
.PostList-grid {
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-grid {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .PostList-grid {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.PostListPage .Content {
  background: #fff;
}
