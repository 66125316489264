.Brands-head {
  position: relative;
  height: 744px;
}
.Brands-head .Video-blackout {
  background: rgba(28, 22, 48, 0.2);
}
.Brands-head:before {
  content: '';
  position: absolute;
  height: 141px;
  top: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom, hsla(0, 0%, 0%, 0.6), hsla(0, 0%, 0%, 0.58819) 9.99%, hsla(0, 0%, 0%, 0.55556) 19.07%, hsla(0, 0%, 0%, 0.50625) 27.44%, hsla(0, 0%, 0%, 0.44444) 35.26%, hsla(0, 0%, 0%, 0.37431) 42.72%, hsla(0, 0%, 0%, 0.3) 50%, hsla(0, 0%, 0%, 0.22569) 57.28%, hsla(0, 0%, 0%, 0.15556) 64.74%, hsla(0, 0%, 0%, 0.09375) 72.56%, hsla(0, 0%, 0%, 0.04444) 80.93%, hsla(0, 0%, 0%, 0.01181) 90.01%, hsla(0, 0%, 0%, 0));
  z-index: 1;
}
.Brands-headNav {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  right: 0;
}
.Brands-headContent {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.Brands-headMedia {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.Brands-img {
  height: 100%;
  width: 100%;
}
.Brands-img img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Brands-img:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(28, 22, 48, 0.2);
}
.Brands-header {
  position: absolute;
  top: 238px;
  left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 9 + 8 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brands-header {
    left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Brands-header {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 9 + 8 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Brands-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #e6102d;
}
.Brands-title:before {
  content: '';
  display: inline-block;
  margin-right: 22px;
  border-bottom: 1px solid #e6102d;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 14px);
}
@media (max-width: 1199px) {
  .Brands-title:before {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 14px);
  }
}
.Brands-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #fff;
  margin-top: 46px;
}
