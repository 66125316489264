.CareerResumeForm-fieldRowResume {
  margin-top: 30px;
}
.CareerResumeForm .CommonNewForm-fieldGroup {
  flex: 1 1;
  width: calc(370 / 1152 * 100%);
}
@media (max-width: 1199px) {
  .CareerResumeForm .CommonNewForm-fieldGroup {
    max-width: 100%;
    width: 100%;
  }
}
.CareerResumeForm .CommonNewForm-fieldGroup.CareerResumeForm-fieldGroupConfidential {
  max-width: 100%;
  width: 100%;
}
.CareerResumeForm-fieldGroupBirthyear {
  max-width: calc(370 / 1152 * 100%);
}
@media (max-width: 1199px) {
  .CareerResumeForm-fieldGroupBirthyear {
    max-width: 100%;
    width: 100%;
  }
}
.CareerResumeForm-fieldGroupCitizenship {
  max-width: calc(760 / 1152 * 100%);
}
@media (max-width: 1199px) {
  .CareerResumeForm-fieldGroupCitizenship {
    max-width: 100%;
    width: 100%;
  }
}
.CareerResumeForm-fieldGroupConfidential {
  max-width: 100%;
  width: 100%;
}
.CareerResumeForm-successMessage {
  color: #1c1630;
  font-size: 20px;
  line-height: calc(32 / 20);
  margin: 90px 0 50px;
}
@media (max-width: 1199px) {
  .CareerResumeForm-successMessage {
    font-size: 18px;
    line-height: calc(22 / 18);
    margin: 56px 0 32px;
  }
}
