.Index-head {
  display: flex;
}
.Index-slider {
  flex-shrink: 0;
  width: 100%;
}
@media (min-width: 1440px) {
  body.lang--ru .Index-slider {
    width: calc(100% - 336px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  body.lang--ru .Index-slider {
    width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 9 + 8 * 48px + 0.5 * 48px + 1 * 48px / 2) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px), (max-width: 1199px) and (max-width: 1199px) {
  body.lang--ru .Index-slider {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 9 + 8 * 32px + 0.5 * 32px + 1 * 48px) + 0px);
  }
}
.Index-primaryPosts {
  flex: 1 1;
}
@media (min-width: 1440px) {
  .Index-primaryPosts {
    width: 336px;
  }
}
.Index-primaryPost {
  position: relative;
  height: 432px;
  width: 100%;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Index-primaryPost {
    height: 388px;
  }
}
@media (max-width: 1199px) {
  .Index-primaryPost {
    height: 328px;
  }
}
.Index-secondaryPosts {
  padding-top: 11px;
  background: #fff;
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 10 + 9 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Index-secondaryPosts {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Index-secondaryPosts {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Index-secondaryPosts {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Index-secondaryPosts {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 10 + 9 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Index-secondaryPosts {
    margin-top: -80px;
    padding-top: 59px;
  }
}
@media (max-width: 1199px) {
  .Index-secondaryPosts {
    margin-top: -43px;
    margin-left: 48px;
    padding-top: 23px;
    padding-right: 0;
    padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
    padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
    width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 12 + 11 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Index-secondaryPosts {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Index-secondaryPosts {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Index-secondaryPosts {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 12 + 11 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Index-secondaryHeader {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #1c1630;
}
.Index-postContainer {
  display: flex;
}
.Index-postItem {
  position: relative;
  padding-top: 19px;
  padding-bottom: 19px;
  border-bottom: 1px solid #edeef2;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 4 + 3 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Index-postItem {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 4 + 3 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Index-postItem:not(:last-child) {
  margin-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Index-postItem:not(:last-child) {
    margin-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Index-postItem {
    padding-top: 25px;
    padding-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px) {
  .Index-postItem {
    padding-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Index-postItem {
    padding-top: 24px;
    width: calc(((100% - (10 - 1) * 48px - 2 * 48px - 0 * 48px) / 10 * 3 + 2 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
  }
  .Index-postItem:not(:last-child) {
    margin-right: calc(((100% - (10 - 1) * 48px - 2 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Index-postItem {
    width: calc(((100% - (10 - 1) * 32px - 2 * 32px - 0 * 2 * 48px) / 10 * 3 + 2 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Index-postItem:not(:last-child) {
    margin-right: calc(((100% - (10 - 1) * 32px - 2 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Index-postItem:hover .Index-postTitle {
  color: #e6102d;
}
.Index-postItem:hover .Index-postArrow {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.Index-postTitle {
  font-size: 18px;
  line-height: 28px;
  color: #616166;
  transition: color 0.3s ease;
}
@media (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  .Index-postTitle {
    line-height: 24px;
  }
}
.Index-postMeta {
  display: flex;
  margin-top: 24px;
}
@media (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  .Index-postMeta {
    margin-top: 26px;
  }
}
.Index-postDate {
  font-size: 11px;
  line-height: 18px;
  letter-spacing: .85px;
  text-transform: uppercase;
  color: #919199;
  height: 16px;
  vertical-align: top;
  margin-top: 4px;
  margin-right: 15px;
}
.Index-postArrow {
  position: absolute;
  height: 8px;
  width: 17px;
  bottom: 20px;
  right: 0;
  stroke: #e6102d;
  opacity: 0;
  transform: translateX(-10px);
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease 0.3s;
}
.Index-postArrow svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.Index-about {
  display: flex;
  padding-top: 47px;
  padding-bottom: 96px;
  background: #fff;
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 10 + 9 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Index-about {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Index-about {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Index-about {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Index-about {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 10 + 9 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  .Index-about {
    width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 12 + 11 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
    margin-left: 24px;
    padding-top: 71px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px), (max-width: 1199px) and (max-width: 1199px) {
  .Index-about {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 12 + 11 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Index-about {
    margin-left: 48px;
    padding-top: 47px;
    padding-bottom: 120px;
  }
}
.Index-aboutCard {
  display: block;
  position: relative;
  height: 384px;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 5 + 4 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Index-aboutCard {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 5 + 4 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Index-aboutCard:not(:last-child) {
  margin-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Index-aboutCard:not(:last-child) {
    margin-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  .Index-aboutCard {
    width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 6 + 5 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px), (max-width: 1199px) and (max-width: 1199px) {
  .Index-aboutCard {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 6 + 5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Index-aboutCard {
    height: 312px;
  }
}
.Index-aboutCard:hover .Index-aboutImg img {
  transform: scale(1.05);
}
.Index-aboutCard:hover .Index-aboutButton svg {
  stroke: #e6102d;
}
.Index-aboutImg {
  height: 100%;
  overflow: hidden;
}
.Index-aboutImg img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
  transition: transform 0.3s ease-in;
}
.Index-aboutCaption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
  background: #fff;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 5 * 4 + 3 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Index-aboutCaption {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 5 * 4 + 3 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Index-aboutCaption {
    padding-top: 23px;
    padding-left: 19px;
    padding-right: 19px;
  }
}
.Index-aboutCategory {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-bottom: 7px;
}
@media (max-width: 1199px) {
  .Index-aboutCategory {
    margin-bottom: 4px;
  }
}
.Index-aboutTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-bottom: -9px;
}
@media (max-width: 1199px) {
  .Index-aboutTitle {
    font-size: 21px;
    line-height: 24px;
    margin-bottom: -5px;
  }
}
.Index-aboutButton {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 16px;
  right: 16px;
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 50%;
}
.Index-aboutButton svg {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  stroke: #979797;
  transition: stroke 0.3s ease-in;
  pointer-events: none;
}
.Index:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fafafc;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Index:before {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px), (min-width: 1200px) and (max-width: 1439px) {
  .Index:before {
    width: 24px;
  }
}
.IndexPage .Content {
  background: #fff;
}
