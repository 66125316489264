.BrandsList {
  display: flex;
}
.BrandsList-grid {
  padding-top: 1px;
  border-left: 1px solid #edeef2;
}
.BrandsList-categories {
  flex-shrink: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 8px;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  background: #fafafc;
}
@media (max-width: 1199px) {
  .BrandsList-categories {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.BrandsList-category {
  text-align: center;
  color: #66666c;
  display: block;
  transition: color 0.3s ease;
  cursor: pointer;
}
.BrandsList-category--ciders {
  margin-top: 46px;
}
.BrandsList-category--beverages {
  margin-top: 44px;
}
.BrandsList-category.isActive,
.BrandsList-category:hover,
.BrandsList-category:focus {
  color: #e6102d;
}
.BrandsList-categoryIcon {
  display: block;
  position: relative;
  margin: 0 auto;
  fill: currentColor;
  stroke: currentColor;
  transition: stroke 0.3s ease, fill 0.3s ease;
}
.BrandsList-categoryIcon svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.BrandsList-category--beer .BrandsList-categoryIcon {
  height: 33px;
  width: 20px;
}
.BrandsList-category--ciders .BrandsList-categoryIcon {
  height: 52px;
  width: 18px;
}
.BrandsList-category--beverages .BrandsList-categoryIcon {
  height: 44px;
  width: 16px;
}
.BrandsList-categoryTitle {
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  margin-top: 8px;
}
.BrandsList-content {
  position: relative;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 11 + 10 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
  background: #fff;
}
@media (max-width: 1199px) {
  .BrandsList-content {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 11 + 10 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
.BrandsList-subCategories {
  display: flex;
  position: absolute;
  align-items: center;
  height: 97px;
  bottom: calc(100% - 1px);
  padding-left: 42px;
  padding-right: 24px;
  background: #fff;
  border: 1px solid #edeef2;
  width: calc(((100% - (11 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 11 * 9 + 8 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .BrandsList-subCategories {
    width: calc(((100% - (11 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 11 * 9 + 8 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
.BrandsList-subCategoriesTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  color: #1c1630;
  flex: 1 1;
}
.BrandsList-subCategoriesList {
  margin-top: 6px;
}
.BrandsList-subCategory {
  font-size: 15px;
  font-weight: 500;
  color: #1c1630;
  background: none;
  border: none;
  transition: color 0.3s ease;
  cursor: pointer;
}
.BrandsList-subCategory:not(:last-child) {
  margin-right: 32px;
}
.BrandsList-subCategory.isActive,
.BrandsList-subCategory:hover,
.BrandsList-subCategory:focus {
  color: #e6102d;
}
.BrandsList-map {
  position: absolute;
  width: 140px;
  bottom: calc(100% + 22px);
  margin-left: 24px;
  left: calc(((100% - (11 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 11 * 9 + 8 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .BrandsList-map {
    left: calc(((100% - (11 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 11 * 9 + 8 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
.BrandsList-map .Button {
  padding-left: 0;
  padding-right: 0;
}
.BrandsList-styles {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding-right: 56px;
  background: #fff;
  border: 1px solid #edeef2;
  border-bottom: none;
  transition: z-index 0.3s step-end;
  z-index: 3;
}
.BrandsList-styles:after {
  content: '';
  position: absolute;
  width: 80px;
  top: 0;
  bottom: 0;
  right: 56px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
  z-index: 1;
  pointer-events: none;
}
.BrandsList-styles:hover {
  z-index: 5;
  transition-timing-function: step-start;
}
.BrandsList-styles.isSticky {
  border-bottom: 1px solid #edeef2;
}
.BrandsList-stylesList {
  white-space: nowrap;
  padding: 15px;
  overflow: hidden;
}
.BrandsList-stylesList .StyleTag + .StyleTag {
  margin-left: 12px;
}
.BrandsList-stylesMore {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}
.BrandsList-stylesMore:hover .BrandsList-stylesTooltip,
.BrandsList-stylesMore:focus .BrandsList-stylesTooltip {
  transition: visibility 0.3s ease;
  visibility: visible;
}
.BrandsList-stylesMore:hover .BrandsList-tooltipOverlay,
.BrandsList-stylesMore:focus .BrandsList-tooltipOverlay {
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  visibility: visible;
}
.BrandsList-stylesMore:hover .BrandsList-tooltipContent,
.BrandsList-stylesMore:focus .BrandsList-tooltipContent {
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  visibility: visible;
}
.BrandsList-stylesButton {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 23px;
  right: 30px;
  background: #1c1630;
  border: none;
  cursor: pointer;
  fill: #fff;
  border-radius: 50%;
  z-index: 4;
}
.BrandsList-stylesButton svg {
  position: absolute;
  height: 4px;
  width: 16px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}
.BrandsList-stylesTooltip {
  position: absolute;
  top: 55px;
  right: 10px;
  transition: visibility 0s ease 0.3s;
  visibility: hidden;
}
.BrandsList-stylesTooltip:before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  top: -6px;
  right: 27px;
  border-style: solid;
  border-width: 0 5.5px 6px 5.5px;
  border-color: transparent transparent #fff transparent;
  z-index: 5;
}
.BrandsList-tooltipOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(15, 12, 26, 0.2);
  transition: opacity 0.3s ease, visibility 0s ease 0.3s;
  z-index: 3;
  visibility: hidden;
  pointer-events: none;
}
.BrandsList-tooltipContent {
  position: relative;
  min-width: 520px;
  width: 90vw;
  padding: 20px;
  padding-bottom: 0;
  opacity: 0;
  background: #fff;
  transition: opacity 0.3s ease, visibility 0s ease 0.3s;
  z-index: 4;
  visibility: hidden;
  border-radius: 2px;
}
.BrandsList-tooltipContent:before {
  content: '';
  position: absolute;
  height: 10px;
  bottom: 100%;
  left: 0;
  right: 0;
}
.BrandsList-tooltipContent .StyleTag {
  margin-bottom: 20px;
  margin-right: 20px;
}
