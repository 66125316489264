.NotFound {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: #0f0c1a;
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 3 + 2 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .NotFound {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 3 + 2 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
.NotFound-content {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  line-height: 48px;
  color: #fff;
  margin-top: -88px;
}
.NotFound-first {
  margin-bottom: 55px;
}
.NotFound-second {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 14px;
  line-height: 40px;
  color: #e6102d;
  position: relative;
}
.NotFound-second:before {
  content: '';
  position: absolute;
  height: calc(100% + 6px);
  width: 100%;
  top: 0;
  left: 0;
  opacity: .2;
  border-bottom: 4px solid #e6102d;
  transition: opacity 0.15s ease;
  vertical-align: bottom;
}
.NotFound-second:hover {
  cursor: pointer;
}
.NotFound-second:hover:before {
  opacity: .5;
}
