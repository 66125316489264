.Post {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.7s ease;
  z-index: 22;
}
.Post--toAnimate {
  position: fixed;
  transform: translate3d(0, 100vh, 0);
}
.Post-close {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
}
.Post-header {
  position: relative;
  height: 100vh;
}
.Post-img {
  height: 100%;
  background: #fff;
}
.Post-img.Post-img--align-t img {
  -o-object-position: center top;
     object-position: center top;
}
.Post-img.Post-img--align-b img {
  -o-object-position: center bottom;
     object-position: center bottom;
}
.Post-img img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center center;
     object-position: center center;
}
.Post-shareButton {
  display: flex;
  position: absolute;
  justify-content: center;
  bottom: -98px;
  z-index: 10;
  right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Post-shareButton {
    right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-shareButton {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Post-body {
  position: relative;
}
.Post-body:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fafafc;
}
.Post-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 72px;
  padding-bottom: 42px;
  background: #fff;
  z-index: 2;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 10 + 9 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Post-content {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 10 + 9 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Post-meta {
  display: flex;
  align-items: center;
  padding-left: 1px;
  margin-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Post-meta {
    margin-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Post-badge {
  font-size: 0;
  margin-right: 9px;
}
.Post-date {
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #616166;
  margin-right: 16px;
}
.Post-externalLink {
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Post-externalLink svg {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 4px;
  fill: #e6102d;
}
.Post-externalLink span {
  position: relative;
}
.Post-externalLink span:after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 1px;
  right: 1px;
  border-bottom: 4px solid rgba(230, 16, 45, 0.3);
  transition: opacity 0.3s ease;
}
.Post-externalLink:hover span:after,
.Post-externalLink:focus span:after {
  opacity: 0;
}
.Post-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #1c1630;
  margin-top: 32px;
  margin-bottom: 26px;
  margin-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 7 + 6 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Post-title {
    margin-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-title {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 7 + 6 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-title {
    font-size: 40px;
    line-height: 45px;
  }
}
.Post-text > *:first-child {
  margin-top: 0;
}
.Post-text p {
  font-size: 21px;
  line-height: 32px;
  color: #1c1630;
  margin-top: 32px;
  margin-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 7 + 6 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Post-text p {
    margin-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-text p {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 7 + 6 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-text p {
    font-size: 18px;
    line-height: 26px;
  }
}
.Post-text p a {
  color: #e6102d;
  text-decoration: underline;
}
html.isNotTouch .Post-text p a:hover {
  text-decoration: none;
}
.Post-text .Slider {
  margin-top: 46px;
}
.Post-text .Slider + * {
  margin-top: 74px;
}
.Post-text figure {
  position: relative;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.Post-text figure .imageContainer {
  position: relative;
}
.Post-text figure img {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Post-text figure figcaption {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-top: 26px;
  padding-bottom: 20px;
  background: #fff;
  padding-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
  left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 6 + 5 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Post-text figure figcaption {
    padding-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-text figure figcaption {
    padding-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-text figure figcaption {
    left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-text figure figcaption {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 6 + 5 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Post-video {
  position: relative;
}
.Post-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Post-podcast {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: 64px;
  transition: opacity 0.3s ease;
}
.Post-podcast img {
  height: 100%;
}
html.isNotTouch .Post-podcast:hover {
  opacity: .7;
}
.Post-pdf {
  display: block;
  background: #edeef2;
  padding: 21px 60px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  margin-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 7 + 6 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Post-pdf {
    margin-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-pdf {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 7 + 6 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Post-pdf:hover svg {
  fill: #e6102d;
}
.Post-pdf table {
  min-height: 76px;
}
.Post-pdf table td {
  vertical-align: middle;
}
.Post-pdf table td:first-child {
  width: 100%;
  padding-right: 60px;
}
.Post-pdf svg {
  display: inline-block;
  vertical-align: middle;
  width: 44px;
  height: 52px;
  transition: fill 0.3s;
}
.Post-pdf svg.SvgIcon--pdf-download-mobile {
  display: none;
}
.Post-bodyFooter {
  margin-top: 130px;
  margin-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  margin-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Post-bodyFooter {
    margin-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-bodyFooter {
    margin-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Post-viewCount {
  margin-right: 26px;
}
.Post-shares {
  display: flex;
  align-items: center;
}
.Post-share {
  display: flex;
  position: relative;
  align-items: stretch;
  height: 48px;
  width: 160px;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.Post-share--tg {
  background: #37ade9;
}
.Post-share--tg svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 20px;
  width: 20px;
}
.Post-share--vk {
  background: #07f;
}
.Post-share--vk svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 20px;
  width: 20px;
}
.Post-share--fb {
  background: #3c5998;
}
.Post-share--fb svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 20px;
  width: 11px;
}
.Post-share--tw {
  background: #34a6de;
}
.Post-share--tw svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 20px;
  width: 22px;
}
.Post-share:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(28, 22, 48, 0.2);
  transition: opacity 0.3s ease;
  border-radius: 5px;
}
.Post-share:hover:after,
.Post-share:focus:after {
  opacity: 1;
}
.Post-shareIcon {
  position: relative;
  flex-shrink: 0;
  width: 48px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  fill: #fff;
}
.Post-shareText {
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  display: inline-block;
  padding-top: 8px;
  padding-left: 13px;
  padding-right: 24px;
}
.Post-footer {
  background: #0f0c1a;
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Post-footer {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Post-footer {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
.Post-footer + img {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
.Post-similar {
  padding-top: 59px;
  padding-bottom: 59px;
}
.Post-similarHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 43px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 35px;
  margin-left: -3px;
}
.Post-similarList {
  display: flex;
  justify-content: flex-start;
  margin: 0 -20px;
}
.Post-tags {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 33px;
}
.Post-tags .StyleTag {
  margin-right: 20px;
}
.Post-subscribe {
  display: flex;
  justify-content: center;
  background: #1B1825;
}
.Post-subscribe + img {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
.Post-subscribeText {
  font-size: 16px;
  letter-spacing: 0;
  color: #fff;
}
