.Locations {
  position: relative;
  height: 100%;
}
.Locations--popup .Locations-fullscreen,
.Locations--brand .Locations-fullscreen {
  display: none;
}
.Locations--popup .Locations-scale,
.Locations--brand .Locations-scale {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}
.Locations-brandInner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.Locations-map {
  height: 100%;
  margin-left: 8px;
}
.Locations-map .mapboxgl-popup {
  padding-bottom: 35px;
}
.Locations-map .mapboxgl-popup-close-button {
  display: none;
}
.Locations-map .mapboxgl-popup-content {
  width: 299px;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 27px;
  padding-right: 27px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.Locations-mapContainer {
  padding-left: 8px;
}
.Locations-fullscreen {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 32px;
  right: 41px;
  background: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
.Locations-fullscreen svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 12px;
  width: 12px;
  stroke: #2e2e33;
  transition: stroke 0.3s ease;
}
.Locations-fullscreen:hover svg,
.Locations-fullscreen:focus svg {
  stroke: #e6102d;
}
.Locations-scale {
  position: absolute;
  top: 84px;
  right: 41px;
}
.Locations-scaleButton {
  display: block;
  position: relative;
  height: 36px;
  width: 40px;
  background: #fff;
  border: none;
  cursor: pointer;
}
.Locations-scaleButton svg {
  fill: #2e2e33;
  transition: fill 0.3s ease;
}
.Locations-scaleButton:hover svg,
.Locations-scaleButton:focus svg {
  fill: #e6102d;
}
.Locations-scaleButton--zoomIn {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  z-index: 2;
}
.Locations-scaleButton--zoomIn:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  border-bottom: 1px solid #979797;
}
.Locations-scaleButton--zoomIn svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 14px;
  width: 14px;
  top: 4px;
}
.Locations-scaleButton--zoomOut {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  z-index: 1;
}
.Locations-scaleButton--zoomOut svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 2px;
  width: 8px;
  bottom: 4px;
}
.Locations-topSubHeader,
.Locations-topListHeader {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Locations-topHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 45px;
  color: #fff;
}
.Locations-topHead {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 2;
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Locations-topHead {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Locations-topHead {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Locations-topContent {
  position: absolute;
  top: 196px;
  left: 0;
  right: 0;
  z-index: 2;
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Locations-topContent {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Locations-topContent {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Locations-topItem {
  text-align: left;
  display: block;
  position: relative;
  height: 71px;
  width: 100%;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}
.Locations-topItem:not(:last-child) {
  border-bottom: 1px solid #383348;
}
.Locations-topItem:not(:first-child) {
  margin-top: 9px;
}
.Locations-topItem:hover .Locations-topTitle,
.Locations-topItem:focus .Locations-topTitle {
  color: #e6102d;
}
.Locations-topItem:hover .Locations-topItemIcon,
.Locations-topItem:focus .Locations-topItemIcon {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  transform: translateX(0);
}
.Locations-topItemInner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 14px;
}
.Locations-topTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  display: block;
  transition: color 0.3s ease;
}
.Locations-topAddress {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: .1px;
  color: #78728c;
  display: block;
  margin-top: 4px;
}
.Locations-topItemIcon {
  visibility: hidden;
  position: absolute;
  height: 10px;
  width: 17px;
  top: 0;
  bottom: 9px;
  right: 3px;
  margin: auto;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s linear 0.3s;
  transform: translateX(-10px);
}
.Locations-topItemIcon svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}
.Locations-marker {
  height: 26px;
  width: 26px;
  background: url('/static/img/front/icons8-marker.png');
  background-size: contain;
  cursor: pointer;
}
