.Button {
  font-family: inherit;
  font-size: 15px;
  font-weight: 500;
  line-height: 50px;
  text-align: center;
  color: #fff;
  display: inline-block;
  position: relative;
  height: 50px;
  padding-left: 45px;
  padding-right: 45px;
  background: #e6102d;
  border: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border-radius: 2px;
}
.Button:hover,
.Button:focus {
  background-color: #cc001b;
}
.Button--grow {
  width: 100%;
}
.Button--small {
  line-height: 44px;
  height: 44px;
}
.Button--small2 {
  line-height: 40px;
  height: 40px;
}
.Button--tiny {
  font-size: 21px;
  font-weight: 500;
  height: 48px;
  padding-left: 25px;
  padding-right: 25px;
}
.Button--transparent {
  background: transparent;
}
.Button--black {
  background: #100c1a;
  border-radius: 0 2px 2px 0;
  color: #fff;
}
.Button--black:hover,
.Button--black:focus {
  background-color: #100c1a;
  opacity: 0.8;
}
.Button-loader {
  display: block !important;
  position: absolute;
  height: 25px;
  width: 25px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0 !important;
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #fff;
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  transition: opacity 0.2s ease-out;
  transform: translateZ(0);
  border-radius: 50%;
}
.Button--submitting .Button-content {
  opacity: 0 !important;
}
.Button--submitting .Button-loader {
  opacity: 1 !important;
  animation: Button-loading 0.8s infinite linear;
}
@keyframes Button-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
