*,
*:after,
*:before {
  box-sizing: border-box;
}
body {
  font-family: 'Graphik LCG', Arial, sans-serif;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: 'kern';
  -ms-font-feature-settings: 'kern' 1;
  font-feature-settings: 'kern';
  min-width: 1024px;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  font-family: inherit;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}
*[data-animation="1"][data-animation-type="fade"][data-animation-state="initial"] {
  opacity: 0;
}
*[data-animation="1"][data-animation-type="fade"][data-animation-state="triggered"] {
  transition: 0.8s opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
*[data-animation="1"][data-animation-type="bubble-up"][data-animation-state="initial"] {
  opacity: 0;
  transform: translateY(16px);
}
*[data-animation="1"][data-animation-type="bubble-up"][data-animation-state="triggered"] {
  transition: 0.8s opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0.8s transform cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
*[data-animation="1"][data-animation-type="bubble-down"][data-animation-state="initial"] {
  opacity: 0;
  transform: translateY(-16px);
}
*[data-animation="1"][data-animation-type="bubble-down"][data-animation-state="triggered"] {
  transition: 0.8s opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s, 0.8s transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s;
}
*[data-animation="1"][data-animation-type="bubble-down-scale"][data-animation-state="initial"] path {
  transform: scale(0);
}
*[data-animation="1"][data-animation-type="bubble-down-scale"][data-animation-state="triggered"] path {
  transition: 0.8s transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s;
  transform-origin: 50% 0;
}
*[data-animation="1"][data-animation-type="fade-scale"][data-animation-state="initial"] {
  opacity: 0;
  transform: scale(0.7);
  transform-origin: 50% 50%;
}
*[data-animation="1"][data-animation-type="fade-scale"][data-animation-state="triggered"] {
  transition: 0.8s opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0.8s transform cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
*[data-animation="1"][data-animation-delay="0"] {
  transition-delay: 0ms !important;
}
*[data-animation="1"][data-animation-delay="50"] {
  transition-delay: 100ms !important;
}
*[data-animation="1"][data-animation-delay="100"] {
  transition-delay: 200ms !important;
}
*[data-animation="1"][data-animation-delay="150"] {
  transition-delay: 300ms !important;
}
*[data-animation="1"][data-animation-delay="200"] {
  transition-delay: 400ms !important;
}
*[data-animation="1"][data-animation-delay="250"] {
  transition-delay: 500ms !important;
}
*[data-animation="1"][data-animation-delay="300"] {
  transition-delay: 600ms !important;
}
*[data-animation="1"][data-animation-delay="350"] {
  transition-delay: 700ms !important;
}
*[data-animation="1"][data-animation-delay="400"] {
  transition-delay: 800ms !important;
}
*[data-animation="1"][data-animation-delay="450"] {
  transition-delay: 900ms !important;
}
*[data-animation="1"][data-animation-delay="500"] {
  transition-delay: 1000ms !important;
}
*[data-animation="1"][data-animation-delay="550"] {
  transition-delay: 1100ms !important;
}
*[data-animation="1"][data-animation-delay="600"] {
  transition-delay: 1200ms !important;
}
*[data-animation="1"][data-animation-delay="650"] {
  transition-delay: 1300ms !important;
}
*[data-animation="1"][data-animation-delay="700"] {
  transition-delay: 1400ms !important;
}
*[data-animation="1"][data-animation-delay="750"] {
  transition-delay: 1500ms !important;
}
*[data-animation="1"][data-animation-delay="800"] {
  transition-delay: 1600ms !important;
}
*[data-animation="1"][data-animation-delay="850"] {
  transition-delay: 1700ms !important;
}
*[data-animation="1"][data-animation-delay="900"] {
  transition-delay: 1800ms !important;
}
*[data-animation="1"][data-animation-delay="950"] {
  transition-delay: 1900ms !important;
}
*[data-animation="1"][data-animation-delay="1000"] {
  transition-delay: 2000ms !important;
}
