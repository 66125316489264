.Confidential {
  background: #fafafc;
  display: flex;
  padding-bottom: 20px;
  height: 100%;
}
.Confidential-main {
  padding: 0 20px;
  margin: 0 auto;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 10 + 9 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Confidential-main {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 10 + 9 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Confidential-main {
    padding: 0 15px;
    width: 100%;
  }
}
.Confidential-header {
  color: #e6102d;
  margin-bottom: 85px;
  padding-top: 200px;
}
@media (max-width: 1199px) {
  .Confidential-header {
    padding-top: 126px;
    margin-bottom: 38px;
  }
}
.Confidential-subtitle {
  display: flex;
  margin-bottom: 48px;
}
@media (max-width: 1199px) {
  .Confidential-subtitle {
    margin-bottom: 16px;
  }
}
.Confidential-subtitleStroke {
  width: 100px;
  margin-right: 24px;
  border-bottom: 1px solid #e6102d;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Confidential-subtitleStroke {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 24px);
  }
}
.Confidential-subtitleText {
  color: #e6102d;
  font-size: 12px;
  font-weight: 500;
  line-height: 8px;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.Confidential-title {
  color: #0f0c1a;
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  margin-bottom: 48px;
}
@media (max-width: 1199px) {
  .Confidential-title {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 38px;
  }
}
.Confidential-content {
  color: #0f0c1a;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 140px;
}
@media (max-width: 1199px) {
  .Confidential-content {
    font-size: 16px;
    line-height: 25px;
  }
}
.Confidential-content h2 {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 44px;
  font-weight: 500;
  line-height: 52px;
  margin: 0 0 24px;
}
@media (max-width: 1199px) {
  .Confidential-content h2 {
    font-size: 25px;
    line-height: 30px;
    margin: 0 0 20px;
  }
}
.Confidential-content p {
  margin-bottom: 32px;
}
@media (max-width: 1199px) {
  .Confidential-content p {
    margin-bottom: 26px;
  }
}
.Confidential-content p + ul {
  margin-top: -32px;
}
@media (max-width: 1199px) {
  .Confidential-content p + ul {
    margin-bottom: -26px;
  }
}
.Confidential-content ol {
  margin-top: 24px;
}
@media (max-width: 1199px) {
  .Confidential-content ol {
    margin-top: 20px;
  }
}
.Confidential-content ol > li {
  margin-bottom: 32px;
}
@media (max-width: 1199px) {
  .Confidential-content ol > li {
    margin-bottom: 26px;
  }
}
.Confidential-content ol ul {
  list-style: disc;
  margin-left: 24px;
}
@media (max-width: 1199px) {
  .Confidential-content ol ul {
    margin-left: 20px;
  }
}
.Confidential-content ol ul li {
  padding-left: 6px;
}
.Confidential-content ol ul + p {
  margin-top: 32px;
}
@media (max-width: 1199px) {
  .Confidential-content ol ul + p {
    margin-top: 26px;
  }
}
.Confidential-content strong {
  font-weight: 600;
}
.Confidential-content a {
  color: #e6102d;
  transition: color 0.3s ease;
}
.Confidential-content a:hover {
  color: #cc001b;
}
.Confidential-section {
  margin-bottom: 75px;
}
@media (max-width: 1199px) {
  .Confidential-section {
    margin-bottom: 38px;
  }
}
