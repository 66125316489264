.Story[data-id='2024-1'] {
  background: #fff;
  color: #1c1630;
  min-height: 540px;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.Story[data-id='2024-1'] .Story-bg {
  background: #558f49;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translate(130%, 0);
  z-index: 1;
  width: 100%;
  will-change: transform;
}
.Story[data-id='2024-1'] .Story-content {
  margin: 0 auto;
  position: relative;
  padding: 50px 0;
  text-align: center;
  z-index: 2;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-1'] .Story-content {
    padding: 30px 0 50px;
  }
}
.Story[data-id='2024-1'] .Story-text {
  font-size: 35px;
  line-height: 42px;
  position: relative;
  height: 84px;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-1'] .Story-text {
    font-size: 16px;
    line-height: 19px;
    padding: 30px;
  }
}
.Story[data-id='2024-1'] .Story-text b {
  font-weight: 600;
}
.Story[data-id='2024-1'] .Story-textContent {
  color: #1b172e;
  position: absolute;
  inset: 0;
}
.Story[data-id='2024-1'] .Story-textContent--duplicate {
  color: #fff;
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
  will-change: clip-path;
}
.Story[data-id='2024-1'] .Story-imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0;
  position: relative;
}
.Story[data-id='2024-1'] .Story-img0,
.Story[data-id='2024-1'] .Story-img1,
.Story[data-id='2024-1'] .Story-img2,
.Story[data-id='2024-1'] .Story-img3 {
  opacity: 1;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  pointer-events: none;
  margin: 0 -50px;
  will-change: opacity, transform;
}
.Story[data-id='2024-1'] .Story-img3 {
  margin: 0 -80px;
}
.Story[data-id='2024-2'] {
  background: #fde7e7;
  color: #1c1630;
  height: 430px;
  position: relative;
  overflow: hidden;
  width: calc(353 / 1060 * 100%);
}
.Story[data-id='2024-2'] .Story-inner {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
}
.Story[data-id='2024-2'] .Story-content {
  padding: 30px;
  margin: 0 0 auto;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-2'] .Story-content {
    padding: 15px;
  }
}
.Story[data-id='2024-2'] .Story-text {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-2'] .Story-text {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2024-2'] .Story-text b {
  font-weight: 500;
}
.Story[data-id='2024-2'] .Story-imgWrapper {
  margin: 40px auto 0;
  position: relative;
}
.Story[data-id='2024-2'] .Story-img {
  display: block;
  opacity: 1;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto auto 0;
  pointer-events: none;
  will-change: opacity, transform;
}
.Story[data-id='2024-3'] {
  background: #db3636;
  color: #fff;
  height: 430px;
  position: relative;
  overflow: hidden;
  width: calc(707 / 1060 * 100%);
}
.Story[data-id='2024-3'] .Story-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.Story[data-id='2024-3'] .Story-content {
  margin: auto 0 0;
  padding: 0 30px 30px;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-3'] .Story-content {
    padding: 0 15px 15px;
  }
}
.Story[data-id='2024-3'] .Story-text {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  max-width: 205px;
  position: relative;
  z-index: 2;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-3'] .Story-text {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2024-3'] .Story-text b {
  font-weight: 500;
}
.Story[data-id='2024-3'] .Story-svg {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translate(0, 0) scale(1.01);
  will-change: transform;
}
.Story[data-id='2024-3'] .Story-svg svg {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  display: block;
  margin: auto;
  height: 100%;
  width: 100%;
}
.Story[data-id='2024-3'] .Story-imgWrapper {
  margin: 0 auto;
  padding: 40px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.Story[data-id='2024-3'] .Story-img {
  display: block;
  opacity: 1;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: opacity, transform;
}
.Story[data-id='2023-1'] {
  background: #fff;
  color: #1c1630;
  min-height: 470px;
  position: relative;
  overflow: hidden;
  width: calc(696 / 1060 * 100%);
}
.Story[data-id='2023-1'] .Story-content {
  padding: 30px 37px 33px;
  text-align: center;
  margin: 0 auto;
}
.Story[data-id='2023-1'] .Story-text {
  font-size: 35px;
  font-weight: 500;
  line-height: 42px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-1'] .Story-text {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2023-1'] .Story-imgWrapper {
  margin: 40px auto 0;
  position: relative;
}
.Story[data-id='2023-1'] .Story-imgOld {
  position: relative;
  z-index: 3;
}
.Story[data-id='2023-1'] .Story-img0 {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto auto 0;
  opacity: 1;
  transform: scaleX(1.06);
  will-change: opacity;
}
.Story[data-id='2023-1'] .Story-imgNew {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 269px;
}
.Story[data-id='2023-1'] .Story-imgNewCenter {
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 0;
  transform: scale(1.088);
  margin-left: -2px;
  z-index: 4;
}
.Story[data-id='2023-1'] .Story-img1,
.Story[data-id='2023-1'] .Story-img2,
.Story[data-id='2023-1'] .Story-img3 {
  opacity: 1;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto auto 0;
  pointer-events: none;
  will-change: opacity, transform;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -51px;
  margin-top: -135px;
}
.Story[data-id='2023-1'] .Story-img1 {
  transform: translate3d(0, 0, 0);
}
.Story[data-id='2023-1'] .Story-img2 {
  margin: 0;
  position: static;
}
.Story[data-id='2023-1'] .Story-img3 {
  transform: translate3d(0, 0, 0);
}
.Story[data-id='2023-2'] {
  background: #1c1630;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 470px;
  position: relative;
  overflow: hidden;
  width: calc(364 / 1060 * 100%);
}
.Story[data-id='2023-2'] .Story-content {
  padding: 30px;
  text-align: left;
  margin: 0 auto;
}
.Story[data-id='2023-2'] .Story-text,
.Story[data-id='2023-2'] .Story-text2 {
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-2'] .Story-text,
  .Story[data-id='2023-2'] .Story-text2 {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2023-2'] .Story-text {
  font-weight: 500;
}
.Story[data-id='2023-2'] .Story-imgWrapper {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -324px, 0) scale(1);
  will-change: transform;
}
.Story[data-id='2023-2'] .Story-img {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto auto 0;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.Story[data-id='2023-3'] {
  background: #e3e3e3;
  color: #1c1630;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 482px;
  position: relative;
  overflow: hidden;
  width: calc(364 / 1060 * 100%);
}
.Story[data-id='2023-3'] .Story-circle {
  border-radius: 100%;
  position: absolute;
  width: calc(335 / 364 * 100%);
  z-index: 1;
}
.Story[data-id='2023-3'] .Story-circle::before {
  display: block;
  content: "";
  padding-top: 100%;
  width: 100%;
}
.Story[data-id='2023-3'] .Story-circle--blue {
  background: #0081f9;
  top: calc(-52 / 364 * 100%);
  left: calc(-76 / 364 * 100%);
  z-index: 1;
}
.Story[data-id='2023-3'] .Story-circle--pink {
  background: #fd4575;
  top: calc(36 / 364 * 100%);
  right: calc(-120 / 364 * 100%);
  z-index: 2;
}
.Story[data-id='2023-3'] .Story-circle--yellow {
  background: #ffdc27;
  bottom: calc(-70 / 364 * 100%);
  left: calc(-38 / 364 * 100%);
  z-index: 3;
}
.Story[data-id='2023-3'] .Story-inner {
  position: relative;
  width: 100%;
  z-index: 4;
}
.Story[data-id='2023-3'] .Story-content {
  padding: 0 30px 30px;
  position: relative;
  text-align: left;
  margin: 0 auto;
  z-index: 5;
}
.Story[data-id='2023-3'] .Story-text,
.Story[data-id='2023-3'] .Story-text2 {
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-3'] .Story-text,
  .Story[data-id='2023-3'] .Story-text2 {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2023-3'] .Story-text {
  font-weight: 500;
  margin-bottom: 10px;
}
.Story[data-id='2023-3'] .Story-imgWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 336px;
  position: relative;
  top: 18%;
  transform: scale(0.9);
  will-change: transform;
}
.Story[data-id='2023-3'] .Story-img {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2023-4'] {
  background: #4b9a4a;
  color: #fff;
  min-height: 482px;
  overflow: hidden;
  position: relative;
  width: calc(696 / 1060 * 100%);
}
.Story[data-id='2023-4'] .Story-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
  text-align: left;
  margin: 0 auto;
  height: 100%;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-4'] .Story-content {
    align-items: flex-start;
  }
}
.Story[data-id='2023-4'] .Story-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-4'] .Story-text {
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
}
.Story[data-id='2023-4'] .Story-list {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: auto auto 0;
  padding: 0;
  position: relative;
  width: 100%;
}
.Story[data-id='2023-4'] .Story-listItem {
  position: relative;
  opacity: 0;
  transition: opacity 0.3s;
  will-change: opacity;
}
.Story[data-id='2023-4'] .Story-listItem.isAnimated {
  opacity: 1;
}
.Story[data-id='2023-4'] .Story-listItem.isAnimated .Story-listItemText {
  transform: translate(0, 0);
  opacity: 1;
}
.Story[data-id='2023-4'] .Story-listItemText {
  font-size: 20px;
  line-height: 24px;
  opacity: 0;
  transform: translate(0, 7px);
  transition: opacity 0.3s 0.2s,
            transform 0.3s 0.2s;
  will-change: opacity, transform;
  margin-left: -74px;
  position: absolute;
  top: -34px;
  left: 50%;
  width: 148px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-4'] .Story-listItemText {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2023-4'] .Story-listItemImg {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto;
}
.Story[data-id='2023-5'] {
  background: #0d3766;
  color: #fff;
  height: 334px;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.Story[data-id='2023-5'] .Story-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 0;
  text-align: left;
  margin: auto;
}
.Story[data-id='2023-5'] .Story-contentCol {
  position: relative;
}
.Story[data-id='2023-5'] .Story-imgWrapper {
  position: absolute;
  will-change: transform;
}
.Story[data-id='2023-5'] .Story-imgWrapper--rzd {
  top: 120px;
  left: -120px;
  transform: scale(1.1);
}
.Story[data-id='2023-5'] .Story-imgWrapper--aeroflot {
  top: 132px;
  left: 52px;
}
.Story[data-id='2023-5'] .Story-img {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto auto 0;
}
.Story[data-id='2023-5'] .Story-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding: 0 30px;
  max-width: 280px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-5'] .Story-text {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2023-5'] .Story-text--right {
  text-align: right;
  margin-left: auto;
}
.Story[data-id='2023-5'] .Story-contentFigure {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 8px 0 0;
}
.Story[data-id='2023-5'] .Story-imgLeft {
  position: relative;
  transform: translate(69px, 0) rotate(20deg);
  z-index: 1;
  will-change: transform;
}
.Story[data-id='2023-5'] .Story-imgRight {
  position: relative;
  transform: translate(-105px, 0) rotate(-20deg);
  will-change: transform;
}
.Story[data-id='2022-1'] {
  background: #e7a742;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 470px;
  position: relative;
  overflow: hidden;
  width: calc(367 / 1033 * 100%);
}
.Story[data-id='2022-1'] .Story-content {
  padding: 30px 37px 33px;
  text-align: center;
  margin: 0 auto;
}
.Story[data-id='2022-1'] .Story-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  max-width: 290px;
}
@media (max-width: 1199px) {
  .Story[data-id='2022-1'] .Story-text {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2022-1'] .Story-imgWrapper {
  display: flex;
  padding-left: 34px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1;
}
.Story[data-id='2022-1'] .Story-img {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto auto 0;
}
.Story[data-id='2022-2'] {
  background: #000;
  color: #fff;
  display: flex;
  min-height: 470px;
  overflow: hidden;
  position: relative;
  width: calc(666 / 1033 * 100%);
  /*
    @{c}-img,
    @{c}-img2 {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: contain; font-family: 'object-fit: contain;';
        object-position: right;
    }
    */
}
.Story[data-id='2022-2'] .Story-content {
  padding: 30px;
}
.Story[data-id='2022-2'] .Story-text {
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  position: relative;
  max-width: 235px;
  z-index: 1;
}
.Story[data-id='2022-2'] .Story-text b {
  font-weight: 600;
}
@media (max-width: 1199px) {
  .Story[data-id='2022-2'] .Story-text {
    font-size: 25px;
    line-height: 30px;
  }
}
.Story[data-id='2022-2'] .Story-imgWrapper {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 1;
  transform: scale(1);
  transition: transform 0.7s ease;
}
.Story[data-id='2022-2'] .Story-imgWrapper::before {
  background: linear-gradient(89.48deg, #000 46.8%, rgba(27, 23, 46, 0) 72.06%);
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 46px;
}
.Story[data-id='2022-2']:hover .Story-imgWrapper {
  transform: scale(1.12);
}
.Story[data-id='2022-3'] {
  background: #fff;
  color: #1c1630;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: calc(24 / 20);
  min-height: 248px;
  overflow: hidden;
  position: relative;
  width: calc(772 / 1033 * 100%);
}
@media (max-width: 1199px) {
  .Story[data-id='2022-3'] {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2022-3'] .Story-content {
  padding: 30px;
}
.Story[data-id='2022-3'] .Story-text {
  font-weight: 500;
  max-width: 245px;
}
.Story[data-id='2022-3'] .Story-text2 {
  margin-top: 92px;
}
.Story[data-id='2022-3'] .Story-imgWrapper,
.Story[data-id='2022-3'] .Story-imgWrapper2,
.Story[data-id='2022-3'] .Story-imgWrapper3 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.Story[data-id='2022-3'] .Story-imgWrapper {
  top: -102px;
  right: calc(230 / 772 * 100%);
}
.Story[data-id='2022-3'] .Story-imgWrapper2 {
  top: auto;
  bottom: -93px;
  right: calc(125 / 772 * 100%);
}
.Story[data-id='2022-3'] .Story-imgWrapper3 {
  top: -83px;
  right: calc(9 / 772 * 100%);
}
.Story[data-id='2022-4'] {
  background: #002d5e url('/static/img/front/history/2022-bavaria-bg.png') no-repeat 50% 50%;
  background-size: cover;
  color: #fff;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: calc(24 / 20);
  min-height: 248px;
  overflow: hidden;
  position: relative;
  width: calc(261 / 1033 * 100%);
}
@media (max-width: 1199px) {
  .Story[data-id='2022-4'] {
    font-size: 25px;
    line-height: 30px;
  }
}
.Story[data-id='2022-4'] .Story-content {
  padding: 20px;
}
.Story[data-id='2022-4'] .Story-text {
  max-width: 200px;
}
.Story[data-id='2022-4'] .Story-text2 {
  font-weight: 500;
}
.Story[data-id='2022-4'] .Story-imgWrapper {
  height: 275px;
  position: absolute;
  bottom: 0;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.Story[data-id='2022-4'] .Story-img {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  height: 275px;
  width: 137px;
}
.Story[data-id='2022-5'] {
  background: #f1e5d4;
  color: #211714;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: calc(24 / 20);
  min-height: 482px;
  overflow: hidden;
  position: relative;
  width: calc(316 / 1033 * 100%);
}
@media (max-width: 1199px) {
  .Story[data-id='2022-5'] {
    font-size: 25px;
    line-height: 30px;
  }
}
.Story[data-id='2022-5'] .Story-inner {
  width: 100%;
}
.Story[data-id='2022-5'] .Story-content {
  padding: 8px 30px 30px;
}
.Story[data-id='2022-5'] .Story-text {
  font-weight: 500;
  letter-spacing: -0.01em;
}
.Story[data-id='2022-5'] .Story-text2 {
  margin-top: 10px;
}
.Story[data-id='2022-5'] .Story-imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.Story[data-id='2022-5'] .Story-img {
  text-align: center;
  margin: auto;
}
.Story[data-id='2022-6'] {
  background: #bb1f1f;
  color: #fff;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: calc(28 / 20);
  min-height: 482px;
  overflow: hidden;
  position: relative;
  width: calc(717 / 1033 * 100%);
}
@media (max-width: 1199px) {
  .Story[data-id='2022-6'] {
    font-size: 25px;
    line-height: 30px;
  }
}
.Story[data-id='2022-6'] .Story-content {
  padding: 30px;
}
.Story[data-id='2022-6'] .Story-text {
  font-weight: 500;
  letter-spacing: -0.01em;
  margin-top: 8px;
}
.Story[data-id='2022-6'] .Story-text2 {
  margin-top: 10px;
}
.Story[data-id='2022-6'] .Story-imgWrapper,
.Story[data-id='2022-6'] .Story-imgWrapper2,
.Story[data-id='2022-6'] .Story-imgWrapper3 {
  position: absolute;
  bottom: 40px;
}
.Story[data-id='2022-6'] .Story-imgWrapper img,
.Story[data-id='2022-6'] .Story-imgWrapper2 img,
.Story[data-id='2022-6'] .Story-imgWrapper3 img {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, 0) scale(0.7);
  transition: opacity 0.3s, transform 0.3s;
}
.Story[data-id='2022-6'] .Story-imgWrapper {
  left: 61px;
  height: 212px;
  width: 162px;
}
.Story[data-id='2022-6'] .Story-imgWrapper2 {
  left: 50%;
  height: 212px;
  width: 162px;
  transform: translate(-121px, 0);
}
.Story[data-id='2022-6'] .Story-imgWrapper3 {
  right: 30px;
  height: 403px;
  width: 308px;
}
.Story[data-id='2022-6'].isAnimated-1 .Story-imgWrapper img:nth-child(1) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-1 .Story-imgWrapper2 img:nth-child(1) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-1 .Story-imgWrapper3 img:nth-child(1) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-2 .Story-imgWrapper img:nth-child(2) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-2 .Story-imgWrapper2 img:nth-child(2) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-2 .Story-imgWrapper3 img:nth-child(2) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-3 .Story-imgWrapper img:nth-child(3) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-3 .Story-imgWrapper2 img:nth-child(3) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-3 .Story-imgWrapper3 img:nth-child(3) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2021-1'] {
  display: flex;
  min-height: 400px;
  width: 100%;
  overflow: hidden;
  background: #fff;
  /*
    @{c}-img,
    @{c}-img2 {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: contain; font-family: 'object-fit: contain;';
        object-position: right;
    }
    */
}
.Story[data-id='2021-1'] .Story-content {
  padding: 38px 37px 22px;
  padding-right: 400px;
}
.Story[data-id='2021-1'] .Story-text {
  color: #1c1630;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
@media (max-width: 1199px) {
  .Story[data-id='2021-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2021-1'] .Story-imgWrapper {
  position: absolute;
  top: 150px;
  right: 60px;
  z-index: 1;
}
.Story[data-id='2021-1'] .Story-imgWrapper2 {
  position: absolute;
  top: -150px;
  right: 221px;
  z-index: 1;
}
.Story[data-id='2021-1'] .Story-img,
.Story[data-id='2021-1'] .Story-img2 {
  max-width: 100%;
}
.Story[data-id='2021-list'] {
  background: #1c1630;
  color: #fff;
  padding: 34px 37px;
  width: 100%;
}
.Story-title {
  color: #e6102d;
  font-size: 24px;
  letter-spacing: 0;
  text-align: center;
  line-height: 32px;
}
.Story-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  margin: 96px auto 23px;
}
.Story-list .Story-listItemImageAward {
  transform: scale(1);
  opacity: 1;
}
.Story-list .Story-listItemImageBottle {
  transform: scale(0.8);
  opacity: 0;
}
.Story-list.isAnimated .Story-listItemImageAward {
  transform: scale(0.8);
  opacity: 0;
}
.Story-list.isAnimated .Story-listItemImageBottle {
  transform: scale(1);
  opacity: 1;
}
.Story-listItem {
  margin: 0 14px;
}
.Story-listItemImage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 37px;
  position: relative;
  height: 204px;
  width: 100%;
}
.Story-listItemImage img {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  pointer-events: none;
  transition: transform 0.3s ease,
                opacity 0.3s ease;
}
.Story-listItem:nth-child(1) img {
  transition-delay: 0s;
}
.Story-listItem:nth-child(2) img {
  transition-delay: 0.3s;
}
.Story-listItem:nth-child(3) img {
  transition-delay: 0.6s;
}
.Story-listItemText {
  font-size: 16px;
  letter-spacing: 0;
  text-align: center;
  line-height: 26px;
}
.Story[data-id='2021-2'] {
  position: relative;
  width: 50%;
  padding-bottom: 60%;
  overflow: hidden;
}
.Story[data-id='2021-2'] .Story-content {
  position: absolute;
  left: 37px;
  top: 40px;
  right: 37px;
  z-index: 3;
}
.Story[data-id='2021-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 1199px) {
  .Story[data-id='2021-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2021-2'] .Story-imgWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.Story[data-id='2021-2'] .Story-img {
  max-width: 100%;
}
@media (min-width: 1680px) {
  .Story[data-id='2021-2'] .Story-img {
    max-width: auto;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -o-object-fit: cover;
       object-fit: cover;
    font-family: 'object-fit: cover;';
    -o-object-position: center;
       object-position: center;
  }
}
.Story[data-id='2021-3'] {
  position: relative;
  width: 50%;
  padding-bottom: 60%;
  overflow: hidden;
}
.Story[data-id='2021-3'] .Story-content {
  position: absolute;
  left: 37px;
  top: 40px;
  right: 37px;
  z-index: 3;
}
.Story[data-id='2021-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 1199px) {
  .Story[data-id='2021-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2021-3'] .Story-imgWrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  width: 100%;
}
.Story[data-id='2021-3'] .Story-img {
  animation: slide infinite 6s linear;
  transform: translate(0, 0);
}
@keyframes slide {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-560px, 0);
  }
}
.Story[data-id='2020-5'] {
  position: relative;
  width: 100%;
  padding-bottom: 60.30701754%;
  overflow: hidden;
}
.Story[data-id='2020-5'].isAnimated .Story-imgWrapper {
  -webkit-filter: blur(0);
          filter: blur(0);
}
.Story[data-id='2020-5'] .Story-content {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 38px 37px 22px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  z-index: 3;
}
.Story[data-id='2020-5'] .Story-imgWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-filter: blur(40px);
          filter: blur(40px);
  transition: -webkit-filter .15s;
  transition: filter .15s;
  transition: filter .15s, -webkit-filter .15s;
  transform: translate3d(0, 0, 0);
  will-change: filter;
}
.Story[data-id='2020-5'] .Story-imgWrapper2 {
  position: absolute;
  height: 97px;
  width: 99px;
  top: 30px;
  left: 30px;
  z-index: 1;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-5'] .Story-imgWrapper2 {
    height: 69px;
    width: 70px;
  }
}
.Story[data-id='2020-5'] .Story-img,
.Story[data-id='2020-5'] .Story-img2 {
  height: 100%;
  width: 100%;
}
.Story[data-id='2020-5'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-5'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2020-4'] {
  display: flex;
  position: relative;
  height: 200px;
  width: 100%;
  background: #fff;
}
.Story[data-id='2020-4'].isAnimated .Story-svgList svg:nth-child(1) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-2 .Story-svgList svg:nth-child(2) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-3 .Story-svgList svg:nth-child(3) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-4 .Story-svgList svg:nth-child(4) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-5 .Story-svgList svg:nth-child(5) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-6 .Story-svgList svg:nth-child(6) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-7 .Story-svgList svg:nth-child(7) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-7 .Story-text {
  opacity: 1;
}
.Story[data-id='2020-4'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  position: absolute;
  width: 325px;
  top: 50%;
  right: 36px;
  opacity: 0;
  transition: opacity 0.15s 0.3s;
  transform: translateY(-50%);
}
@media (max-width: 1199px) {
  .Story[data-id='2020-4'] .Story-text {
    font-size: 20px;
    line-height: 26px;
    width: 312px;
    right: 25px;
  }
}
.Story[data-id='2020-4'] .Story-svgList {
  display: flex;
  position: absolute;
  align-items: flex-end;
  bottom: 60px;
  left: 60px;
  right: 50%;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-4'] .Story-svgList {
    right: 55%;
  }
}
.Story[data-id='2020-4'] .Story-svgList svg {
  height: 75px;
  width: 35px;
  opacity: 0;
  transition: opacity 0.15s;
}
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(2),
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(4) {
  height: 41px;
  width: 41px;
  margin: 0 18px 20px -3px;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-4'] .Story-svgList svg:nth-child(2),
  .Story[data-id='2020-4'] .Story-svgList svg:nth-child(4) {
    margin: 0 8px 15px -3px;
  }
}
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(4) {
  margin-right: -3px;
}
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(5) {
  transform: rotate(180deg);
}
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(6) {
  height: 38px;
  width: 38px;
  margin: 0 54px 21px;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-4'] .Story-svgList svg:nth-child(6) {
    margin: 0 34px 16px;
  }
}
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(7) {
  height: 85px;
  width: 55px;
}
.Story[data-id='2020-3'] {
  display: flex;
  width: 50%;
  padding-bottom: 60.3%;
  overflow: hidden;
  background: #1c1630;
}
.Story[data-id='2020-3'] .Story-text,
.Story[data-id='2020-3'] .Story-text2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  position: absolute;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-3'] .Story-text,
  .Story[data-id='2020-3'] .Story-text2 {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2020-3'] .Story-text {
  width: 202px;
  bottom: 46px;
  left: 40px;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-3'] .Story-text {
    left: 30px;
  }
}
.Story[data-id='2020-3'] .Story-text2 {
  width: 191px;
  top: 34px;
  right: 40px;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-3'] .Story-text2 {
    width: 166px;
    right: 30px;
  }
}
.Story[data-id='2020-3'] .Story-imgWrapper,
.Story[data-id='2020-3'] .Story-imgWrapper2 {
  position: absolute;
  width: 29.38596491%;
  z-index: 1;
}
.Story[data-id='2020-3'] .Story-imgWrapper:after,
.Story[data-id='2020-3'] .Story-imgWrapper2:after {
  content: '';
  display: block;
  padding-bottom: 270.14925373%;
}
.Story[data-id='2020-3'] .Story-imgWrapper {
  top: 19px;
  left: 40px;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-3'] .Story-imgWrapper {
    left: 30px;
  }
}
.Story[data-id='2020-3'] .Story-imgWrapper2 {
  bottom: 19px;
  right: 40px;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-3'] .Story-imgWrapper2 {
    right: 30px;
  }
}
.Story[data-id='2020-3'] .Story-img,
.Story[data-id='2020-3'] .Story-img2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2020-2'] {
  position: relative;
  width: 50%;
  padding-bottom: 60.3%;
  background: #bda470;
}
.Story[data-id='2020-2'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding: 0 48px 46px;
  z-index: 3;
}
.Story[data-id='2020-2'] .Story-imgWrapper {
  position: relative;
  width: 40.5%;
  margin: auto;
  padding-bottom: 40.5%;
}
.Story[data-id='2020-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2020-2'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-right: none;
}
.Story[data-id='2020-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 10px;
  text-align: center;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2020-1'] {
  display: flex;
  height: 400px;
  width: 100%;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2020-1'] .Story-content {
  position: absolute;
  width: 265px;
  bottom: 45px;
  right: 96px;
  z-index: 2;
}
.Story[data-id='2020-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2020-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2020-1'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: calc(((100% - (4 - 1) * 48px - 0 * 48px - 0 * 48px) / 4 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2020-1'] .Story-imgWrapper {
    width: calc(((100% - (4 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2020-1'] .Story-imgWrapper:after {
  content: '';
  display: block;
  padding-bottom: 145.3%;
}
.Story[data-id='2020-1'] .Story-imgWrapper2 {
  position: absolute;
  top: 0;
  left: 5%;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 5 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2020-1'] .Story-imgWrapper2 {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 5 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2020-1'] .Story-imgWrapper2:after {
  content: '';
  display: block;
  padding-bottom: 145.3%;
}
.Story[data-id='2020-1'] .Story-img,
.Story[data-id='2020-1'] .Story-img2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2019-4'] {
  width: 100%;
  height: 480px;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .Story[data-id='2019-4'] {
    height: 250px;
  }
}
.Story[data-id='2019-4'].isReversed .Story-imgWrapper,
.Story[data-id='2019-4'].isReversed .Story-imgWrapper2 {
  flex-flow: row-reverse;
}
.Story[data-id='2019-4'].isAnimated .Story-img {
  transform: scale(0.8);
  opacity: 0;
}
.Story[data-id='2019-4'] .Story-content {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 55px;
  padding-left: 52px;
  z-index: 2;
}
.Story[data-id='2019-4'] .Story-text {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  color: #1c1630;
}
@media (max-width: 1199px) {
  .Story[data-id='2019-4'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2019-4'] .Story-imgWrapper {
  position: absolute;
  display: flex;
  justify-content: space-between;
  left: calc(50% + 40px);
  top: 0;
  right: 40px;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2019-4'] .Story-imgWrapper {
    display: none;
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2019-4'] .Story-imgWrapper {
    left: 50%;
    right: 0;
  }
}
.Story[data-id='2019-4'] .Story-imgWrapper2 {
  position: absolute;
  display: flex;
  justify-content: space-between;
  left: 40px;
  right: 40px;
  bottom: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2019-4'] .Story-imgWrapper2 {
    display: none;
  }
}
.Story[data-id='2019-4'] .Story-img {
  width: 216px;
  height: 216px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  transition: transform  .3s, opacity .3s;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2019-4'] .Story-img {
    width: 190px;
    height: 190px;
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2019-4'] .Story-img {
    width: 173px;
    height: 173px;
  }
}
.Story[data-id='2019-3'] {
  position: relative;
  width: 50%;
  padding-bottom: 60%;
  overflow: hidden;
}
.Story[data-id='2019-3'].isAnimated .Story-img,
.Story[data-id='2019-3'].isAnimated .Story-img2,
.Story[data-id='2019-3'].isAnimated .Story-img3,
.Story[data-id='2019-3'].isAnimated .Story-img4 {
  opacity: 1;
}
.Story[data-id='2019-3'] .Story-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30.76923077%;
  background: linear-gradient(1.55deg, #080808 4.7%, rgba(8, 8, 8, 0) 90.82%);
  z-index: 3;
}
.Story[data-id='2019-3'] .Story-text {
  position: absolute;
  left: 60px;
  right: 48px;
  bottom: 72px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
}
@media (max-width: 1199px) {
  .Story[data-id='2019-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2019-3'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  right: 128px;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.Story[data-id='2019-3'] .Story-imgWrapper2 {
  position: absolute;
  left: 96px;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.Story[data-id='2019-3'] .Story-imgWrapper3 {
  position: absolute;
  left: 48px;
  right: 56px;
  bottom: 0;
  height: 94.87179487%;
}
.Story[data-id='2019-3'] .Story-imgWrapper4 {
  position: absolute;
  left: 48px;
  right: 56px;
  bottom: 0;
  height: 94.87179487%;
}
.Story[data-id='2019-3'] .Story-img,
.Story[data-id='2019-3'] .Story-img2,
.Story[data-id='2019-3'] .Story-img3,
.Story[data-id='2019-3'] .Story-img4 {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.Story[data-id='2019-3'] .Story-img {
  transition: opacity .15s;
}
.Story[data-id='2019-3'] .Story-img2 {
  transition: opacity .15s .15s;
}
.Story[data-id='2019-3'] .Story-img3 {
  transition: opacity .15s .3s;
}
.Story[data-id='2019-3'] .Story-img4 {
  transition: opacity .15s .45s;
}
.Story[data-id='2019-2'] {
  position: relative;
  width: 50%;
  padding-bottom: 60%;
  overflow: hidden;
}
.Story[data-id='2019-2'] .Story-content {
  position: absolute;
  left: 48px;
  top: 40px;
  right: 60px;
  z-index: 3;
}
.Story[data-id='2019-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
}
@media (max-width: 1199px) {
  .Story[data-id='2019-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2019-2'] .Story-imagesWrapper {
  position: absolute;
  left: -15px;
  top: 7.69230769%;
  right: 0;
  width: 48%;
  margin: 0 auto;
  transform: rotate(61.54deg);
}
.Story[data-id='2019-2'] .Story-imagesWrapper:after {
  content: '';
  display: block;
  padding-bottom: 270%;
}
.Story[data-id='2019-2'] .Story-imgWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.Story[data-id='2019-2'] .Story-imgWrapper:first-child .Story-imgMask {
  height: 100%;
}
.Story[data-id='2019-2'] .Story-img {
  width: 100%;
  height: 100%;
}
.Story[data-id='2019-2'] .Story-imgMask {
  height: 0;
}
.Story[data-id='2019-1'] {
  width: 100%;
  height: 480px;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .Story[data-id='2019-1'] {
    height: 250px;
  }
}
.Story[data-id='2019-1'] .Story-content {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 55px;
  padding-left: 52px;
  z-index: 2;
}
.Story[data-id='2019-1'] .Story-text {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  color: #fff;
}
@media (max-width: 1199px) {
  .Story[data-id='2019-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2019-1'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
.Story[data-id='2019-1'] .Story-imgWrapper:after {
  content: '';
  display: block;
  padding-bottom: 57%;
}
@media (min-width: 1680px) {
  .Story[data-id='2019-1'] .Story-imgWrapper:after {
    padding-bottom: 50%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2019-1'] .Story-imgWrapper:after {
    padding-bottom: 69%;
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2019-1'] .Story-imgWrapper:after {
    padding-bottom: 51%;
  }
}
.Story[data-id='2019-1'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2018-1'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
}
.Story[data-id='2018-1'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2018-1'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2018-1'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2018-1'] .Story-animation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2018-1'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2018-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #ffffff;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2018-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2018-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2018-2'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  overflow: hidden;
}
.Story[data-id='2018-2'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-top: 43px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
  order: 1;
}
@media (max-width: 1199px) {
  .Story[data-id='2018-2'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2018-2'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2018-2'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  order: 2;
  margin-top: 7%;
}
.Story[data-id='2018-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2018-2'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2018-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2018-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2018-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2018-3'] {
  display: flex;
  height: 228px;
  overflow: hidden;
  width: 100%;
  background: #1c1630;
}
@media (max-width: 1199px) {
  .Story[data-id='2018-3'] {
    height: 250px;
  }
}
.Story[data-id='2018-3'] .Story-content {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 62px;
  padding-left: 48px;
  z-index: 2;
}
.Story[data-id='2018-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2018-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2018-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2018-3'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2018-3'] .Story-imgWrapper {
    top: 100px;
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2018-3'] .Story-imgWrapper {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2018-3'] .Story-imgWrapper {
    right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2018-3'] .Story-imgWrapper:after {
  content: '';
  display: block;
  padding-bottom: 272.15%;
}
.Story[data-id='2018-3'] .Story-img {
  position: absolute;
  height: 100%;
  width: calc(100% - 46px);
  top: 0;
  bottom: 0;
  left: 18px;
  right: 28px;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2017-1'] {
  width: 50%;
  padding-bottom: 40px;
  padding-left: 48px;
  padding-right: 84px;
}
.Story[data-id='2017-1'] .Story-imgWrapper {
  text-align: center;
  margin-bottom: 10px;
}
.Story[data-id='2017-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2017-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2017-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2017-2'] {
  display: flex;
  width: 50%;
  padding-top: 68px;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 84px;
}
.Story[data-id='2017-2'] .Story-img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 151px;
  height: 144px;
  margin-left: -75px;
  margin-top: -72px;
}
.Story[data-id='2017-2'] .Story-imgWrapper {
  flex: 1 1;
  position: relative;
}
.Story[data-id='2017-2'] .Story-inner {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.Story[data-id='2017-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2017-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2017-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2017-3'] {
  display: flex;
  height: 228px;
  overflow: hidden;
  width: 100%;
  background: #1c1630;
}
@media (max-width: 1199px) {
  .Story[data-id='2017-3'] {
    height: 250px;
  }
}
.Story[data-id='2017-3'] .Story-content {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 62px;
  padding-left: 48px;
  z-index: 2;
}
.Story[data-id='2017-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2017-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2017-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2017-3'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2017-3'] .Story-imgWrapper {
    top: 100px;
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2017-3'] .Story-imgWrapper {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2017-3'] .Story-imgWrapper {
    right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2017-3'] .Story-imgWrapper:after {
  content: '';
  display: block;
  padding-bottom: 272.15%;
}
.Story[data-id='2017-3'] .Story-img {
  position: absolute;
  height: 100%;
  width: calc(100% - 46px);
  top: 0;
  bottom: 0;
  left: 18px;
  right: 28px;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2016-1'] {
  display: flex;
  height: 480px;
  width: 100%;
  background: #1c1630;
}
@media (max-width: 1199px) {
  .Story[data-id='2016-1'] {
    height: 350px;
  }
}
.Story[data-id='2016-1'] .Story-content {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 43px;
  padding-left: 48px;
  z-index: 2;
}
.Story[data-id='2016-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2016-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2016-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2016-1'] .Story-imgWrapperOuter {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.Story[data-id='2016-1'] .Story-imgWrapperInner {
  position: relative;
  width: 47%;
  padding: 10%;
}
.Story[data-id='2016-1'] .Story-imgWrapperInner:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.Story[data-id='2016-1'] .Story-outerCircle {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #2c2448;
  border-radius: 50%;
}
.Story[data-id='2016-1'] .Story-innerCircle {
  content: '';
  position: absolute;
  height: 64%;
  width: 64%;
  top: 18%;
  left: 18%;
  background: #e6102d;
  border-radius: 50%;
}
.Story[data-id='2016-1'] .Story-contentCircle {
  position: absolute;
  height: 48%;
  width: 48%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 30px;
  overflow: hidden;
  background: #fff;
  transition: transform 0.1s ease-out;
  z-index: 1;
  will-change: transform;
  border-radius: 50%;
}
.Story[data-id='2016-1'] .Story-circleImage {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2016-2'] {
  width: 50%;
  padding-top: 68px;
  padding-bottom: 18px;
  padding-left: 48px;
  padding-right: 84px;
}
.Story[data-id='2016-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2016-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2016-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2016-3'] {
  width: 100%;
  max-height: 264px;
  overflow: hidden;
}
.Story[data-id='2016-3'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2016-3'] .Story-content {
  position: relative;
  align-self: center;
  margin-top: -1.4%;
  padding-left: 50px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 4 + 3 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2016-3'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 4 + 3 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2016-3'] .Story-imgWrapper {
  margin-top: 4%;
  margin-bottom: 3.5%;
  padding-left: 46px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2016-3'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2016-3'] .Story-img {
  display: block;
  height: 100%;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2016-3'] .Story-imgWrapper2 {
  position: absolute;
  height: 152px;
  top: 5px;
  bottom: 0;
  left: 128px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
}
.Story[data-id='2016-3'] .Story-img2 {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2016-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2016-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2016-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2015-1'] {
  display: flex;
  height: 480px;
  width: 100%;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-1'] {
    height: 350px;
  }
}
.Story[data-id='2015-1'] .Story-inner {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-top: none;
  z-index: 2;
}
.Story[data-id='2015-1'] .Story-content {
  position: relative;
  margin-top: 43px;
  margin-left: 48px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 2 + 1 * 48px + 2 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2015-1'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 2 + 1 * 32px + 2 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2015-1'] .Story-imgWrapper {
  position: absolute;
  width: 53%;
  bottom: 0;
  right: 11px;
  padding-bottom: 31.06%;
}
.Story[data-id='2015-1'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2015-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  margin-left: -1px;
}
.Story[data-id='2015-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2015-2'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2015-2'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-2'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2015-2'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2015-2'] .Story-imgWrapper {
  position: relative;
  width: 32.6%;
  margin-top: 8.8%;
  margin-left: 48px;
  padding-bottom: 32.6%;
}
.Story[data-id='2015-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2015-2'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-top: none;
}
.Story[data-id='2015-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #000;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2015-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2015-3'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2015-3'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-3'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2015-3'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2015-3'] .Story-imgWrapper {
  position: relative;
  width: 32.6%;
  margin-top: 8.8%;
  margin-left: 60px;
  padding-bottom: 32.6%;
}
.Story[data-id='2015-3'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2015-3'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-top: none;
  border-left: none;
}
.Story[data-id='2015-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #000;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2015-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2015-4'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2015-4'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-4'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2015-4'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2015-4'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  align-self: center;
  width: 61%;
  margin-top: -10px;
}
.Story[data-id='2015-4'] .Story-img {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2015-4'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-top: none;
}
.Story[data-id='2015-4'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-4'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2015-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2015-5'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2015-5'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-5'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2015-5'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2015-5'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 60px;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2015-5'] .Story-imgWrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2015-5'] .Story-imgWrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.Story[data-id='2015-5'] .Story-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: left;
     object-position: left;
  transform-origin: left bottom;
}
.Story[data-id='2015-5'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-top: none;
  border-left: none;
}
.Story[data-id='2015-5'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-5'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2015-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2015-6'] {
  display: flex;
  height: 480px;
  width: 100%;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-6'] {
    height: 350px;
  }
}
.Story[data-id='2015-6'] .Story-inner {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2015-6'] .Story-content {
  position: relative;
  margin-top: 43px;
  margin-left: 48px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2015-6'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2015-6'] .Story-imgWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2015-6'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2015-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2015-6'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-6'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2015-7'] {
  display: flex;
  height: 480px;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-7'] {
    height: 350px;
  }
}
.Story[data-id='2015-7'] .Story-inner {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-top: none;
  z-index: 2;
}
.Story[data-id='2015-7'] .Story-content {
  position: relative;
  margin-top: 43px;
  margin-left: 48px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 4 + 3 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2015-7'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 4 + 3 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2015-7'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2015-7'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2015-7'] .Story-imgWrapper {
    right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2015-7'] .Story-imgWrapper:after {
  content: '';
  display: block;
  padding-bottom: 272.15%;
}
.Story[data-id='2015-7'] .Story-img {
  position: absolute;
  height: 100%;
  width: calc(100% - 46px);
  top: 0;
  bottom: 0;
  left: 18px;
  right: 28px;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2015-7'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2015-7'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2015-7'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-1'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2014-1'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-1'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2014-1'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2014-1'] .Story-imgWrapper {
  position: relative;
  width: 32.6%;
  margin-top: 8.8%;
  margin-left: 48px;
  padding-bottom: 32.6%;
}
.Story[data-id='2014-1'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-1'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
}
.Story[data-id='2014-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #000;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2014-2'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2014-2'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-2'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2014-2'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2014-2'] .Story-imgWrapper {
  position: relative;
  width: 32.6%;
  margin-top: 8.8%;
  margin-left: 60px;
  padding-bottom: 32.6%;
}
.Story[data-id='2014-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2014-2'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-left: none;
}
.Story[data-id='2014-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #000;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2014-3'] {
  width: 100%;
  max-height: 264px;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2014-3'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2014-3'] .Story-content {
  position: relative;
  align-self: center;
  margin-top: -1.4%;
  padding-left: 19px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 5 + 4 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-3'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 5 + 4 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2014-3'] .Story-imgWrapper {
  margin-top: 4%;
  margin-bottom: 3.5%;
  padding-left: 46px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-3'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2014-3'] .Story-img {
  display: block;
  height: 100%;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-3'] .Story-imgWrapper2 {
  position: absolute;
  height: 152px;
  top: 5px;
  bottom: 0;
  left: 128px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
}
.Story[data-id='2014-3'] .Story-img2 {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2014-4'] {
  width: 100%;
  max-height: 264px;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2014-4'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2014-4'] .Story-content {
  position: relative;
  align-self: center;
  margin-top: -1.4%;
  padding-left: 19px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 5 + 4 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-4'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 5 + 4 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2014-4'] .Story-imgWrapper {
  margin-top: 4%;
  margin-bottom: 3.5%;
  padding-left: 46px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-4'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2014-4'] .Story-img {
  display: block;
  height: 100%;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-4'] .Story-imgWrapper2 {
  position: absolute;
  height: 152px;
  top: 5px;
  bottom: 0;
  left: 128px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
}
.Story[data-id='2014-4'] .Story-img2 {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-4'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-4'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2014-5'] {
  width: 50%;
  max-height: 258px;
  background: #fff;
}
.Story[data-id='2014-5'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  border: 1px solid #ebebf5;
  z-index: 2;
}
.Story[data-id='2014-5'] .Story-content {
  position: relative;
  margin-left: 48px;
  padding-top: 43px;
  padding-bottom: 10.1%;
  z-index: 2;
  order: 1;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-5'] .Story-content {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2014-5'] .Story-imgWrapper {
  align-self: flex-end;
  margin-top: 10%;
  padding-left: 19px;
  padding-right: 19px;
  order: 2;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
  right: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 0.5 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-5'] .Story-imgWrapper {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2014-5'] .Story-imgWrapper {
    right: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 0.5 + -0.5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2014-5'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-5'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-5'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2014-6'] {
  width: 50%;
  max-height: 258px;
  background: #fff;
}
.Story[data-id='2014-6'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  border: 1px solid #ebebf5;
  border-left: none;
  z-index: 2;
}
.Story[data-id='2014-6'] .Story-content {
  position: relative;
  margin-left: 60px;
  padding-top: 43px;
  padding-bottom: 10.1%;
  z-index: 2;
  order: 1;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-6'] .Story-content {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2014-6'] .Story-imgWrapper {
  align-self: flex-end;
  margin-top: 10%;
  padding-left: 11px;
  padding-right: 9px;
  order: 2;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
  right: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 0.5 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-6'] .Story-imgWrapper {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2014-6'] .Story-imgWrapper {
    right: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 0.5 + -0.5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2014-6'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-6'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-6'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2014-7'] {
  width: 50%;
  max-height: 258px;
  background: #fff;
}
.Story[data-id='2014-7'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  border: 1px solid #ebebf5;
  border-top: none;
  z-index: 2;
}
.Story[data-id='2014-7'] .Story-content {
  position: relative;
  margin-left: 48px;
  padding-top: 43px;
  padding-bottom: 10.1%;
  z-index: 2;
  order: 1;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-7'] .Story-content {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2014-7'] .Story-imgWrapper {
  align-self: center;
  margin-top: 5.8%;
  margin-bottom: 7%;
  padding-left: 13px;
  padding-right: 12px;
  order: 2;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
  right: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 0.5 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-7'] .Story-imgWrapper {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2014-7'] .Story-imgWrapper {
    right: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 0.5 + -0.5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2014-7'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-7'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-7'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-7'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2014-8'] {
  width: 50%;
  max-height: 258px;
  background: #fff;
}
.Story[data-id='2014-8'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  border: 1px solid #ebebf5;
  border-top: none;
  border-left: none;
  z-index: 2;
}
.Story[data-id='2014-8'] .Story-content {
  position: relative;
  margin-left: 60px;
  padding-top: 43px;
  padding-bottom: 10.1%;
  z-index: 2;
  order: 1;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-8'] .Story-content {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2014-8'] .Story-imgWrapper {
  align-self: center;
  padding-left: 15px;
  padding-right: 23px;
  order: 2;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
  right: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 0.5 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-8'] .Story-imgWrapper {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2014-8'] .Story-imgWrapper {
    right: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 0.5 + -0.5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2014-8'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-8'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-8'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-8'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2014-9'] {
  width: 50%;
  max-height: 258px;
  background: #fff;
}
.Story[data-id='2014-9'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  border: 1px solid #ebebf5;
  border-top: none;
  z-index: 2;
}
.Story[data-id='2014-9'] .Story-content {
  position: relative;
  margin-left: 48px;
  padding-top: 43px;
  padding-bottom: 10.1%;
  z-index: 2;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-9'] .Story-content {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2014-9'] .Story-imgWrapper {
  position: absolute;
  height: 198px;
  top: 0;
  bottom: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 2;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-9'] .Story-imgWrapper {
    right: -40px;
  }
}
.Story[data-id='2014-9'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-9'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-9'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-9'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2014-10'] {
  display: flex;
  height: 228px;
  overflow: hidden;
  border: 1px solid #ebebf5;
  border-top: none;
  width: 100%;
  background: #fff;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-10'] {
    height: 250px;
  }
}
.Story[data-id='2014-10'] .Story-content {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 62px;
  padding-left: 48px;
  z-index: 2;
}
.Story[data-id='2014-10'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-10'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2014-10'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2014-10'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2014-10'] .Story-imgWrapper {
    top: 100px;
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2014-10'] .Story-imgWrapper {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2014-10'] .Story-imgWrapper {
    right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2014-10'] .Story-imgWrapper:after {
  content: '';
  display: block;
  padding-bottom: 272.15%;
}
.Story[data-id='2014-10'] .Story-img {
  position: absolute;
  height: 100%;
  width: calc(100% - 46px);
  top: -33%;
  bottom: 0;
  left: 18px;
  right: 28px;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2013-1'] {
  height: 256px;
  width: 100%;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2013-1'] .Story-inner {
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2013-1'] .Story-content {
  position: relative;
  margin-left: 48px;
  padding-top: 43px;
  padding-bottom: 28px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 2 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2013-1'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 2 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2013-1'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 7 + 6 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * -1 + 0 * 48px + -1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2013-1'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 7 + 6 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2013-1'] .Story-imgWrapper {
    right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * -1 + 0 * 32px + -1 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2013-1'] .Story-imgWrapper:after {
  content: '';
  display: block;
  padding-bottom: 133.88%;
}
.Story[data-id='2013-1'] .Story-img {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2013-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2013-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2013-1'] .Story-text2 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  color: #616166;
}
.Story[data-id='2013-2'] {
  height: 256px;
  width: 50%;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2013-2'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  z-index: 2;
}
.Story[data-id='2013-2'] .Story-content {
  position: relative;
  width: 324px;
  padding-top: 43px;
  padding-bottom: 28px;
  padding-left: 43px;
  z-index: 2;
}
.Story[data-id='2013-2'] .Story-imgWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.Story[data-id='2013-2'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2013-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2013-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2013-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2013-3'] {
  height: 256px;
  width: 50%;
  background: #fff;
}
.Story[data-id='2013-3'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  border: 1px solid #ebebf5;
  z-index: 2;
}
.Story[data-id='2013-3'] .Story-content {
  position: relative;
  margin-left: 60px;
  padding-top: 43px;
  padding-bottom: 28px;
  z-index: 2;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 4.5 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2013-3'] .Story-content {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 4.5 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2013-3'] .Story-imgWrapper {
  position: absolute;
  height: 198px;
  top: 0;
  bottom: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 2;
}
@media (max-width: 1199px) {
  .Story[data-id='2013-3'] .Story-imgWrapper {
    right: -40px;
  }
}
.Story[data-id='2013-3'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2013-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2013-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2013-4'] {
  height: 328px;
  width: 100%;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2013-4'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2013-4'] .Story-content {
  position: relative;
  padding-top: 43px;
  padding-bottom: 28px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 5 + 4 * 48px + 0 * 48px + 0 * 48px / 2) + 24px);
  margin-left: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2013-4'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 5 + 4 * 32px + 0 * 32px + 0 * 48px) + 24px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2013-4'] .Story-content {
    margin-left: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2013-4'] .Story-imgWrapper {
  position: absolute;
  height: 152px;
  top: 5px;
  bottom: 0;
  left: 45px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 2;
}
.Story[data-id='2013-4'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-4'] .Story-imgWrapper2 {
  position: absolute;
  height: 152px;
  top: 5px;
  bottom: 0;
  left: 128px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
}
.Story[data-id='2013-4'] .Story-img2 {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-4'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2013-4'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2013-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2013-5'] {
  height: 328px;
  width: 100%;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2013-5'] .Story-inner {
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2013-5'] .Story-content {
  position: relative;
  margin-left: 48px;
  padding-top: 43px;
  padding-bottom: 28px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 5 + 4 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2013-5'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 5 + 4 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  .Story[data-id='2013-5'] .Story-content {
    width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 4 + 3 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px), (max-width: 1199px) and (max-width: 1199px) {
  .Story[data-id='2013-5'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 4 + 3 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2013-5'] .Story-imgWrapper {
  position: absolute;
  height: 225px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2013-5'] .Story-imgWrapper {
    right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2013-5'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-5'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2013-5'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2013-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2013-6'] {
  height: 256px;
  width: 100%;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2013-6'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2013-6'] .Story-content {
  position: relative;
  padding-top: 43px;
  padding-bottom: 28px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 5 + 4 * 48px + 0 * 48px + 0 * 48px / 2) + 24px);
  margin-left: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2013-6'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 5 + 4 * 32px + 0 * 32px + 0 * 48px) + 24px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2013-6'] .Story-content {
    margin-left: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2013-6'] .Story-imgWrapper {
  position: absolute;
  height: 185px;
  top: 5px;
  bottom: 0;
  left: 45px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 2;
}
.Story[data-id='2013-6'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-6'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2013-6'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2013-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2013-7'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2013-7'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-top: 43px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
  order: 1;
}
@media (max-width: 1199px) {
  .Story[data-id='2013-7'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2013-7'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2013-7'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  margin-top: 7%;
  order: 2;
}
.Story[data-id='2013-7'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-7'] .Story-img2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 70%;
  right: -70%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-7'] .Story-img3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 140%;
  right: -140%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-7'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
}
.Story[data-id='2013-7'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2013-7'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2013-7'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2012-1'] {
  width: 100%;
  min-height: 144px;
  background: #fff;
}
.Story[data-id='2012-1'] .Story-inner {
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2012-1'] .Story-content {
  position: relative;
  margin-left: 48px;
  padding-top: 38px;
  padding-bottom: 40px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 5 + 4 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2012-1'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 5 + 4 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2012-1'] .Story-imgWrapper {
  height: 88px;
  width: 71px;
  margin-top: 36px;
  margin-left: 46px;
}
.Story[data-id='2012-1'] .Story-img {
  display: block;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: top;
     object-position: top;
}
.Story[data-id='2012-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2012-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2012-2'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2012-2'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-2'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2012-2'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2012-2'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  width: 100%;
  margin-top: 29.4%;
  margin-bottom: 10.3%;
}
.Story[data-id='2012-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2012-2'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
}
.Story[data-id='2012-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2012-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2012-3'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2012-3'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-3'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2012-3'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2012-3'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  width: 100%;
  margin-top: 21%;
  margin-bottom: 5%;
}
.Story[data-id='2012-3'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  z-index: 2;
}
.Story[data-id='2012-3'] .Story-circle {
  position: absolute;
  height: 140px;
  width: 140px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(2, 161, 224, 0.14);
  border-radius: 50%;
}
.Story[data-id='2012-3'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-left: none;
}
.Story[data-id='2012-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2012-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2012-4'] {
  height: 310px;
  width: 100%;
  background: #fff;
}
.Story[data-id='2012-4'] .Story-inner {
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2012-4'] .Story-content {
  position: relative;
  margin-left: 48px;
  padding-top: 43px;
  padding-bottom: 28px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 5 + 4 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2012-4'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 5 + 4 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  .Story[data-id='2012-4'] .Story-content {
    width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 4 + 3 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px), (max-width: 1199px) and (max-width: 1199px) {
  .Story[data-id='2012-4'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 4 + 3 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2012-4'] .Story-imgWrapper {
  position: absolute;
  height: 245px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2012-4'] .Story-imgWrapper {
    right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2012-4'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2012-4'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Story[data-id='2012-4'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2012-4'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2012-4'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-4'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2012-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2012-5'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2012-5'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-5'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2012-5'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2012-5'] .Story-imgWrapper {
  position: relative;
  width: 25.6%;
  margin-top: 11%;
  margin-left: 49px;
  padding-bottom: 25.6%;
  z-index: 2;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-5'] .Story-imgWrapper {
    width: 20%;
    margin-top: 5%;
    padding-bottom: 20%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2012-5'] .Story-imgWrapper {
    width: 22.5%;
    margin-top: 8%;
    padding-bottom: 22.5%;
  }
}
.Story[data-id='2012-5'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2012-5'] .Story-imgWrapper2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.Story[data-id='2012-5'] .Story-img2 {
  display: block;
  width: 100%;
  margin-top: calc(11% + 60px);
}
@media (max-width: 1199px) {
  .Story[data-id='2012-5'] .Story-img2 {
    margin-top: calc(5% + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2012-5'] .Story-img2 {
    margin-top: calc(8% + 50px);
  }
}
.Story[data-id='2012-5'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2012-5'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-5'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2012-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2012-6'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2012-6'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-6'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2012-6'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2012-6'] .Story-imgWrapper {
  position: relative;
  width: 35%;
  margin-top: 11%;
  margin-left: 60px;
  padding-bottom: 35%;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-6'] .Story-imgWrapper {
    width: 25%;
    margin-top: 5%;
    padding-bottom: 25%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2012-6'] .Story-imgWrapper {
    width: 30%;
    margin-top: 8%;
    padding-bottom: 30%;
  }
}
.Story[data-id='2012-6'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2012-6'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2012-6'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-6'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2012-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2011-1'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2011-1'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-1'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2011-1'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2011-1'] .Story-imgWrapper {
  position: relative;
  width: 15%;
  margin-top: 11%;
  margin-left: 49px;
  padding-bottom: 63%;
}
.Story[data-id='2011-1'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2011-1'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
}
.Story[data-id='2011-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2011-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2011-2'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2011-2'] .Story-content {
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-2'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2011-2'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2011-2'] .Story-imgWrapper {
  position: absolute;
  max-width: 312px;
  top: 23%;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  padding-top: 25px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 9px;
  z-index: 5;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2011-2'] .Story-imgWrapper {
    top: 10%;
    padding-top: 18px;
    padding-bottom: 15px;
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2011-2'] .Story-imgWrapper {
    top: 15%;
    padding-top: 15px;
    padding-bottom: 12px;
  }
}
.Story[data-id='2011-2'] .Story-img {
  position: absolute;
  height: 114px;
  width: 73px;
  top: -51px;
  right: -38px;
}
@media (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  .Story[data-id='2011-2'] .Story-img {
    height: 57px;
    width: 37px;
    top: -24px;
    right: -18px;
  }
}
.Story[data-id='2011-2'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2011-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2011-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
}
.Story[data-id='2011-2'] .Story-text2 {
  font-size: 42px;
  font-weight: 500;
  line-height: 48px;
  color: #e6102d;
  position: absolute;
  max-width: 312px;
  top: 23%;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  padding-top: 25px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  background: #fff;
  border-radius: 9px;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2011-2'] .Story-text2 {
    font-size: 30px;
    line-height: 42px;
    top: 10%;
    padding-top: 18px;
    padding-bottom: 15px;
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2011-2'] .Story-text2 {
    font-size: 24px;
    line-height: 36px;
    top: 15%;
    padding-top: 15px;
    padding-bottom: 12px;
  }
}
.Story[data-id='2011-3'] {
  width: 100%;
  min-height: 144px;
  background: #fff;
}
.Story[data-id='2011-3'] .Story-inner {
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2011-3'] .Story-content {
  position: relative;
  margin-left: 48px;
  padding-top: 28px;
  padding-bottom: 28px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 5 + 4 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2011-3'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 5 + 4 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2011-3'] .Story-imgWrapper {
  position: absolute;
  height: 100%;
  width: 184px;
  top: 0;
  overflow: hidden;
  right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2011-3'] .Story-imgWrapper {
    right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2011-3'] .Story-img {
  display: block;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: top;
     object-position: top;
}
.Story[data-id='2011-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2011-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2011-4'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2011-4'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-4'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2011-4'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2011-4'] .Story-imgWrapper {
  position: relative;
  width: 35%;
  margin-top: 11%;
  margin-left: 49px;
  padding-bottom: 35%;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-4'] .Story-imgWrapper {
    width: 25%;
    margin-top: 5%;
    padding-bottom: 25%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2011-4'] .Story-imgWrapper {
    width: 30%;
    margin-top: 8%;
    padding-bottom: 30%;
  }
}
.Story[data-id='2011-4'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2011-4'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-right: none;
}
.Story[data-id='2011-4'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-4'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2011-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2011-5'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2011-5'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-5'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2011-5'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2011-5'] .Story-imgWrapper {
  position: relative;
  width: 35%;
  margin-top: 11%;
  margin-left: 60px;
  padding-bottom: 35%;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-5'] .Story-imgWrapper {
    width: 25%;
    margin-top: 5%;
    padding-bottom: 25%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2011-5'] .Story-imgWrapper {
    width: 30%;
    margin-top: 8%;
    padding-bottom: 30%;
  }
}
.Story[data-id='2011-5'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2011-5'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2011-5'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2011-5'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2011-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2010-1'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2010-1'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-1'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2010-1'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2010-1'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  width: 100%;
  margin-top: 23%;
  margin-bottom: 8%;
  margin-left: -3%;
}
.Story[data-id='2010-1'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2010-1'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
}
.Story[data-id='2010-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2010-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2010-2'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2010-2'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-2'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2010-2'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2010-2'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  width: 100%;
  margin-top: 11%;
  margin-bottom: 2%;
  margin-left: 2%;
}
.Story[data-id='2010-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2010-2'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-left: none;
}
.Story[data-id='2010-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2010-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2010-3'] {
  width: 100%;
  min-height: 168px;
  background: #fff;
}
.Story[data-id='2010-3'] .Story-inner {
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2010-3'] .Story-content {
  position: relative;
  margin-left: 48px;
  padding-top: 28px;
  padding-bottom: 28px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 5 + 4 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2010-3'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 5 + 4 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2010-3'] .Story-imgWrapper {
  position: absolute;
  height: 81%;
  bottom: 0;
  right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2010-3'] .Story-imgWrapper {
    right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2010-3'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2010-3'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Story[data-id='2010-3'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2010-3'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2010-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2010-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2010-4'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2010-4'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-top: 43px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
  order: 1;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-4'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2010-4'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2010-4'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  order: 2;
  margin-top: 7%;
  margin-bottom: 7%;
}
.Story[data-id='2010-4'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2010-4'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
}
.Story[data-id='2010-4'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-4'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2010-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2010-5'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  overflow: hidden;
  background: #fff;
}
.Story[data-id='2010-5'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-top: 43px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
  order: 1;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-5'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2010-5'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2010-5'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  margin-top: 11%;
  z-index: 2;
  order: 2;
}
.Story[data-id='2010-5'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2010-5'] .Story-imgWrapper2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2010-5'] .Story-img2 {
  position: absolute;
  width: 123%;
  bottom: 22px;
  left: 0;
  right: 0;
}
.Story[data-id='2010-5'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
}
.Story[data-id='2010-5'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-5'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2010-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2010-6'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2010-6'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-top: 43px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
  order: 1;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-6'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2010-6'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2010-6'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  order: 2;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-6'] .Story-imgWrapper {
    margin-top: -5%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2010-6'] .Story-imgWrapper {
    margin-top: -5%;
  }
}
.Story[data-id='2010-6'] .Story-img {
  position: absolute;
  width: 240px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1199px) {
  .Story[data-id='2010-6'] .Story-img {
    width: 100px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2010-6'] .Story-img {
    width: 200px;
  }
}
.Story[data-id='2010-6'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
}
.Story[data-id='2010-6'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2010-6'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2010-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2009-1'] {
  height: 228px;
  width: 100%;
  background: #fff;
}
.Story[data-id='2009-1'] .Story-inner {
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2009-1'] .Story-content {
  position: relative;
  margin-left: 48px;
  padding-top: 73px;
  padding-bottom: 28px;
  z-index: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2009-1'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px), (max-width: 1199px) {
  .Story[data-id='2009-1'] .Story-content {
    width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 4 + 3 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px), (max-width: 1199px) and (max-width: 1199px) {
  .Story[data-id='2009-1'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 4 + 3 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2009-1'] .Story-imgWrapper {
  position: absolute;
  height: 140px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2009-1'] .Story-imgWrapper {
    right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2009-1'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2009-1'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .Story[data-id='2009-1'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2009-1'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2009-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2009-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2009-2'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2009-2'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-2'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2009-2'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2009-2'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  width: 100%;
  margin-top: 10%;
  margin-bottom: 9%;
}
.Story[data-id='2009-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2009-2'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-bottom: none;
  border-left: none;
}
.Story[data-id='2009-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2009-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2009-3'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2009-3'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-top: 43px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
  order: 1;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-3'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2009-3'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2009-3'] .Story-imgWrapper {
  position: relative;
  flex: 1 1;
  order: 2;
  margin-top: 7%;
  overflow: hidden;
}
.Story[data-id='2009-3'] .Story-img {
  position: absolute;
  height: 90%;
  width: 90%;
  top: 0;
  left: 5%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2009-3'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-bottom: none;
  border-right: none;
  border-top: none;
}
.Story[data-id='2009-3'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-3'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2009-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2009-4'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2009-4'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-4'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2009-4'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2009-4'] .Story-imgWrapper {
  position: absolute;
  top: -1px;
  bottom: 0;
  left: -1px;
  right: 0;
}
.Story[data-id='2009-4'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2009-4'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2009-4'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-4'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2009-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2009-5'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2009-5'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-top: 43px;
  padding-left: 48px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-5'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2009-5'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2009-5'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2009-5'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2009-5'] .Story-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  border-right: none;
}
.Story[data-id='2009-5'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-5'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2009-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2009-6'] {
  position: relative;
  width: 50%;
  padding-bottom: 51.16%;
  background: #fff;
}
.Story[data-id='2009-6'] .Story-content {
  position: relative;
  flex-shrink: 0;
  padding-bottom: 39px;
  padding-left: 60px;
  padding-right: 48px;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-6'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2009-6'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2009-6'] .Story-animation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 1199px), (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2009-6'] .Story-animation {
    bottom: 15%;
  }
}
.Story[data-id='2009-6'] .Story-inner {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-left: none;
  border-top: none;
}
.Story[data-id='2009-6'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-6'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2009-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Story[data-id='2009-7'] {
  display: flex;
  height: 328px;
  width: 100%;
}
.Story[data-id='2009-7'] .Story-content {
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 4 + 3 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
  margin-left: 48px;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-7'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 4 + 3 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2009-7'] .Story-inner {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  border-top: none;
  z-index: 2;
}
.Story[data-id='2009-7'] .Story-content {
  position: relative;
  margin-top: 43px;
  z-index: 2;
}
.Story[data-id='2009-7'] .Story-imgWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
}
.Story[data-id='2009-7'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2009-7'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2009-7'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2009-7'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2008-1'] {
  display: flex;
  height: 328px;
  width: 100%;
}
.Story[data-id='2008-1'] .Story-content {
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 4 + 3 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
  margin-left: 48px;
}
@media (max-width: 1199px) {
  .Story[data-id='2008-1'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 4 + 3 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2008-1'] .Story-inner {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  z-index: 2;
}
.Story[data-id='2008-1'] .Story-content {
  position: relative;
  margin-top: 43px;
  z-index: 2;
  order: 1;
}
.Story[data-id='2008-1'] .Story-imgWrapper {
  flex-shrink: 0;
  align-self: center;
  margin-top: -19px;
  margin-left: auto;
  margin-right: 48px;
  padding: 0;
  order: 2;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 2 + 1 * 48px + 2 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Story[data-id='2008-1'] .Story-imgWrapper {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 2 + 1 * 32px + 2 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2008-1'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2008-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2008-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2008-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
/*
@{c}[data-id='2008-2'] {
    display: flex;
    height: 328px;
    width: 100%;

    @{c}-content {
        .calcgrid(@property: width; @columns: 4; @total-columns: 9; @has-last-gutter: 1; @constant-value: 24px);

        margin-left: 48px;
    }

    @{c}-inner {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid #ebebf5;
        border-top: none;
        z-index: 2;
    }

    @{c}-content {
        position: relative;
        margin-top: 43px;
        z-index: 2;
        order: 1;
    }

    @{c}-imgWrapper {
        display: flex;
        align-items: stretch;
        flex-shrink: 0;
        height: 100%;
        width: 50%;
        padding: 0;
        order: 2;
    }

    @{c}-img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: contain; font-family: 'object-fit: contain;';
        object-position: bottom;
    }

    @{c}-month {
        font-size: 10px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: @color-primary;
        margin-left: -1px;
    }

    @{c}-text {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: #000;
        margin-top: 9px;

        @media @device-small {
            font-size: 20px;
            line-height: 26px;
        }
    }
}
*/
.Story[data-id='2008-2'] {
  display: flex;
  height: 228px;
  overflow: hidden;
  border: 1px solid #ebebf5;
  border-top: none;
  width: 100%;
  background: #fff;
}
@media (max-width: 1199px) {
  .Story[data-id='2008-2'] {
    height: 250px;
  }
}
.Story[data-id='2008-2'] .Story-content {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 62px;
  padding-left: 48px;
  z-index: 2;
}
.Story[data-id='2008-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2008-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2008-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2008-2'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Story[data-id='2008-2'] .Story-imgWrapper {
    top: 100px;
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2008-2'] .Story-imgWrapper {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2008-2'] .Story-imgWrapper {
    right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2008-2'] .Story-imgWrapper:after {
  content: '';
  display: block;
  padding-bottom: 272.15%;
}
.Story[data-id='2008-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: calc(100% - 46px);
  top: -33%;
  bottom: 0;
  left: 18px;
  right: 28px;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2007-1'] {
  display: flex;
  height: 328px;
  width: 100%;
}
.Story[data-id='2007-1'] .Story-content {
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 3 + 2 * 48px + 2 * 48px + 0 * 48px / 2) + 24px);
  margin-left: 48px;
}
@media (max-width: 1199px) {
  .Story[data-id='2007-1'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 3 + 2 * 32px + 2 * 32px + 0 * 48px) + 24px);
  }
}
.Story[data-id='2007-1'] .Story-video {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Story[data-id='2007-1'] .Story-inner {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #ebebf5;
  z-index: 2;
}
.Story[data-id='2007-1'] .Story-content {
  position: relative;
  margin-top: 43px;
  z-index: 2;
}
.Story[data-id='2007-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2007-1'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2007-1'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story[data-id='2007-2'] {
  display: flex;
  height: 328px;
  width: 100%;
}
.Story[data-id='2007-2'] .Story-content {
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 4 + 3 * 48px + 1 * 48px + 0 * 48px / 2) + 24px);
  margin-right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  margin-left: 48px;
}
@media (max-width: 1199px) {
  .Story[data-id='2007-2'] .Story-content {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 4 + 3 * 32px + 1 * 32px + 0 * 48px) + 24px);
  }
}
@media (max-width: 1199px) {
  .Story[data-id='2007-2'] .Story-content {
    margin-right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2007-2'] .Story-animation {
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 4 + 3 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  order: 2;
}
@media (max-width: 1199px) {
  .Story[data-id='2007-2'] .Story-animation {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 4 + 3 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Story[data-id='2007-2'] .Story-inner {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.Story[data-id='2007-2'] .Story-content {
  position: relative;
  flex: 1 1;
  margin-top: 43px;
  z-index: 2;
}
.Story[data-id='2007-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2007-2'] .Story-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #000;
  margin-top: 9px;
}
@media (max-width: 1199px) {
  .Story[data-id='2007-2'] .Story-text {
    font-size: 20px;
    line-height: 26px;
  }
}
.Story {
  position: relative;
}
.Story-animation {
  overflow: hidden;
}
