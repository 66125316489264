.CommonNewForm {
  color: #0f0c1a;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin-top: 32px;
}
@media (max-width: 1199px) {
  .CommonNewForm {
    font-size: 16px;
    line-height: 25px;
    margin-top: 48px;
  }
}
.CommonNewForm p {
  margin-bottom: 16px;
}
.CommonNewForm p + p {
  margin-top: 30px;
}
.CommonNewForm-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 44px;
  font-weight: 500;
  margin: 50px 0 32px;
}
@media (max-width: 1199px) {
  .CommonNewForm-title {
    font-size: 28px;
    margin: 32px 0 16px;
    padding: 0;
  }
}
.CommonNewForm-text {
  font-size: 20px;
  font-weight: 400;
  line-height: calc(32 / 20);
  margin-top: 48px;
  max-width: 90%;
}
@media (max-width: 1199px) {
  .CommonNewForm-text {
    font-size: 18px;
    line-height: calc(22 / 18);
    margin: 16px 0 20px;
    padding: 0;
  }
}
.CommonNewForm-fieldRow {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .CommonNewForm-fieldRow {
    display: block;
  }
}
.CommonNewForm-fieldGroup {
  margin: 0 0 20px;
}
.CommonNewForm-fieldGroup--agree {
  margin-top: 48px;
}
.CommonNewForm-label {
  color: #1c1630;
  display: block;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  margin: 0 0 5px;
}
@media (max-width: 1199px) {
  .CommonNewForm-label {
    font-size: 18px;
    line-height: calc(22 / 18);
    margin: 0 0 8px;
  }
}
.CommonNewForm-field {
  background: #eef0f6;
  border-radius: 2px;
  border: 1px solid #eef0f6;
  color: #1c1630;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  outline: 0;
  padding: 0 18px;
  height: 40px;
  width: 100%;
}
@media (max-width: 1199px) {
  .CommonNewForm-field {
    font-size: 18px;
    height: 32px;
  }
}
.CommonNewForm-field[disabled] {
  opacity: 0.6;
}
.CommonNewForm-field::-webkit-input-placeholder {
  color: #94949d;
}
.CommonNewForm-field::-ms-input-placeholder {
  color: #94949d;
}
.CommonNewForm-field::placeholder {
  color: #94949d;
}
.CommonNewForm-field.isInvalid {
  border-color: #e61010;
}
.CommonNewForm-field--textarea {
  padding: 12px 18px;
  min-height: 110px;
  max-height: 330px;
  resize: vertical;
}
@media (max-width: 1199px) {
  .CommonNewForm-field--textarea {
    min-height: 94px;
  }
}
.CommonNewForm-fieldGroupCheckbox {
  font-size: 20px;
  line-height: 32px;
  margin-top: 5px;
}
@media (max-width: 1199px) {
  .CommonNewForm-fieldGroupCheckbox {
    font-size: 18px;
    line-height: 22px;
  }
}
.CommonNewForm-selectWrapper {
  position: relative;
}
.CommonNewForm-selectWrapper select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  visibility: hidden;
  position: absolute;
  left: -999px;
}
.CommonNewForm-customSelectWrapper {
  position: relative;
}
.CommonNewForm-customSelectWrapper.is-open .CommonNewForm-customSelectValue::after {
  transform: translate(0, -50%) rotate(180deg);
}
.CommonNewForm-customSelectWrapper.is-open .CommonNewForm-customSelectList {
  display: block;
}
.CommonNewForm-customSelectValue {
  cursor: default;
  position: relative;
  height: 40px;
  line-height: 40px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 1199px) {
  .CommonNewForm-customSelectValue {
    font-size: 18px;
    line-height: 32px;
    height: 32px;
  }
}
.CommonNewForm-customSelectValue::after {
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75 5L8.75 11L14.75 5' stroke='%231D2130' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: '';
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translate(0, -50%);
  height: 16px;
  width: 17px;
  transition: transform 0.35s ease;
  z-index: 1;
}
.CommonNewForm-customSelectList {
  -webkit-filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.07));
          filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.07));
  display: none;
  list-style: none;
  margin: 0;
  max-height: 220px;
  overflow: auto;
  padding: 0;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  z-index: 10;
}
@media (max-width: 1199px) {
  .CommonNewForm-customSelectList {
    top: 37px;
  }
}
.CommonNewForm-customSelectList li {
  background: #eef0f6;
  border-bottom: 1px solid #c9c9ce;
  display: block;
}
.CommonNewForm-customSelectList li:last-child {
  border-bottom: 0;
}
.CommonNewForm-customSelectList button {
  border: none;
  background: #eef0f6;
  color: #1c1630;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  outline: none;
  overflow: hidden;
  padding: 0 18px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  transition: color 0.35s ease;
}
.CommonNewForm-customSelectList button:hover {
  color: #cc001b;
}
@media (max-width: 1199px) {
  .CommonNewForm-customSelectList button {
    font-size: 18px;
    line-height: 32px;
    height: 32px;
  }
}
.CommonNewForm-files {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.CommonNewForm-filesItem:not(:last-child) {
  margin-bottom: 16px;
}
.CommonNewForm-file {
  background: #edeef2;
  border: 1px solid #edeef2;
  color: #1c1630;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 21px;
  line-height: calc(32 / 21);
  padding: 25px 20px 25px 60px;
  text-decoration: none;
  min-height: 110px;
  width: 100%;
}
.CommonNewForm-file.isInvalid {
  border-color: #e61010;
}
@media (max-width: 1199px) {
  .CommonNewForm-file {
    font-size: 16px;
    padding: 20px;
    min-height: 102px;
  }
}
.CommonNewForm-fileTitle {
  margin-right: 60px;
  max-width: 80%;
}
@media (max-width: 1199px) {
  .CommonNewForm-fileTitle {
    margin-right: 20px;
  }
}
.CommonNewForm-fileTitleNote {
  display: block;
  font-weight: normal;
}
.CommonNewForm-fileExt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  font-weight: 700;
  letter-spacing: 0.15em;
  min-width: 120px;
}
.CommonNewForm-fileExt svg {
  display: block;
  fill: #1c1630;
  margin: 2px auto;
}
@media (max-width: 1199px) {
  .CommonNewForm-fileExt svg {
    margin: 0;
  }
}
@media (max-width: 1199px) {
  .CommonNewForm-fileExt {
    margin-right: -24px;
    min-width: initial;
  }
}
@media (max-width: 1199px) {
  .CommonNewForm-fileExtName {
    margin-bottom: 14px;
    transform: rotate(-90deg);
  }
}
.CommonNewForm-fieldFile {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.CommonNewForm-fileUploadWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  min-height: 45px;
  min-width: 120px;
}
@media (max-width: 1199px) {
  .CommonNewForm-fileUploadWrapper {
    justify-content: flex-end;
    min-width: 114px;
  }
}
.CommonNewForm-fileUploadBtn {
  background: #100c1a;
  border-radius: 0 2px 2px 0;
  color: #fff;
  line-height: 45px;
  padding: 0 30px;
  height: 45px;
  min-width: 168px;
}
@media (max-width: 1199px) {
  .CommonNewForm-fileUploadBtn {
    padding: 0 20px;
    min-width: 114px;
  }
}
.CommonNewForm-fileUploadSuccessIcon {
  display: none;
}
.CommonNewForm-uploadedFiles:empty {
  display: none;
}
.CommonNewForm-uploadedFilesList {
  list-style: none;
  margin: 0;
  padding: 4px 70px 4px 60px;
}
@media (max-width: 1199px) {
  .CommonNewForm-uploadedFilesList {
    padding: 4px 0;
  }
}
.CommonNewForm-uploadedFilesList:empty {
  display: none;
}
.CommonNewForm-uploadedFilesListItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}
.CommonNewForm-uploadedFilesListItem:not(:last-child) {
  border-bottom: 1px solid #ebebf6;
}
.CommonNewForm-uploadedFilesListItemName {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 26px;
  padding: 0 34px;
  position: relative;
  max-width: calc(100% - 34px - 48px);
}
.CommonNewForm-uploadedFilesListItemName span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CommonNewForm-uploadedFilesListItemName::before {
  content: '';
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='26' fill='none'%3E%3Cpath fill='%23E60F2D' d='M5.5 8a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11ZM5 13.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5ZM5.5 18a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11Z'/%3E%3Cpath fill='%23E60F2D' fill-rule='evenodd' d='m22 5.5-3-3L16 0H2a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V5.5ZM2 1h13.5v2.5A2.5 2.5 0 0 0 18 6h3v18a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm16.325 2.24L20.085 5H18a1.5 1.5 0 0 1-1.5-1.5V1.718l1.825 1.521Z' clip-rule='evenodd'/%3E%3C/svg%3E") no-repeat 0 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 22px;
}
.CommonNewForm-uploadedFilesListItemButton {
  cursor: pointer;
  flex-shrink: 0;
  margin-left: auto;
}
.CommonNewForm-uploadedFilesListItemButton svg {
  fill: currentColor;
}
.CommonNewForm-uploadedFilesListItemButton:hover svg {
  fill: #e6102d;
}
.CommonNewForm-checkbox {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.CommonNewForm-checkboxLabel {
  display: inline-block;
  padding-left: 48px;
  position: relative;
  line-height: 32px;
}
.CommonNewForm-checkboxLabel a {
  color: #e6102d;
  transition: color 0.3s ease;
}
.CommonNewForm-checkboxLabel a:hover {
  color: #cc001b;
}
@media (max-width: 1199px) {
  .CommonNewForm-checkboxLabel {
    font-size: 18px;
    line-height: 22px;
    min-height: 30px;
    padding-top: 4px;
  }
}
.CommonNewForm-checkbox + .CommonNewForm-checkboxLabel::before {
  background: #edeef2;
  border: 1px solid #edeef2;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
}
.isInvalid .CommonNewForm-checkbox + .CommonNewForm-checkboxLabel::before {
  border: 1px solid #e61010;
}
.CommonNewForm-checkbox:checked + .CommonNewForm-checkboxLabel::before {
  background: #edeef2 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='12' fill='none'%3E%3Crect width='9.471' height='1' x='.715' y='4.002' fill='%23000' rx='.5' transform='rotate(45 .715 4.002)'/%3E%3Crect width='15.071' height='1' fill='%23000' rx='.5' transform='scale(-1 1) rotate(45 -8.45 -20.257)'/%3E%3C/svg%3E") no-repeat 50% 50%;
}
.CommonNewForm-btnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 48px;
}
.CommonNewForm-btnWrapper--addButtons {
  margin-top: 0;
}
@media (max-width: 1199px) {
  .CommonNewForm-btnWrapper {
    flex-direction: column;
    margin-top: 32px;
  }
}
@media (max-width: 1199px) {
  .CommonNewForm-btnWrapper .Button {
    width: 100%;
  }
}
.CommonNewForm-btn {
  border-radius: 0 2px 2px 0;
  line-height: 45px;
  padding: 0 30px;
  height: 45px;
  min-width: 168px;
}
.CommonNewForm-btn[disabled] {
  background: #f59fab;
  cursor: default;
}
@media (max-width: 1199px) {
  .CommonNewForm-btn {
    height: 45px;
    width: 100%;
  }
}
.CommonNewForm-message {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
}
.CommonNewForm-message--error {
  color: #e61010;
}
.CommonNewForm-message--thanks {
  font-size: 18px;
  font-weight: 500;
  line-height: calc(22 / 18);
}
