.BrandsGrid {
  padding-bottom: 100px;
  opacity: 0;
}
.BrandsGrid--brand {
  padding-bottom: 0;
}
.BrandsGrid.isReady {
  opacity: 1;
}
.BrandsGrid-sizer {
  width: 20%;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .BrandsGrid-sizer {
    width: 25%;
  }
}
@media (max-width: 1199px) {
  .BrandsGrid-sizer {
    width: 25%;
  }
}
.BrandsGrid--brand .BrandsGrid-sizer {
  width: 20%;
}
.BrandsGrid-item {
  position: relative;
  height: 342px;
  width: 20%;
  background: #fff;
  transition: z-index 0.3s ease;
  z-index: 1;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .BrandsGrid-item {
    height: 314px;
    width: 25%;
  }
}
@media (max-width: 1199px) {
  .BrandsGrid-item {
    height: 308px;
    width: 25%;
  }
}
.BrandsGrid-item--isBig {
  height: 684px;
  width: 40%;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .BrandsGrid-item--isBig {
    height: 628px;
    width: 50%;
  }
}
@media (max-width: 1199px) {
  .BrandsGrid-item--isBig {
    height: 616px;
    width: 50%;
  }
}
.BrandsGrid-item--isStamp {
  position: absolute;
  top: 0;
  right: 0;
}
.BrandsGrid-item:after {
  content: '';
  position: absolute;
  top: -1px;
  bottom: 0;
  left: -1px;
  right: 0;
  border: 1px solid #edeef2;
  transition: border-color 0.3s ease;
  z-index: 1;
}
.BrandsGrid-item[data-last-column='true'].BrandsGrid-item:after {
  right: 0;
}
.BrandsGrid-item:hover,
.BrandsGrid-item:focus {
  z-index: 2;
}
.BrandsGrid-item:hover:after,
.BrandsGrid-item:focus:after {
  border-color: #e6102d;
}
.BrandsGrid--brand .BrandsGrid-item {
  height: 336px;
  width: 20%;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .BrandsGrid--brand .BrandsGrid-item {
    height: 314px;
    width: 20%;
  }
}
@media (max-width: 1199px) {
  .BrandsGrid--brand .BrandsGrid-item {
    height: 308px;
    width: 20%;
  }
}
.BrandsGrid-itemTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: .1px;
  color: #2e2e33;
  display: block;
  margin-bottom: 4px;
  z-index: 2;
}
.BrandsGrid-itemStyle {
  font-size: 12px;
  color: #7a7a7a;
  display: block;
  z-index: 2;
}
.BrandsGrid-itemContent {
  height: 100%;
  width: 100%;
  table-layout: fixed;
}
.BrandsGrid-itemContent td {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}
.BrandsGrid-itemContent tr:last-child td {
  padding-bottom: 20px;
}
.BrandsGrid-itemImgContainer {
  height: 100%;
}
.BrandsGrid-itemImg {
  display: block;
  position: absolute;
  height: 81%;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: auto;
  margin-bottom: 10px;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  z-index: 1;
}
.BrandsGrid-item--isBig .BrandsGrid-itemImg {
  height: 84%;
  margin-bottom: 20px;
}
.BrandsGrid-itemBadge {
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #fff;
  position: absolute;
  height: 21px;
  top: 20px;
  right: 10px;
  padding: 0 5px;
  background: #ccd6dd;
  border-radius: 2px;
}
.BrandsGrid-loader {
  position: absolute;
  height: 10px;
  width: 50px;
  bottom: 45px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}
.BrandsGrid-loader.isActive {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s linear 0s;
}
.BrandsGrid-loaderDot {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 0;
  background: #e6102d;
  border-radius: 50%;
}
.BrandsGrid-loaderDot:nth-child(1) {
  left: 0;
  animation: BrandsGrid-dotAnimation 0.75s linear 0s infinite alternate;
}
.BrandsGrid-loaderDot:nth-child(2) {
  left: 20px;
  animation: BrandsGrid-dotAnimation 0.75s linear 0.25s infinite alternate;
}
.BrandsGrid-loaderDot:nth-child(3) {
  left: 40px;
  animation: BrandsGrid-dotAnimation 0.75s linear 0.5s infinite alternate;
}
@keyframes BrandsGrid-dotAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: .33;
  }
}
