.Content {
  position: relative;
  background: #fafafc;
}
.Content:before {
  content: '';
  position: absolute;
  min-height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 8px solid #e6102d;
  z-index: 14;
}
.Content:before .PostPage,
.Content:before .LocationsPage,
.ExcursionPage .Content:before {
  display: none;
}
.Content--scalingOut .Content-main {
  transform: scale3d(0.8, 0.8, 1);
}
.Content--scalingOut .Content-overlay {
  opacity: 1;
}
.Content-main {
  min-height: 100vh;
  transition: transform 0.7s ease;
}
.Content-main .PostPage,
.Content-main .LocationsPage,
.ExcursionPage .Content-main {
  display: none;
}
.Content-topNav--index {
  position: relative;
}
.Content-topNav .PostPage,
.Content-topNav .LocationsPage,
.ExcursionPage .Content-topNav {
  display: none;
}
.Content-body {
  margin-bottom: -368px;
  min-height: 100vh;
  width: 100%;
}
.Content-body--no-footer {
  margin-bottom: auto;
}
.Content-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(28, 22, 48, 0.8);
  transition: opacity 0.7s ease;
  z-index: 21;
  pointer-events: none;
}
.Content-push,
.Content-footer {
  height: 368px;
}
