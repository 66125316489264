.Legal {
  display: flex;
  height: 100%;
  padding-bottom: 20px;
  background: #f7f8fc;
}
.Legal-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.Legal-backgroundImage {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .3;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(rgba(0, 0, 0, 0)));
          mask-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(rgba(0, 0, 0, 0)));
}
.Legal-left {
  flex: auto;
  padding-top: 60px;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Legal-left {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Legal-left {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.Legal-right {
  flex: auto;
  background: transparent;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 10 + 9 * 48px + 0.5 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Legal-right {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 10 + 9 * 32px + 0.5 * 32px + 1 * 48px) + 0px);
  }
}
.Legal-header {
  color: #e6102d;
  margin-bottom: 48px;
  padding-top: 200px;
  padding-left: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
  margin-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Legal-header {
    padding-left: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Legal-header {
    margin-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.Legal-subtitle {
  display: flex;
  margin-bottom: 24px;
}
.Legal-subtitleStroke {
  width: 100px;
  margin-right: 24px;
  border-bottom: 1px solid #e6102d;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Legal-subtitleStroke {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 24px);
  }
}
.Legal-subtitleText {
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  line-height: 8px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #e6102d;
}
.Legal-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #0f0c1a;
  margin-bottom: 18px;
  padding-right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Legal-title {
    padding-right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Legal-content {
  padding-left: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 1 + 0 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
  margin-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  margin-bottom: 140px;
}
@media (max-width: 1199px) {
  .Legal-content {
    padding-left: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 1 + 0 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Legal-content {
    margin-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.Legal-text {
  padding-right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  font-size: 20px;
  line-height: 32px;
  color: #0f0c1a;
}
@media (max-width: 1199px) {
  .Legal-text {
    padding-right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Legal-text {
    font-size: 16px;
    line-height: 25px;
  }
}
.Legal-text .Post-pdf {
  margin-left: 0;
  width: auto;
}
.Legal-text p {
  margin-bottom: 30px;
}
.Legal-text a {
  color: #e6102d;
}
.Legal-text figure {
  position: relative;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.Legal-text figure .imageContainer {
  position: relative;
}
.Legal-text figure img {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Legal-text figure figcaption {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-top: 26px;
  padding-bottom: 20px;
  background: #fff;
  padding-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
  left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 6 + 5 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Legal-text figure figcaption {
    padding-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Legal-text figure figcaption {
    padding-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Legal-text figure figcaption {
    left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Legal-text figure figcaption {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 6 + 5 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Legal-form {
  color: #0f0c1a;
  font-size: 20px;
  line-height: 32px;
  padding-right: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 2 + 1 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Legal-form {
    padding-right: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 2 + 1 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
