.SubscribeSections {
  position: fixed;
  height: 366px;
  width: 520px;
  bottom: 0;
  left: 50%;
  padding-top: 30px;
  padding-bottom: 31px;
  padding-left: 40px;
  padding-right: 40px;
  opacity: 0;
  background: #fff;
  transition: transform 0.7s ease, opacity 0.7s ease;
  transform: translateY(100%) translateX(-50%);
  z-index: 20;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.SubscribeSections.isActive {
  opacity: 1;
  transform: translateY(0) translateX(-50%);
}
.SubscribeSections-close {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 18px;
  right: 17px;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.SubscribeSections-close:hover,
.SubscribeSections-close:focus {
  opacity: .5;
}
.SubscribeSections-close svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  stroke: #919199;
}
.SubscribeSections-header {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 45px;
  color: #1c1630;
}
.SubscribeSections-inputList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 125px;
  margin-top: 56px;
  margin-bottom: 29px;
  padding-left: 1px;
}
.SubscribeSections-input {
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: 22px;
}
.SubscribeSections-input:nth-child(1),
.SubscribeSections-input:nth-child(2),
.SubscribeSections-input:nth-child(3) {
  width: 50%;
  margin-right: 21px;
}
.SubscribeSections-input input {
  position: relative;
  height: 16px;
  width: 16px;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.SubscribeSections-input label {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 15px;
  color: #333;
  margin-left: 12px;
  cursor: pointer;
}
.SubscribeSections-inputCheckbox {
  display: inline-block;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 0;
  left: 0;
  border: 1px solid #e3e3e6;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  z-index: 1;
  border-radius: 2px;
}
.SubscribeSections-inputCheckbox svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 14px;
  width: 18px;
  bottom: 5px;
  left: 1px;
  opacity: 0;
  stroke: #e6102d;
  transition: opacity 0.3s ease;
}
input:checked + .SubscribeSections-inputCheckbox {
  background-color: #fff2f4;
  border-color: #ff8090;
}
input:checked + .SubscribeSections-inputCheckbox svg {
  opacity: 1;
}
.SubscribeSections-message {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  display: flex;
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.SubscribeSections-submit .Button[disabled] {
  background: #909090;
  cursor: default;
}
