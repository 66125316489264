.MediaSubscribe {
  margin: 38px;
}
.MediaSubscribe-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #1c1630;
  margin: 26px 0 32px;
}
@media (max-width: 1199px) {
  .MediaSubscribe-title {
    font-size: 28px;
    line-height: 34px;
    margin: 18px 0 24px;
  }
}
.MediaSubscribe-text {
  color: #1c1630;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin: 0 0 32px;
  max-width: 876px;
}
@media (max-width: 1199px) {
  .MediaSubscribe-text {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 24px;
  }
}
.MediaSubscribe-form {
  min-height: 62px;
  max-width: 494px;
}
.MediaSubscribe-form.isInvalid .MediaSubscribe-message--error {
  display: block;
}
.MediaSubscribe-form.isSended .MediaSubscribe-fieldGroup {
  display: none;
}
.MediaSubscribe-form.isSended .MediaSubscribe-message--thanks {
  display: block;
}
.MediaSubscribe-fieldGroup {
  display: flex;
  flex-direction: row;
  margin: 0 0 5px;
  height: 42px;
}
.MediaSubscribe-field {
  background: #eef0f6;
  border-radius: 2px 0 0 2px;
  border: 0;
  color: #1c1630;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  outline: 0;
  padding: 0 18px;
  height: 42px;
  width: 100%;
}
@media (max-width: 1199px) {
  .MediaSubscribe-field {
    font-size: 18px;
  }
}
.MediaSubscribe-field::-webkit-input-placeholder {
  color: #94949d;
}
.MediaSubscribe-field::-ms-input-placeholder {
  color: #94949d;
}
.MediaSubscribe-field::placeholder {
  color: #94949d;
}
.MediaSubscribe-btn {
  border-radius: 0 2px 2px 0;
  line-height: 42px;
  padding: 0 30px;
  height: 100%;
  min-width: 140px;
}
.MediaSubscribe-btn[disabled] {
  background: #f59fab;
  cursor: default;
}
.MediaSubscribe-message {
  display: none;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
}
.MediaSubscribe-message--error {
  color: #e61010;
}
.MediaSubscribe-message--thanks {
  font-size: 18px;
  font-weight: 500;
  line-height: calc(22 / 18);
}
