.People {
  display: flex;
  position: relative;
  height: 100%;
}
.People-background {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #0f0c1a;
}
.People-backgroundImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: .3;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -webkit-mask-image: linear-gradient(black, transparent);
          mask-image: linear-gradient(black, transparent);
}
.People-left {
  flex: auto;
  padding-top: 60px;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-left {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .People-left {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.People-right {
  flex: 0 1 auto;
  margin-bottom: 78px;
  background: transparent;
  z-index: 1;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 10 + 9 * 48px + 0.5 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-right {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 10 + 9 * 32px + 0.5 * 32px + 1 * 48px) + 0px);
  }
}
.People-header {
  color: #e6102d;
  margin-bottom: 128px;
  padding-top: 200px;
  padding-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 1 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  margin-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-header {
    padding-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .People-header {
    margin-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.People-subtitle {
  display: flex;
  margin-bottom: 48px;
}
.People-subtitleStroke {
  margin-right: 22px;
  border-bottom: 1px solid #e6102d;
  width: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 14px);
}
@media (max-width: 1199px) {
  .People-subtitleStroke {
    width: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 14px);
  }
}
.People-subtitleText {
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  line-height: 8px;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.People-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #f7f8fc;
  padding-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 2 + 1 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-description {
    padding-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 2 + 1 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .People-description {
    font-size: 50px;
  }
}
.People-grid {
  display: block;
  font-size: 0;
  margin-left: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  margin-right: calc(((100% - (10 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-grid {
    margin-left: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .People-grid {
    margin-right: calc(((100% - (10 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
.People-item {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 50%;
  padding-top: 31.5%;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
.People-item-inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  right: 0;
  overflow: hidden;
  right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-item-inner {
    right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .People-item {
    margin-bottom: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.People-item:nth-child(odd) {
  padding-right: calc(((100% - (10 - 1) * 48px - 0 * 48px - 0 * 48px) / 10 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-item:nth-child(odd) {
    padding-right: calc(((100% - (10 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 10 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.People-item:last-child,
.People-item:nth-last-child(2) {
  margin-bottom: 0;
}
.People-item:hover .People-image {
  transition: transform 0.3s ease;
  transform: scale(1.1);
}
.People-item:hover .People-text {
  transition: background-color 0.3s ease, min-width 0.3s ease;
}
.People-item:hover .People-textWrapper {
  min-width: 100%;
  background: #e6102d;
  transition: min-width 0.3s ease, padding 0.3s ease, background-color 0.3s ease;
}
.People-item:hover .People-name {
  color: #f7f8fc;
  transform: translateY(-13px);
}
.People-item:hover .People-position {
  color: #f7f8fc;
  opacity: 0;
  transition: opacity 0.3s ease, height 0.3s ease 0.6s, color 0.3s ease;
}
.People-item:hover .People-positionDescription {
  max-height: 200px;
  opacity: 1;
  transition: opacity 0.3s ease 0.15s, transform 0.3s ease;
  transform: translateY(0%);
}
.People-image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
  transition: transform 0.3s ease;
}
.People-text {
  color: #1c1630;
  display: flex;
  position: absolute;
  min-height: 0;
  min-width: 0;
  bottom: 0;
  left: 0;
  transition: color 0.3s ease, background-color 0.3s ease, min-width 0.3s ease;
  width: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 5 * 4 + 3 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-text {
    width: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 5 * 4 + 3 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
.People-textWrapper {
  min-width: 0;
  background: #f7f8fc;
  transition: min-width 0.6s ease 0.075s, padding 0.3s ease 0.075s, background-color 0.3s ease;
}
.People-name {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
  padding-top: 28px;
  padding-right: 22px;
  transition: color 0.3s ease, transform 0.3s ease;
  padding-left: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 5 * 0 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-name {
    padding-left: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 5 * 0 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .People-name {
    font-size: 20px;
  }
}
.People-position {
  font-size: 14px;
  line-height: 12px;
  padding-bottom: 21px;
  transition: color 0.3s ease, opacity 0.3s ease, height 0.3s ease 0.6s;
  padding-left: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 5 * 0 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 5 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-position {
    padding-left: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 5 * 0 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .People-position {
    padding-right: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 5 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.People-positionDescription {
  font-size: 20px;
  line-height: 26px;
  color: #f7f8fc;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 12px;
  padding-bottom: 11px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.6s ease;
  transform: translateY(100%);
  padding-left: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 5 * 0 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (5 - 1) * 48px - 0 * 48px - 0 * 48px) / 5 * 0 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .People-positionDescription {
    padding-left: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 5 * 0 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .People-positionDescription {
    padding-right: calc(((100% - (5 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 5 * 0 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
