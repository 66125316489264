.CareerVacancyCard {
  color: #1c1630;
}
.CareerVacancyCard-link {
  background: #fceded;
  display: block;
  color: #1c1630;
  padding: 20px;
  text-decoration: none;
  transition: background-color 0.35s ease;
}
.CareerVacancyCard-link:hover {
  background: #e6c7c5;
}
.CareerVacancyCard-title {
  font-size: 20px;
  font-weight: 600;
  line-height: calc(32 / 20);
  margin: 0 0 48px;
}
.CareerVacancyCard-info {
  font-weight: 400;
  font-size: 15px;
  line-height: calc(30 / 15);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.CareerVacancyCard-linkMore {
  font-weight: 500;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
