.ButtonIcon {
  position: relative;
  height: 80px;
  width: 80px;
  background-color: #e6102d;
  border: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.ButtonIcon svg {
  position: absolute;
  height: 28px;
  width: 28px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  stroke: #fff;
  pointer-events: none;
}
.ButtonIcon:hover,
.ButtonIcon:focus {
  background-color: #cc001b;
}
