.MenuLeft {
  height: 100%;
  padding-top: 228px;
}
.MenuLeft-item {
  font-size: 15px;
  font-weight: 500;
  line-height: 47px;
  color: #f7f8fc;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: color 0.3s ease;
}
.MenuLeft-item:hover {
  color: #e6102d;
  transition: color 0.3s ease;
  cursor: pointer;
}
.MenuLeft-item.isActive {
  color: #e6102d;
}
.MenuLeft--about .MenuLeft-item,
.MenuLeft--history .MenuLeft-item,
.MenuLeft--principles .MenuLeft-item {
  color: #0f0c1a;
  border-bottom: 1px solid #ebebf6;
}
.MenuLeft--about .MenuLeft-item:hover,
.MenuLeft--history .MenuLeft-item:hover,
.MenuLeft--principles .MenuLeft-item:hover {
  color: #e6102d;
  transition: color 0.3s ease;
  cursor: pointer;
}
.MenuLeft--about .MenuLeft-item.isActive,
.MenuLeft--history .MenuLeft-item.isActive,
.MenuLeft--principles .MenuLeft-item.isActive {
  color: #e6102d;
}
