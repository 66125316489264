.TagList {
  position: relative;
  margin-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .TagList {
    margin-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.TagList:after {
  content: '';
  position: absolute;
  width: 80px;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
  z-index: 1;
  pointer-events: none;
}
.TagList-list {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .TagList-list {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.TagList-list .StyleTag + .StyleTag {
  margin-left: 12px;
}
.TagList-more {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 19;
}
.TagList-more:hover .TagList-stylesTooltip,
.TagList-more:focus .TagList-stylesTooltip {
  visibility: visible;
  transition: visibility 0.3s ease;
}
.TagList-more:hover .TagList-tooltipOverlay,
.TagList-more:focus .TagList-tooltipOverlay {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.TagList-more:hover .TagList-tooltip,
.TagList-more:focus .TagList-tooltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.TagList-more:hover .TagList-tooltipContent,
.TagList-more:focus .TagList-tooltipContent {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.TagList-button {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 23px;
  right: 0;
  background: #1c1630;
  border: none;
  fill: #fff;
  cursor: pointer;
  z-index: 4;
  border-radius: 50%;
}
.TagList-button svg {
  position: absolute;
  height: 4px;
  width: 16px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}
.TagList-tooltip {
  visibility: hidden;
  position: absolute;
  top: 55px;
  right: 0;
  transition: visibility 0s ease 0.3s;
}
.TagList-tooltip:before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  top: -6px;
  right: 6px;
  z-index: 5;
  border-style: solid;
  border-width: 0 5.5px 6px 5.5px;
  border-color: transparent transparent #fff transparent;
}
.TagList-tooltipOverlay {
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(15, 12, 26, 0.2);
  transition: opacity 0.3s ease, visibility 0s ease 0.3s;
  z-index: 3;
  pointer-events: none;
}
.TagList-tooltipContent {
  visibility: hidden;
  position: relative;
  min-width: 520px;
  width: 90vw;
  padding: 20px;
  padding-bottom: 0;
  opacity: 0;
  background: #fff;
  transition: opacity 0.3s ease, visibility 0s ease 0.3s;
  z-index: 4;
  border-radius: 2px;
}
.TagList-tooltipContent:before {
  content: '';
  position: absolute;
  height: 10px;
  bottom: 100%;
  left: 0;
  right: 0;
}
.TagList-tooltipContent .StyleTag {
  margin-bottom: 20px;
  margin-right: 20px;
}
