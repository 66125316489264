.History {
  display: flex;
  position: relative;
  height: 100%;
  padding-bottom: 171px;
  background: #f7f8fc;
}
.History-left {
  flex-shrink: 0;
  padding-top: 60px;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 2 + 1 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .History-left {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 2 + 1 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .History-left {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.History-right {
  background: transparent;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 9 + 8 * 48px + 1 * 48px + 1 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .History-right {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 9 + 8 * 32px + 1 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .History-right {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .History-right {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.History-header {
  color: #e6102d;
  margin-bottom: 11px;
  padding-top: 200px;
}
.History-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  line-height: 8px;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 17px;
  padding-left: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .History-title {
    padding-left: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.History-title:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  margin-right: 22px;
  border-bottom: 1px solid #e6102d;
  width: calc(((100% - (9 - 1) * 48px - 0 * 48px - 0 * 48px) / 9 * 1 + 0 * 48px + 0.5 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .History-title:before {
    width: calc(((100% - (9 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 9 * 1 + 0 * 32px + 0.5 * 32px + 0 * 48px) + 0px);
  }
}
.History-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 52px;
  font-weight: 500;
  line-height: 64px;
  color: #1c1630;
}
.History-listBlock:first-child {
  margin-top: 0;
}
.History-listBlock + .History-listBlock {
  margin-top: -130px;
}
.History-years {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  flex-direction: column;
  justify-content: space-between;
  max-height: 503px;
  top: 140px;
  bottom: 100px;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .History-years {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .History-years {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-height: 660px) {
  .History-years {
    max-height: 400px;
  }
}
@media (max-height: 550px) {
  .History-years {
    max-height: 300px;
  }
}
.History-yearLink {
  font-size: 18px;
  font-weight: 500;
  text-align: right;
  color: #ccd6dd;
  display: block;
  padding-right: 24px;
  transition: color 0.3s ease, transform 0.3s ease;
  transform-origin: center center;
  font-variant: tabular-nums;
}
.History-yearLink:hover {
  color: #e6102d;
}
.History-yearLink.isActive {
  color: #222;
  transform: scaleX(1.111) scaleY(1.111);
  pointer-events: none;
}
.History-year {
  font-size: 200px;
  font-weight: 600;
  color: #1c1630;
  position: relative;
  margin-left: 1px;
  padding-top: 130px;
}
.History-year span {
  -webkit-text-fill-color: #fafafc;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #1c1630;
}
.History-year:after {
  content: attr(data-year);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.History-year.isActive:after {
  opacity: 1;
}
.History-news {
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  padding-bottom: 130px;
}
.History-launchBlock {
  display: flex;
}
@media (max-width: 1199px), (min-width: 1200px) and (max-width: 1439px) {
  .History-launchBlock {
    display: block;
  }
}
.History-launchBlock .History-year {
  padding-top: 0;
}
.History-launch {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  padding-top: 40px;
  padding-left: 60px;
  padding-right: 40px;
}
@media (max-width: 1199px) {
  .History-launch {
    font-size: 20px;
    line-height: 26px;
  }
}
@media (max-width: 1199px) {
  .History-launch {
    width: 75%;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .History-launch {
    width: 75%;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
