.Career {
  height: 100%;
}
@media (max-width: 767px) {
  .Career {
    background: #fafafc;
    padding-top: 50px;
  }
}
.Career-main {
  background: #fff;
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Career-main {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Career-main {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 767px) {
  .Career-main {
    background: #fafafc;
    padding: 15px;
  }
}
.Career-bg {
  display: block;
  position: relative;
  overflow: hidden;
  height: 535px;
}
.Career-bg::before {
  background: rgba(28, 22, 48, 0.5);
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
@media (max-width: 767px) {
  .Career-bg {
    height: 330px;
  }
}
.Career-bgImage {
  font-family: 'object-fit: cover;';
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.Career-header {
  background: #fff;
  position: relative;
  margin-top: -264px;
  min-height: 264px;
  z-index: 2;
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-top: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Career-header {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Career-header {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Career-header {
    padding-top: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 767px) {
  .Career-header {
    background: #fafafc;
    margin: 0 0 50px;
    min-height: unset;
    padding: 17px 0 0;
    z-index: 1;
  }
}
.Career-subtitle {
  display: flex;
  margin-bottom: 48px;
}
@media (max-width: 767px) {
  .Career-subtitle {
    margin-bottom: 16px;
  }
}
.Career-subtitleStroke {
  width: 100px;
  margin-right: 24px;
  border-bottom: 1px solid #e6102d;
  width: calc(((100% - (7 - 1) * 48px - 0 * 48px - 0 * 48px) / 7 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 24px);
}
@media (max-width: 1199px) {
  .Career-subtitleStroke {
    width: calc(((100% - (7 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 7 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 24px);
  }
}
@media (max-width: 767px) {
  .Career-subtitleStroke {
    margin-right: 11px;
    width: 96px;
  }
}
.Career-subtitleText {
  font-size: 12px;
  font-weight: 500;
  line-height: 8px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #e6102d;
}
.Career-title {
  color: #0f0c1a;
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .Career-title {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 16px;
  }
}
.Career-text {
  color: #0f0c1a;
  font-size: 20px;
  line-height: calc(32 / 20);
}
@media (max-width: 767px) {
  .Career-text {
    font-size: 18px;
    line-height: calc(22 / 18);
  }
}
.Career-text p {
  margin-top: 32px;
}
@media (max-width: 767px) {
  .Career-text p {
    margin-top: 16px;
  }
}
.Career-container {
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  padding-bottom: 140px;
}
@media (max-width: 1199px) {
  .Career-container {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .Career-container {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 767px) {
  .Career-container {
    padding: 0 0 114px;
  }
}
.Career-section {
  color: #1c1630;
  margin-top: 90px;
}
.Career-section--vacancies {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .Career-section--vacancies {
    display: block;
  }
}
.Career-section--vacancy {
  margin-top: 48px;
}
@media (max-width: 767px) {
  .Career-section {
    margin-top: 48px;
  }
}
.Career-sectionTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 44px;
  font-weight: 500;
  margin: 0 0 32px;
}
@media (max-width: 767px) {
  .Career-sectionTitle {
    font-size: 28px;
    margin: 0 0 16px;
  }
}
.Career-sectionText {
  font-size: 20px;
  line-height: calc(32 / 20);
  margin: 0 0 32px;
}
@media (max-width: 767px) {
  .Career-sectionText {
    font-size: 18px;
    line-height: calc(22 / 18);
  }
}
.Career-sectionText b {
  font-weight: 500;
}
.Career-sectionText ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.Career-sectionText ul li {
  padding-left: 14px;
  position: relative;
}
.Career-sectionText ul li::before {
  background: #e60f2d;
  border-radius: 100%;
  content: "";
  position: absolute;
  left: 1px;
  top: 14px;
  height: 5px;
  width: 5px;
}
@media (max-width: 767px) {
  .Career-sectionText ul li::before {
    top: 8px;
  }
}
.Career-sectionText p + p {
  margin-top: 24px;
}
.Career-vacanciesList {
  padding-top: 32px;
  width: calc(100% - 250px - 20px);
}
.Career-vacanciesList .CareerVacancyCard {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .Career-vacanciesList {
    padding-top: 0;
    width: 100%;
  }
}
.CareerAdvantages {
  display: flex;
  font-family: 'Graphik LCG', Arial, sans-serif;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 20px 0;
}
@media (max-width: 767px) {
  .CareerAdvantages {
    display: flex;
    flex-direction: column;
    margin: 0 -15px;
  }
}
.CareerAdvantages-item {
  background: #dfdfdf;
  color: #1c1630;
  overflow: hidden;
  position: relative;
  width: calc(460 / 1152 * 100%);
}
.CareerAdvantages-item:nth-child(1) {
  background: #1c1630;
  color: #fff;
  width: calc(432 / 1152 * 100%);
}
.CareerAdvantages-item:nth-child(2) {
  width: calc(700 / 1152 * 100%);
}
.CareerAdvantages-item:nth-child(3) {
  background: #1c1630;
  color: #fff;
  width: calc(552 / 1152 * 100%);
}
.CareerAdvantages-item:nth-child(4) {
  background: #f1e5d4;
  width: calc(580 / 1152 * 100%);
}
.CareerAdvantages-item:nth-child(5) {
  background: #dfdfdf;
  width: calc(672 / 1152 * 100%);
}
.CareerAdvantages-item:nth-child(6) {
  background: #ccd6dd;
  width: calc(460 / 1152 * 100%);
}
@media (max-width: 767px) {
  .CareerAdvantages-item {
    display: block;
    width: 100% !important;
  }
}
.CareerAdvantages-itemTitle {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  padding: 25px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.CareerAdvantages-itemText {
  font-size: 20px;
  line-height: calc(32 / 20);
  padding: 25px;
}
.CareerAdvantages-itemText b {
  font-weight: 600;
}
.CareerAdvantages-itemImage {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}
.CareerAdvantages-itemImage img {
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.CareerAdvantageMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0 0;
}
@media (max-width: 767px) {
  .CareerAdvantageMain {
    display: block;
    margin: 20px -15px 0;
  }
}
.CareerAdvantageMain-text {
  background: #e60f2d;
  color: #fff;
  font-size: 20px;
  line-height: calc(32 / 20);
  padding: 25px;
  width: calc(432 / 1152 * 100%);
}
.CareerAdvantageMain-text b {
  font-weight: 600;
}
@media (max-width: 767px) {
  .CareerAdvantageMain-text {
    display: block;
    width: 100%;
  }
}
.CareerAdvantageMain-img {
  position: relative;
  width: calc(720 / 1152 * 100%);
}
@media (max-width: 767px) {
  .CareerAdvantageMain-img {
    width: 100%;
  }
}
.CareerAdvantageMain-img img {
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.CareerDirections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (max-width: 767px) {
  .CareerDirections {
    gap: 8px;
  }
}
@media (max-width: 767px) {
  .CareerDirections--main {
    flex-direction: column;
    gap: 8px;
  }
  .CareerDirections--main .CareerDirections-item {
    width: 100%;
  }
  .CareerDirections--main .CareerDirections-itemLink {
    aspect-ratio: unset;
    min-height: 145px;
  }
}
.CareerDirections--internships .CareerDirections-item {
  width: calc(20% - 20px);
}
@media (max-width: 767px) {
  .CareerDirections--internships .CareerDirections-item {
    flex-grow: 0;
    width: calc(50% - 8px);
  }
}
.CareerDirections-item {
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
  width: calc(25% - 20px);
}
@media (max-width: 767px) {
  .CareerDirections-item {
    flex-grow: 0;
    width: calc(50% - 8px);
  }
}
.CareerDirections-itemLink {
  aspect-ratio: 1.49797571;
  background: #1c1630;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  padding: 20px;
  position: relative;
  width: 100%;
  z-index: 1;
}
@media (max-width: 767px) {
  .CareerDirections-itemLink {
    aspect-ratio: 1.09032258;
    padding: 15px;
    min-height: 145px;
  }
}
.CareerDirections-itemLink::before {
  background: linear-gradient(180deg, rgba(28, 22, 48, 0) 0%, rgba(28, 22, 48, 0.8) 100%);
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 123px;
  z-index: 2;
}
@media (max-width: 767px) {
  .CareerDirections-itemLink::before {
    height: 70px;
  }
}
.CareerDirections-itemLink:hover .CareerDirections-itemBg {
  transform: scale(1.1);
}
.CareerDirections-itemBg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  transform: scale(1);
  transition: transform 0.35s ease;
  will-change: transform;
}
.CareerDirections-itemBg img {
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.CareerDirections-itemTitle {
  color: #fff;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 20px;
  line-height: calc(24 / 20);
  position: relative;
  z-index: 2;
}
.CareerDirections-itemNote {
  color: #1c1630;
  font-size: 20px;
  line-height: 32px;
  margin: 5px auto 0;
  text-align: center;
}
.CareerFilters {
  width: 250px;
}
@media (max-width: 767px) {
  .CareerFilters {
    display: none;
  }
}
.CareerFiltersForm {
  color: #0f0c1a;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .CareerFiltersForm {
    font-size: 16px;
    line-height: 25px;
    margin-top: 48px;
  }
}
.CareerFiltersForm p {
  margin-bottom: 16px;
}
.CareerFiltersForm p + p {
  margin-top: 30px;
}
.CareerFiltersForm-fieldGroup {
  margin: 0 0 20px;
}
.CareerFiltersForm-label {
  color: #1c1630;
  display: block;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  margin: 0 0 5px;
}
@media (max-width: 767px) {
  .CareerFiltersForm-label {
    font-size: 18px;
    line-height: calc(22 / 18);
    margin: 0 0 8px;
  }
}
.CareerFiltersForm-field {
  background: #eef0f6;
  border-radius: 2px;
  border: 1px solid #eef0f6;
  color: #1c1630;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  outline: 0;
  padding: 0 18px;
  height: 40px;
  width: 100%;
}
@media (max-width: 767px) {
  .CareerFiltersForm-field {
    font-size: 18px;
    height: 32px;
  }
}
.CareerFiltersForm-field::-webkit-input-placeholder {
  color: #94949d;
}
.CareerFiltersForm-field::-ms-input-placeholder {
  color: #94949d;
}
.CareerFiltersForm-field::placeholder {
  color: #94949d;
}
.CareerFiltersForm-field.isInvalid {
  border-color: #e61010;
}
.CareerFiltersForm-field--textarea {
  padding: 12px 18px;
  min-height: 110px;
  max-height: 330px;
  resize: vertical;
}
.CareerFiltersForm-field--select {
  font-size: 15px;
}
.CareerFiltersForm-selectWrapper {
  position: relative;
}
.CareerFiltersForm-selectWrapper select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  visibility: hidden;
  position: absolute;
  left: -999px;
}
.CareerFiltersForm-customSelectWrapper {
  position: relative;
}
.CareerFiltersForm-customSelectWrapper.is-open .LegalForm-customSelectValue::after {
  transform: translate(0, -50%) rotate(180deg);
}
.CareerFiltersForm-customSelectWrapper.is-open .LegalForm-customSelectList {
  display: block;
}
.CareerFiltersForm-customSelectValue {
  cursor: default;
  position: relative;
  height: 40px;
  line-height: 40px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 767px) {
  .CareerFiltersForm-customSelectValue {
    font-size: 18px;
    line-height: 32px;
    height: 32px;
  }
}
.CareerFiltersForm-customSelectValue::after {
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75 5L8.75 11L14.75 5' stroke='%231D2130' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: '';
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translate(0, -50%);
  height: 16px;
  width: 17px;
  transition: transform 0.35s ease;
  z-index: 1;
}
.CareerFiltersForm-customSelectList {
  -webkit-filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.07));
          filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.07));
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
}
@media (max-width: 767px) {
  .CareerFiltersForm-customSelectList {
    top: 37px;
  }
}
.CareerFiltersForm-customSelectList li {
  background: #eef0f6;
  border-bottom: 1px solid #c9c9ce;
  display: block;
}
.CareerFiltersForm-customSelectList li:last-child {
  border-bottom: 0;
}
.CareerFiltersForm-customSelectList button {
  border: none;
  background: #eef0f6;
  color: #1c1630;
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 18px;
  text-align: left;
  width: 100%;
  transition: color 0.35s ease;
}
.CareerFiltersForm-customSelectList button:hover {
  color: #cc001b;
}
@media (max-width: 767px) {
  .CareerFiltersForm-customSelectList button {
    font-size: 18px;
    line-height: 32px;
    height: 32px;
  }
}
.CareerFiltersForm-text {
  margin-top: 48px;
}
.CareerFiltersForm-checkboxList {
  list-style: none;
  margin: 0;
  padding: 0;
}
.CareerFiltersForm-checkboxList li:not(:last-child) {
  margin-bottom: 8px;
}
.CareerFiltersForm-checkbox {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.CareerFiltersForm-checkboxLabel {
  display: inline-block;
  padding-left: 48px;
  position: relative;
}
.CareerFiltersForm-checkboxLabel a {
  color: #e6102d;
  transition: color 0.3s ease;
}
.CareerFiltersForm-checkboxLabel a:hover {
  color: #cc001b;
}
.CareerFiltersForm-checkbox + .CareerFiltersForm-checkboxLabel::before {
  background: #edeef2;
  border: 1px solid #edeef2;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
}
.isInvalid .CareerFiltersForm-checkbox + .CareerFiltersForm-checkboxLabel::before {
  border: 1px solid #e61010;
}
.CareerFiltersForm-checkbox:checked + .CareerFiltersForm-checkboxLabel::before {
  background: #edeef2 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='12' fill='none'%3E%3Crect width='9.471' height='1' x='.715' y='4.002' fill='%23000' rx='.5' transform='rotate(45 .715 4.002)'/%3E%3Crect width='15.071' height='1' fill='%23000' rx='.5' transform='scale(-1 1) rotate(45 -8.45 -20.257)'/%3E%3C/svg%3E") no-repeat 50% 50%;
}
