.PostItem {
  position: relative;
  cursor: pointer;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 4 + 3 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  margin-bottom: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostItem {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 4 + 3 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .PostItem {
    margin-bottom: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.PostItem:hover .PostItem-title,
.PostItem:focus .PostItem-title {
  border-color: transparent !important;
}
.PostItem:hover .PostItem-img img,
.PostItem:focus .PostItem-img img {
  transform: scale(1.05);
}
.PostItem:hover .PostItem-img:after,
.PostItem:focus .PostItem-img:after {
  opacity: 1;
}
.PostItem:hover .PostItem-overlay,
.PostItem:focus .PostItem-overlay {
  opacity: 1;
}
.PostItem:after {
  content: '';
  display: block;
  padding-bottom: 122.22%;
}
.PostItem-link {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.PostItem-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.PostItem-content {
  position: relative;
  padding: 49px 24px 24px;
  z-index: 6;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .PostItem-content {
    padding-top: 48px;
  }
}
@media (max-width: 1199px) {
  .PostItem-content {
    padding-top: 44px;
  }
}
.PostItem-meta {
  font-size: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 17px;
  padding-top: 24px;
  padding-left: 24px;
  z-index: 10;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .PostItem-meta {
    margin-bottom: 12px;
  }
}
@media (max-width: 1199px) {
  .PostItem-meta {
    margin-bottom: 16px;
  }
}
.PostItem-category {
  margin-right: 9px;
}
.PostItem-date {
  font-size: 11px;
  line-height: 18px;
  letter-spacing: .85px;
  text-transform: uppercase;
  height: 16px;
  vertical-align: top;
}
.PostItem-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin-top: 10px;
  padding-bottom: 1px;
  border-bottom: 1px solid;
  transition: border-color 0.3s ease;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .PostItem-title {
    font-size: 26px;
    line-height: 32px;
  }
}
@media (max-width: 1199px) {
  .PostItem-title {
    font-size: 20px;
    line-height: 28px;
  }
}
.PostItem-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  overflow: hidden;
}
.PostItem-img img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
  transition: transform 0.3s ease-in;
}
.PostItem-img--align-t img {
  -o-object-position: center top;
     object-position: center top;
}
.PostItem-img--align-b img {
  -o-object-position: center bottom;
     object-position: center bottom;
}
.PostItem-imgOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.PostItem-viewCount {
  z-index: 6;
  display: inline-block;
  position: relative;
  vertical-align: top;
  top: -2px;
  margin-left: 10px;
}
.PostItem--index {
  height: 100%;
  width: 100%;
  margin-bottom: 0;
}
.PostItem--index:after {
  content: normal;
  padding-bottom: 0;
}
.PostItem--index .PostItem-meta {
  padding-top: 20px;
}
.PostItem--index .PostItem-category {
  margin-right: 6px;
}
.PostItem--index .PostItem-content {
  padding-top: 52px;
}
.PostItem--index .PostItem-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 10px;
  padding-bottom: 1px;
  border-bottom: 1px solid;
  transition: border-color 0.3s ease;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .PostItem--index .PostItem-title {
    font-size: 21px;
    line-height: 28px;
  }
}
@media (max-width: 1199px) {
  .PostItem--index .PostItem-title {
    font-size: 20px;
    line-height: 28px;
  }
}
.PostItem-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(28, 22, 48, 0.2);
  transition: opacity 0.3s ease;
  z-index: 11;
  pointer-events: none;
}
.PostItem--post {
  width: calc((100% - 80px) / 3);
  margin: 0 20px;
}
.PostItem--post:after {
  padding-bottom: 121%;
}
.PostItem--post .PostItem-title {
  font-size: 26px;
  line-height: 32px;
}
@media (max-width: 1199px) {
  .PostItem--post .PostItem-title {
    font-size: 18px;
    line-height: 24px;
  }
}
