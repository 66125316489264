.Share {
  position: relative;
  height: 32px;
  width: 32px;
}
.Share-button {
  position: relative;
  height: 32px;
  width: 32px;
  background: none;
  border: none;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s linear;
  cursor: pointer;
  z-index: 1;
}
.Share-button svg {
  position: absolute;
  height: 10px;
  width: 13px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.Share-button:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid rgba(46, 46, 51, 0.2);
  transition: transform 0.3s ease;
  border-radius: 50%;
}
.Share-button:hover:after,
.Share-button:focus:after {
  transform: scale(1.15);
}
.Share.isActive .Share-button {
  visibility: hidden;
  opacity: 0;
  transition-delay: 0s;
  transform: scale(0.1);
  z-index: 1;
}
.Share-close {
  visibility: hidden;
  position: absolute;
  height: 32px;
  width: 32px;
  top: 0;
  right: 0;
  opacity: 0;
  background: none;
  border: none;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s linear 0.3s;
  transform: scale(0.1);
  cursor: pointer;
  z-index: 3;
}
.Share-close svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 17px;
  width: 17px;
  stroke: #2e2e33;
}
.Share.isActive .Share-close {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  transform: scale(1);
}
.Share-list {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}
.Share-item {
  visibility: hidden;
  position: absolute;
  height: 32px;
  width: 32px;
  top: 0;
  right: 0;
  opacity: 0;
  border: none;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s linear 0.3s;
  cursor: pointer;
  border-radius: 50%;
  /*
        &--facebook {
            background: #4162af;

            svg {
                height: 16px;
                width: 9px;
            }

            @{c}.isActive & {
                transform: translateX(-40px);
            }
        }
        */
}
.Share-item svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  fill: #fff;
}
.Share.isActive .Share-item {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.Share.isActive .Share-item:hover {
  opacity: .7;
}
.Share-item--telegram {
  background: #37ade9;
}
.Share-item--telegram svg {
  height: 14px;
  width: 16px;
}
.Share.isActive .Share-item--telegram {
  transform: translateX(-80px);
}
.Share-item--twitter {
  background: #55acee;
}
.Share-item--twitter svg {
  height: 14px;
  width: 16px;
}
.Share.isActive .Share-item--twitter {
  transform: translateX(-40px);
}
.Share-itemInner {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  transition: transform 0.3s ease;
  transform: rotate(90deg);
}
.Share.isActive .Share-itemInner {
  transform: rotate(0deg);
}
.Share--post {
  height: 40px;
  width: 40px;
}
.Share--post .Share-button {
  height: 40px;
  width: 40px;
}
.Share--post .Share-button svg {
  height: 15px;
  width: 15px;
}
.Share--post .Share-close {
  visibility: hidden;
  position: absolute;
  height: 40px;
  width: 40px;
}
.Share--post .Share-close svg {
  height: 20px;
  width: 20px;
}
.Share--post .Share-item {
  height: 40px;
  width: 40px;
}
.Share--post.isActive .Share-item--telegram {
  transform: translateX(-150px);
}
.Share--post.isActive .Share-item--twitter {
  transform: translateX(-100px);
}
.Share--post.isActive .Share-item--facebook {
  transform: translateX(-50px);
}
