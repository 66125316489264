.LocationsPopup {
  position: fixed;
  height: 100%;
  min-width: 1024px;
  top: 0;
  left: 0;
  right: 0;
  background: #12151b;
  transition: transform 0.7s ease;
  z-index: 22;
}
.LocationsPopup--toAnimate {
  position: fixed;
  transform: translate3d(0, 100vh, 0);
}
.LocationsPopup-close {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
}
.LocationsPopup-content {
  height: 100%;
}
.LocationsPopup-menu {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  margin-top: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  margin-bottom: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  margin-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  z-index: 2;
}
@media (max-width: 1199px) {
  .LocationsPopup-menu {
    margin-top: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .LocationsPopup-menu {
    margin-bottom: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .LocationsPopup-menu {
    margin-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .LocationsPopup-menu {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .LocationsPopup-menu {
    width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) and (max-width: 1199px) {
  .LocationsPopup-menu {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .LocationsPopup-menu {
    width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 1 * 48px) / 12 * 4 + 3 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .LocationsPopup-menu {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 1 * 2 * 48px) / 12 * 4 + 3 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.LocationsPopup-header {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  color: #1c1630;
  padding-top: 23px;
  padding-bottom: 23px;
  padding-left: 24px;
  padding-right: 24px;
}
.LocationsPopup-categories {
  display: flex;
  margin-bottom: 37px;
}
.LocationsPopup-category {
  display: flex;
  position: relative;
  height: 48px;
  width: 50%;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.LocationsPopup-category:first-child {
  border-right: 1px solid #dcdcdc;
}
.LocationsPopup-category input {
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.LocationsPopup-category input:checked + label {
  color: #e6102d;
}
.LocationsPopup-category input:checked + label:after {
  opacity: 1;
}
.LocationsPopup-category label {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  line-height: 44px;
  text-align: center;
  color: #333;
  position: relative;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: color 0.3s ease;
  cursor: pointer;
}
.LocationsPopup-category label:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  opacity: 0;
  border-bottom: 4px solid #e6102d;
  transition: opacity 0.3s ease;
}
.LocationsPopup-category label:hover,
.LocationsPopup-category label:focus {
  color: #e6102d;
}
.LocationsPopup-filters {
  max-height: calc((100vh - 2 * 24px) - 128px);
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  overflow: auto;
}
@media (max-width: 1199px) {
  .LocationsPopup-filters {
    max-height: calc((100vh - 2 * 48px) - 128px);
  }
}
.LocationsPopup--beer .LocationsPopup-filters--ciders {
  display: none;
}
.LocationsPopup--ciders .LocationsPopup-filters--beer {
  display: none;
}
.LocationsPopup-subCategories {
  margin-bottom: 13px;
}
.LocationsPopup-subCategoriesHeader,
.LocationsPopup-brandsHeader {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #333;
}
.LocationsPopup-subCategoriesHeader {
  margin-bottom: 23px;
}
.LocationsPopup-brandsHeader {
  margin-bottom: 26px;
}
.LocationsPopup-subCategoriesList {
  display: flex;
  flex-wrap: wrap;
}
.LocationsPopup-subCategory {
  position: relative;
  width: 50%;
  margin-bottom: 21px;
}
.LocationsPopup-subCategory input {
  position: relative;
  height: 16px;
  width: 16px;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.LocationsPopup-subCategory label {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 13px;
  color: #333;
  margin-left: 8px;
  cursor: pointer;
}
.LocationsPopup-inputCheckbox {
  display: inline-block;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 0;
  left: 0;
  border: 1px solid #e3e3e6;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  z-index: 1;
  border-radius: 2px;
}
.LocationsPopup-inputCheckbox svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 14px;
  width: 18px;
  bottom: 5px;
  left: 1px;
  opacity: 0;
  stroke: #e6102d;
  transition: opacity 0.3s ease;
}
input:checked + .LocationsPopup-inputCheckbox {
  background-color: #fff2f4;
  border-color: #ff8090;
}
input:checked + .LocationsPopup-inputCheckbox svg {
  opacity: 1;
}
.LocationsPopup-brandsList {
  width: calc(100% + 12px);
}
.LocationsPopup-brandsList .StyleTag {
  margin-bottom: 12px;
  margin-right: 12px;
}
.LocationsPopup-locations {
  height: 100%;
}
.LocationsPopup-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.LocationsPopup-loader:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: .5;
}
.LocationsPopup-loader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  animation: spin .6s linear infinite reverse;
}
.LocationsPopup-loader .ball {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  animation: spin 1.2s infinite ease-in-out;
}
.LocationsPopup-loader .ball:after {
  position: absolute;
  content: '';
  background-color: #e6102d;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  top: 0px;
}
.LocationsPopup-loader .ball:nth-child(1) {
  animation-delay: 0s;
}
.LocationsPopup-loader .ball:nth-child(2) {
  animation-delay: -0.1s;
}
.LocationsPopup-loader .ball:nth-child(3) {
  animation-delay: -0.2s;
}
.LocationsPopup-loader .ball:nth-child(4) {
  animation-delay: -0.3s;
}
.LocationsPopup-loader .ball:nth-child(5) {
  animation-delay: -0.4s;
}
.LocationsPopup-loader .ball:nth-child(6) {
  animation-delay: -0.5s;
}
.LocationsPopup-loader .ball:nth-child(7) {
  animation-delay: -0.6s;
}
.LocationsPopup-loader .ball:nth-child(8) {
  animation-delay: -0.7s;
}
.LocationsPopup-loader .ball:nth-child(9) {
  animation-delay: -0.8s;
}
.LocationsPopup-loader .ball:nth-child(10) {
  animation-delay: -0.9s;
}
.LocationsPopup--loaded .LocationsPopup-loader {
  display: none;
}
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
