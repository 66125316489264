.SectionBadge {
  display: inline-block;
  position: relative;
  height: 16px;
  padding-right: 6px;
}
.SectionBadge-title {
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  display: inline-block;
  position: relative;
  height: 100%;
  padding-left: 6px;
  padding-right: 4px;
  vertical-align: top;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  background: #5210e6;
}
.SectionBadge-end {
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 7px;
  top: 0;
  bottom: 0;
  right: 0;
  fill: #5210e6;
}
.SectionBadge-end svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
